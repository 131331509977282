import axios from 'axios';
import { getItem } from '@/helpers/persistanceStorage';
import router from '@/main.js';
import routeNames from '@/router/routeNames';

// export const serverUrl = 'http://digital.cartlis.ge:2096/';
//export const serverUrl = 'http://159.89.27.88:2096/';

export const serverUrl =
  window.location.protocol + '//' + window.location.hostname + ':2096/'; //';http://localhost:3000/';//
axios.defaults.baseURL = `${serverUrl}api/`;

axios.interceptors.request.use((config) => {
  const token = getItem('token');
  if (token) {
    config.headers.token = token ? `${token}` : '';
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (
        router.currentRoute.name !== 'Login' &&
        router.currentRoute.name !== routeNames.userStartscreen
      ) {
        if (
          router.currentRoute.name.toLowerCase().startsWith('user') &&
          router.currentRoute.name.toLowerCase() !== 'users'
        ) {
          router.push({ name: routeNames.userStartscreen });
        } else {
          router.push({ name: 'Login' });
        }
      }
    }
    return error?.name === 'CanceledError' ? Promise.reject(error) : error;
  }
);

export default axios;
