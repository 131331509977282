<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>

        <Breadcrumps
          :data="[{ title: $t('modalNotSaved:Message:Default:Title') }]"
        />

        <div class="topline-minizone">
          <Button
            text=""
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <!--      TODO: Stas правка верстки - Сделал 15.01.2025-->
      <!--      TODO: MODAL МОДАЛЬНОЕ ВЫБОРА ПРОДУКТОВ-->
      <!--      TODO: САША ПЕРЕВОД-->

      <div class="modalwrap2-content">
        <div class="modalwrap3-text">
          <p class="text-mini" v-html="message" />
          <p class="text-mini textwarning uni_text-warningcolor">
            {{ $t('ModalCloneWithProductsConflict:ProblemText') }}:
          </p>
        </div>

        <article
          class="chkset chkset--print modalclonewithproductsconflict-tooltip-relativer"
        >
          <div class="blocklinewrap sets-wrap">
            <div
              class="sets-column--full sets-column--scroll tbl_checkboxproduct"
            >
              <!--TODO: ВИТЯ -           v-for="product in products" :key="product.id"-->
              <!--Вот эта хрень должна быть на этом чекбокс, тогда будет то что нужно, над на риал данных проверить-->
              <!--На крайний, можно поробовать обернуть-->

              <CheckBoxProduct
                class=""
                v-for="product in products"
                :key="product.id"
                :text="product.name"
                :product="product"
                color-checked="accentfill"
                color-un-checked="gray"
                v-model="selectedProducts[product.id]"
              />

              <!--              <HoverTooltipProduct :product="product" :noSubstance="true" />-->
            </div>
          </div>
        </article>
      </div>

      <div class="modalwrap3-text margintop--standart marginbottom--standart" />

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('DefenseProgramEdit:ModalClone:ButtonOk')"
          size="wide"
          color="accent"
          icon="tick"
          :is-loading="false"
          :loading-left="true"
          @click="clone"
        />

        <Button
          :text="$t('DefenseProgramEdit:ModalClone:ButtonCancel')"
          size="wide"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('close')"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import Modal from '@/pages/Modal';

export default {
  name: 'ModalCloneWithProductsConflict',
  props: {
    message: { type: String, required: true },
    products: { type: Array, required: true },
  },
  components: { Button, Breadcrumps, Modal },
  data() {
    return {
      selectedProducts: Object.fromEntries(
        this.products.map((p) => [p.id, false])
      ),
    };
  },
  methods: {
    clone() {
      this.$emit(
        'clone',
        this.products.filter((p) => !this.selectedProducts[p.id])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.chkset .chktext {
  max-width: 40ch !important;
}

.warningline {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: $iconSizeStandart - 0px;
      min-width: $iconSizeStandart - 0px;
      height: $iconSizeStandart - 0px;

      & * {
        fill: $colorWarning;
      }
    }
  }

  & > div:last-child {
    color: $colorWarning;
    font-size: $fontsizeStandart;
    line-height: $lineFontsizeStandart;
    padding-left: $marginMicro;
  }
}

@media screen and (max-width: 1800px) {
  .warningline {
    & > div:first-child {
      //width: $iconSizeMicro-k1600;
      //height: $iconSizeMicro-k1600;

      & svg {
        width: $iconSizeStandart-k1600 - 0px;
        min-width: $iconSizeStandart-k1600 - 0px;
        height: $iconSizeStandart-k1600 - 0px;
      }
    }

    & > div:last-child {
      font-size: $fontsizeStandart-k1600;
      line-height: $lineFontsizeStandart-k1600;
      padding-left: $marginMicro-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .warningline {
    & > div:first-child {
      //width: $iconSizeMicro-k1600;
      //height: $iconSizeMicro-k1600;

      & svg {
        width: $iconSizeStandart-k1360 - 0px;
        min-width: $iconSizeStandart-k1360 - 0px;
        height: $iconSizeStandart-k1360 - 0px;
      }
    }

    & > div:last-child {
      font-size: $fontsizeStandart-k1360;
      line-height: $lineFontsizeStandart-k1360;
      padding-left: $marginMicro-k1360;
    }
  }
}
</style>

<script setup>
import CheckBoxProduct from '@/components/CheckBoxProduct.vue';
</script>
