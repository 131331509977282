<template>
  <article class="rightzone rightzone--userarticleslist">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <div class="articleslist">
      <div class=" ">
        <!--   <router-link
          :to="{ name: routeNames.userAnalysis }"
          tag="div"
          class="articleslist_line"
          active-class="sidemenu-item&#45;&#45;active"
        >
          <div></div>
          <p>Типы ирригационных систем</p>
        </router-link>-->

        <div class="articleslist_line">
          <p>Какую систему выбрать</p>
        </div>
        <div class="articleslist_line">
          <p>Установка капельного орошения своими руками</p>
        </div>
        <div class="articleslist_line">
          <p>Где купить</p>
        </div>
        <div class="articleslist_line">
          <p>Часто задаваемые вопросы</p>
        </div>
      </div>
    </div>

    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import routeNames from '@/router/routeNames';

export default {
  name: 'UserArticlesList3',
  components: {
    UserFooter,
    Breadcrumps,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Статьи', route: routeNames.userHomePage },
        { title: 'Ирригационные системы' },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
