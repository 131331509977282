import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import i18n from '@/./i18n';
require('./directives/Svg');
require('./directives/ClickOutside');
require('./directives/Sticky');
require('./directives/tooltip');
import VueAWN from 'vue-awesome-notifications';
import ModalConfirm from './plugins/modal-confirm/';
import wysiwyg from 'vue-wysiwyg';
import { saveToSession, restoreFromSession } from './helpers/browserHelper';
import { routeHistory } from './router/router';

//https://github.com/chmln/vue-wysiwyg
Vue.use(wysiwyg, { hideModules: { image: true, table: true } });

/*import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

Vue.use(TawkMessengerVue, {
  propertyId: '6300f49754f06e12d88fc2f0',
  widgetId: '1gatrmshb',
});*/

Vue.config.productionTip = false;
Vue.use(VueAWN, {});

Vue.use(ModalConfirm);
Vue.component('modal-confirm', ModalConfirm.default);

//Для сохранения данных истории роутера при перезагрузке страницы
window.addEventListener('beforeunload', (event) => {
  /*const unsavedData = {
    message: 'Данные перед уходом',
    timestamp: new Date().toISOString(),
  };*/
  routeHistory;

  // Сохраняем данные в sessionStorage
  saveToSession('routerHistory', routeHistory);
});

const userData = restoreFromSession('routerHistory');
if (Array.isArray(userData)) {
  routeHistory.push(...userData);
}

store.subscribe((mutation, state) => {
  //console.log(mutation, state);
  //Сохранение данных фильтра от перезагрузки страницы
  if (mutation?.type?.startsWith('[filtersState]')) {
    saveToSession('[filtersState]', state.filtersState);
  }
});

const filterData = restoreFromSession('[filtersState]');
if (filterData) {
  //console.log(filterData);
  store.replaceState({
    ...store.state,
    filtersState: {
      ...store?.state?.filtersState,
      ...filterData,
    },
  });
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default router;
