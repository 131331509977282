<template>
  <div class="chkwrap-block">
    <div
      class="chkwrap tooltip-wrapper tooltip-wrapper--not-relative"
      :class="{ 'chkwrap--disabled': disabled, [colorClass]: true }"
      @click="onClick"
    >
      <HoverTooltipProductTEST :product="product" />

      <div class="chkbox">
        <div v-svg="{ name: 'tick' }"></div>
      </div>

      <div class="chktext">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import HoverTooltipProductTEST from '@/pages/programs/components/HoverTooltipProductTEST.vue';

export default {
  name: 'CheckBoxProduct',
  components: { HoverTooltipProductTEST },
  props: {
    disabled: {
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
    value: { default: false },
    colorChecked: {
      type: String,
      default: 'accentfill',
    },
    colorUnChecked: {
      type: String,
      default: 'accent',
    },
  },
  data() {
    return {
      checked: this.value === 1 || this.value === true,
    };
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.$emit('input', this.checked);
      }
    },
  },
  computed: {
    colorClass() {
      if (this.checked) {
        return `chkwrap--checked-${this.colorChecked}`;
      } else {
        return `chkwrap--nocheck-${this.colorUnChecked}`;
      }
    },
  },
  watch: {
    value() {
      this.checked = this.value;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';

//TODO: STAS - перенести

.modalclonewithproductsconflict-tooltip-relativer {
  position: relative;
}

//Все ради модалки копировать
.tooltip.tooltip--tooltipproduct {
  //left: 100px;
  width: 100%;
  bottom: calc(100% + $marginNano);
}

.tooltip-wrapper.tooltip-wrapper--not-relative {
  position: static;
}

@media screen and (max-width: 1800px) {
  .tooltip.tooltip--tooltipproduct {
    bottom: calc(100% + $marginNano-k1600);
  }
}
@media screen and (max-width: 1420px) {
  .tooltip.tooltip--tooltipproduct {
    bottom: calc(100% + $marginNano-k1360);
  }
}
</style>
