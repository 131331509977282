<template>
  <div class="pageprint pageprint--horizontal">
    <!--<div class="pageprint pageprint&#45;&#45;horizontal">-->
    <div class="printbtnswrap">
      <div class="printbtns">
        <Button
          class="marginright--standart"
          text="Печать"
          v-tooltip="{ text: $t('PrintProgramDefense:ButtonPrint') }"
          size="h-max"
          color="accent"
          icon="print"
          :is-loading="false"
          :loading-left="false"
          @click="printWindow()"
        />

        <Button
          text="Выйти"
          size="h-max"
          color="accentlines"
          icon="x"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'DefensePrograms' })"
        />
      </div>
    </div>

    <!--    <PrintProgramDefense/>-->

    <PrintProgramDefense />
    <!--<PrintProgramFertilizer/>-->
  </div>
</template>

<script>
import Button from '@/components/views/Button';
// import PrintProgramDefense from '@/pages/programs/programprint/PrintProgramDefense';
// import PrintProgramFertilizer from '@/pages/programs/programprint/PrintProgramFertilizer';
// import PrintProgramDefense from "@/pages/programs_stasbckup/programprint/PrintProgramDefense";

export default {
  name: 'PrintPage',
  components: {
    // PrintProgramDefense,
    // PrintProgramFertilizer,
    // PrintProgramDefense,
    Button,
  },
  methods: {
    printWindow: function () {
      window.print();
    },
  },
};
</script>
