<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <!-- П.1 Анализ воды-->
    <div
      class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
    >
      <div class="artclhead">
        <div class="artclhead-num marginright--micro">01.</div>
        <div class="artclhead-text">აიღეთ ნიმუში</div>
      </div>

      <div class="artclblock">
        <!--Блок Файл ПДФ-->

        <div class="artclblock-text">
          <p>Подробная инструкция в файле:</p>
        </div>

        <div class="margintop--standart">
          <ComponentFile
            text="Инструкции по отбору проб воды"
            icon="filepdf"
            link="https://drive.google.com/file/d/1kZ4z1_g0MkLd4db-ZTM0_F2kCUxLjbug/view?usp=sharing"
          />
        </div>
      </div>
    </div>

    <!-- П.2 Маркировка воды -->
    <div
      class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
    >
      <div class="artclhead">
        <div class="artclhead-num marginright--micro">02.</div>
        <div class="artclhead-text">ეტიკეტების ნიმუშები</div>
      </div>

      <div class="artclblock">
        <!--Блок Файл ПДФ-->

        <div class="margintop--standart">
          <ComponentFile
            text="Инструкции по маркировки проб воды"
            icon="filepdf"
            link="https://drive.google.com/file/d/17upUU2kF156zEzESy5W7GatD1mN8pofe/view?usp=sharing"
          />
        </div>
      </div>
    </div>

    <!-- П.1 Анализ воды
    <div class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">
        <div class="artclhead-num marginright--micro">
          01.
        </div>
        <div class="artclhead-text">
          აიღეთ ნიმუში
        </div>
      </div>

      <div class="artclblock">


        <div class="artclblock-text">
          <p>
            წყლის ნიმუშის აღება უნდა მოხდეს სუფთა პლასმასის ბოთლში. ყურადღება უნდა მიექცეს იმ ფაქტს, რომ წყლის ნიმუშის დაბინძურება არ მოხდეს ნიმუშის აღებისას დაბინძურებული ჭურჭლის გამოყენებით.
          </p>
        </div>


        <div class="artclblock-text">
          <p class="bold">
            თუ წყლის ნიმუშს ვიღებთ ონკანიდან მიჰყევით შემდეგ ინსტრუქციას
          </p>
          <ol>
            <li>
              ფრთხილად გაწმინდეთ ონკანის ზედაპირი (მფრქვევანა),
              რათა გაიწმინდოს იგი ცხიმისა და ზედაპირული დამაბინძურებლებისგან,
              რაც ონკანის ზედაპირზე ნარჩნების სახით შეიძლება არსებობდეს
            </li>

            <li>
              მაქსიმალური ნაკადის სიჩქარით ადინეთ წყალი
            </li>

            <li>
              ნიმუშის ასაღებ ბოთლს 5-ჯერ გამოავლეთ წყალი,
              აგრეთვე გამოავლეთ წყალი თავსახურსაც
            </li>

            <li>
              წყლის ნაკადს დაუწიეთ და დაბალი სიჩქარით შეავსეთ
              ბოთლი პირთამდე და მჭიდროდ დაუჭირეთ თავსახური
            </li>

            <li>
              გაუკეთეთ ნიმუშს შესაბამისი ეტიკეტი
            </li>
          </ol>
        </div>
        <div class="artclblock-text">
          <p class="bold">
            ნიმუშის შეგროვება მდინარიდან, ნაკადულიდან ან სხვა ზედაპირული წყლიდან
          </p>
          <ol>
            <li>
              მოხსენით სუფთა პლასმასის ბოთლს თავსახური
            </li>
            <li>
              დაიკავეთ ბოთლი ვერტიკალურად და ჩაუშვით წყალში,
              ისე რომ ბოთლის ყელი აღმოჩნდეს წყლის ზედაპირიდან 30 სმ სიღრმეზე
            </li>
            <li>
              დახარეთ ბოთლი ოდნავ, რათა ბოლომდე აივსოს
            </li>
            <li>
              გამოავლეთ წყალი ბოთლს 5-ჯერ
            </li>
            <li>
              ბოთლის ნიმუშით პირამდე ავსების შემდეგ მჭიდროდ მოარგეთ თავსახური
            </li>
            <li>
              ნიმუშს გაუკეთეთ შესაბამისი ეტიკეტი
            </li>
          </ol>
        </div>
        <div class="artclblock-text">
          <p class="bold">
            წყლის ნიმუშის შეგროვება ტუმბოდან/ჭაბურღილიდან
          </p>
          <ol>
            <li>
              მინიმუმ 5 წუთის განმავლობაში გაუჩერებლად ამუშავეთ ტუმბო
            </li>
            <li>
              აცადეთ რომ წყალმა გაიაროს მილები
            </li>
            <li>
              ნიმუშის აღებისას ტუმბოდან გადმოსული წყალი პირდაპირ შეაგროვეთ ბოთლში
              ( რეკომენდებულია ბოთლის რამდენიმეჯერადი გამოვლება ნიმუშით)
            </li>
            <li>
              ბოთლის პირამდე შევსების შემდეგ მჭიდროდ დაახურეთ თავსახური
            </li>
            <li>
              გაუკეთეთ შესაბამისი ეტიკეტი
            </li>
          </ol>
        </div>


        <div class="artclblock-text">
          <p>
            <span class="textwarning">შენიშვნა!</span> თავი აარიდეთ გამაგრილებელი სასმლის ბოთლის გამოეყენებას, რათა ბოთლის კედლებზე არსებულმა შიგთავსმა არ შეგიცვალოთ ანალიზის შედეგები. სასურველია, ნიმუშის ასაღებად გამოიყენოთ სუფთა პლასმასის ბოთლი. წინააღმდეგ შემთხვევაში ნიმუშის აღებამდე წინა დღეს მრავალჯერადად უნდა გამოირეცხოს ბოთლი და თავსახური გამდინარე წყლით და საშუალება მიეცეს თავღია ბუნებრივად გამოშრეს ოთახის ტემპერატურაზე. ბოთლს ორგანოლეპტიკურად (სუნით) არ უნდა ეტყობოდეს მასში გარეცხვამდე სხვა სითხის არსებობა.
          </p>
        </div>


        <div class="artclblock-media">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wHMIgZXDA1o" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>


      </div>

    </div>-->

    <!--Блок Гео-->
    <GuideAnalysisGeo />

    <!--Блок Отслеживайте-->
    <GuideAnalysisTrack />

    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from '@/components/views/LocationSelect';
import Button from '@/components/views/Button';
import GuideAnalysisGeo from '@/pages/userproject/userArticles/Components/GuideAnalysisGeo';
import GuideAnalysisTrack from '@/pages/userproject/userArticles/Components/GuideAnalysisTrack';
import ComponentFile from '@/pages/userproject/userArticles/Components/ComponentFile';
import routeNames from '@/router/routeNames';

export default {
  name: 'UserAnalysesInstruction1',
  components: {
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Все нализы', route: routeNames.userAnalyzes },
        { title: 'Инструкция - Анализ воды' },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
