<template>
  <button
    class="btn"
    :class="classes"
    @click="disabled ? null : $emit('click')"
    ref="button"
  >
    <span v-if="icon || (loadingLeft && isLoading)" class="btn_lefticon">
      <template v-if="!loadingLeft || (loadingLeft && !isLoading)">
        <div v-svg="{ name: icon }" />
      </template>

      <span v-if="loadingLeft" class="loader"></span>
    </span>
    <span v-if="isHasButtonText" class="btn_text">{{ text }}</span>
    <span class="btn_righticon">
      <span v-if="!loadingLeft" class="loader"></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  components: {},
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: 'standart',
    },
    color: {
      type: String,
      default: 'gray',
    },
    icon: {
      type: String,
    },
    loadingLeft: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case 'standart':
          return 'btn--width_standart';
        case 'standart2x':
          return 'btn--width_standart2x';
        case 'wide':
          return 'btn--width_wide';
        case 'wider':
          return 'btn--width_wider';
        case 'max':
          return 'btn--width_max';
        case 'auto':
          return 'btn--width_auto';
        case 's-standart':
          return 'btn--sqr_standart';
        case 's-mini':
          return 'btn--sqr_mini';
        case 's-micro':
          return 'btn--sqr_micro';
        case 'h-standart':
          return 'btn--sqr_h-standart';
        case 'h-max':
          return 'btn--sqr_h-max';
        default:
          return 'btn--width_standart';
      }
    },
    colorClass() {
      if (this.isLoading) {
        return 'btn--color_gray';
      }
      switch (this.color) {
        case 'gray':
          return 'btn--color_gray';
        case 'graylightlines':
          return 'btn--color_graylightlines';
        case 'graylines':
          return 'btn--color_graylines';
        case 'graylinesaccent':
          return 'btn--color_graylinesaccent';
        case 'graylinesalert':
          return 'btn--color_graylinesalert';
        case 'accent':
          return 'btn--color_accent';
        case 'accentlines':
          return 'btn--color_accentlines';
        case 'accentlightlines':
          return 'btn--color_accentlightlines';
        case 'alert':
          return 'btn--color_alert';
        case 'alertlines':
          return 'btn--color_alertlines';
        case 'whitelines':
          return 'btn--color_whitelines';
        case 'transpgray':
          return 'btn--color_transpgray';
        case 'transpaccent':
          return 'btn--color_transpaccent';
        case 'transpError':
          return 'btn--color_transpError';
        case 'warning':
          return 'btn--color_warning';
        case 'warninglines':
          return 'btn--color_warninglines';
        default:
          return 'btn--color_gray';
      }
    },
    buttonLoadingClass() {
      return this.isLoading ? 'btn--loading' : undefined;
    },
    isHasButtonText() {
      return this.text;
    },
    buttonDisabledClass() {
      return this.disabled ? 'btn--disabled' : undefined;
    },
    classes() {
      const tooltipClass = this.$refs.button?.classList.contains(
        'tooltip-wrapper'
      )
        ? 'tooltip-wrapper'
        : '';
      return [
        this.colorClass,
        this.sizeClass,
        this.buttonLoadingClass,
        this.buttonDisabledClass,
        tooltipClass,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.svgicon {
  stroke: transparent;
}
.btn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: $borderRadiusStandart;
  font-size: $fontsizeBigButtons;

  font-weight: $fontNormal;
  user-select: none;
  padding-left: $marginMicro;
  padding-right: $marginMicro;

  & .btn_lefticon {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $iconSizeStandart;
      height: $iconSizeStandart;
    }
  }

  & .btn_text {
    font-size: inherit;
    line-height: $lineFontsizeBigButtons;
    margin-left: $marginMicro2;
    margin-right: $marginMicro2;
    white-space: nowrap;
  }

  & .btn_righticon {
    display: block;

    & > * {
      width: $iconSizeStandart;
      height: $iconSizeStandart;
    }
  }

  & .loader {
    display: none; //Костыль 20072022 вылез лоадер
  }
}

//colors
.btn--color_warning {
  background-color: $colorWarning;
  color: $colorTextWhite;
  border: $borderButton solid $colorWarning;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: transparent;
    color: $colorWarning;
    border: $borderButton solid $colorWarning;

    & * {
      fill: $colorWarning;
    }
  }
}

.btn--color_warninglines {
  background-color: transparent;
  color: $colorWarning;
  border: $borderButton solid $colorWarning;

  & * {
    fill: $colorWarning;
  }

  &:hover {
    background-color: $colorWarning;
    color: $colorTextWhite;
    border: $borderButton solid $colorWarning;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_transpgray {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid transparent;

  & * {
    fill: $colorGray;
  }

  &:hover {
    color: $colorTextAccent;

    & * {
      fill: $colorAccent;
    }
  }
}
.btn--color_transpaccent {
  background-color: transparent;
  color: $colorTextAccent;
  border: $borderButton solid transparent;

  & * {
    fill: $colorAccent;
  }

  &:hover {
    color: $colorTextAccentDark;

    & * {
      fill: $colorAccentDark;
    }
  }
}
.btn--color_transpError {
  background-color: transparent;
  color: $colorTextError;
  border: $borderButton solid transparent;

  & * {
    fill: $colorError;
  }

  &:hover {
    & * {
    }
  }
}
.btn--color_gray {
  background-color: $colorGray;
  color: $colorTextWhite;
  border: $borderButton solid $colorGray;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGray;
    border: $borderButton solid $colorLinesGray;

    & * {
      fill: $colorGray;
    }
  }
}
.btn--color_graylines {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid $colorLinesGray;

  & * {
    fill: $colorGray;
  }

  &:hover {
    background-color: $colorGray;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesGray;

    & * {
      fill: $colorWhite;
    }
  }
}
.btn--color_graylinesalert {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid $colorLinesGray;

  & * {
    fill: $colorGray;
  }

  &:hover {
    background-color: $colorTextError;
    color: $colorTextWhite;
    border: $borderButton solid $colorTextError;

    & * {
      fill: $colorWhite;
    }
  }
}
.btn--color_graylinesaccent {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid $colorLinesGray;

  & * {
    fill: $colorGray;
  }

  &:hover {
    background-color: $colorTextAccent;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesAccent;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_graylightlines {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid $colorLinesGrayLight;

  & * {
    fill: $colorGray;
  }

  &:hover {
    background-color: $colorGray;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesGray;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_accent {
  background-color: $colorAccent;
  color: $colorTextWhite;
  border: $borderButton solid $colorAccent;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: $colorAccentDark;
    border: $borderButton solid $colorAccentDark;
  }
}
.btn--color_accentlines {
  background-color: transparent;
  color: $colorTextAccent;
  border: $borderButton solid $colorLinesAccent;

  & * {
    fill: $colorAccent;
  }

  &:hover {
    background-color: $colorTextAccent;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesAccent;

    & * {
      fill: $colorWhite;
    }
  }
}
.btn--color_accentlightlines {
  background-color: transparent;
  color: $colorTextAccent;
  border: $borderButton solid $colorLinesAccentLight;

  & * {
    fill: $colorAccent;
  }

  &:hover {
    background-color: $colorTextAccent;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesAccent;

    & * {
      fill: $colorWhite;
    }
  }
}
.btn--color_alert {
  background-color: $colorError;
  color: $colorTextWhite;
  border: $borderButton solid $colorLinesError;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextError;
    border: $borderButton solid $colorLinesError;

    & * {
      fill: $colorError;
    }
  }
}
.btn--color_alertlines {
  background-color: transparent;
  color: $colorTextError;
  border: $borderButton solid $colorLinesError;

  & * {
    fill: $colorError;
  }

  &:hover {
    background-color: $colorTextError;
    color: $colorTextWhite;
    border: $borderButton solid $colorTextError;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_whitelines {
  background-color: transparent;
  color: $colorTextWhite;
  border: $borderButton solid $colorLinesWhite;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: $colorAccent;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesAccent;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--disabled {
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid $colorGrayLight;

  & * {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid $colorGrayLight;

    & * {
      fill: $colorGrayLight;
    }
  }
}
.btn--disabled.btn--sqr_standart {
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid $colorGrayLight;

  & .btn_text {
    display: none;
  }

  & .btn_righticon {
    display: none;
  }

  & * {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid $colorGrayLight;

    & * {
      fill: $colorGrayLight;
    }
  }
}
.btn--disabled.btn--sqr_mini {
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid transparent;

  & * {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid transparent;

    & * {
      fill: $colorGrayLight;
    }
  }
}
.btn--disabled.btn--sqr_micro {
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid transparent;

  & * {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid transparent;

    & * {
      fill: $colorGrayLight;
    }
  }
}

//sizes{
.btn--width_standart {
  min-width: $widthMini;
  height: $heightStandart;
}

.btn--width_standart2x {
  min-width: $widthStandart2x;
  height: $heightStandart;
}

.btn--width_wide {
  width: auto; //$widthStandart;
  min-width: $widthStandart; //$widthMini;
  height: $heightStandart;
}
.btn--width_wider {
  width: auto; //$widthWide;
  min-width: $widthWide;
  height: $heightStandart;
}
.btn--width_max {
  width: 100%;
  min-width: $widthMini;
  height: $heightStandart;
}

.btn--width_auto {
  min-width: $widthMini;
  height: $heightStandart;
}

//sizes squre
.btn--sqr_standart {
  border-radius: $borderRadiusStandart;
  width: $heightStandart;
  min-width: $heightStandart;
  height: $heightStandart;

  & .btn_text {
    display: none;
  }

  & .btn_righticon {
    display: none;
  }
}
.btn--sqr_mini {
  font-size: $fontsizeStandart;

  border-radius: $borderRadiusMini;
  width: $heightMedium;
  min-width: $heightMedium;
  height: $heightMedium;

  & .btn_text {
    line-height: $lineFontsizeStandart;
    //display: none; !Иначе ломается пагинация
  }

  & .btn_righticon {
    display: none;
  }
}
.btn--sqr_micro {
  border-radius: $borderRadiusMicro;
  width: $heightMini;
  min-width: $heightMini;
  height: $heightMini;
  padding: 3px;

  & .btn_text {
    display: none;
  }

  & .btn_righticon {
    display: none;
  }

  & svg {
    max-width: 75%;
    max-height: 75%;
  }

  & .btn_lefticon {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//sizes - mini height
.btn--sqr_h-max {
  font-size: $fontsizeStandart;

  border-radius: $borderRadiusMicro;
  width: 100%;
  min-width: $heightMedium;
  height: $heightMini;

  text-wrap: none;
  text-overflow: ellipsis;

  & .btn_text {
    line-height: $lineFontsizeStandart;
  }

  & .btn_lefticon div {
    width: $iconSizeStandart * 0.7;
    height: $iconSizeStandart * 0.7;

    & svg {
      width: inherit;
      height: inherit;
    }
  }
}

.btn--sqr_h-standart {
}

// Loader
.loader {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  text-indent: -9999em;
  border-top: $borderRadiusMicro solid rgba(255, 255, 255, 0.2);
  border-right: $borderRadiusMicro solid rgba(255, 255, 255, 0.2);
  border-bottom: $borderRadiusMicro solid rgba(255, 255, 255, 0.2);
  border-left: $borderRadiusMicro solid $colorLinesWhite;
  border-radius: 50%;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: loaderSpin 1.1s infinite linear;
}
.loader:after {
  border-radius: 50%;
}
@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Состояние лоадинг
.btn--loading {
  cursor: wait;
  background-color: $colorGray !important;
  border: $borderButton solid $colorLinesGray;

  &:hover {
    background-color: inherit;
    border: inherit;
    color: $colorTextWhite;

    & * {
      fill: $colorWhite;
    }
  }

  & .loader {
    display: block;
    width: $iconSizeStandart;
    height: $iconSizeStandart;
    border-radius: 50%;
    background-color: transparent;
  }
}

//Модификаторы

//коррекция визуального иконки "отчистить форму"
.filter_buttonclear {
  & svg {
    transform: translateX(-1px);
  }
}

@media screen and (max-width: 1800px) {
  .btn {
    border-radius: $borderRadiusStandart-k1600;
    font-size: $fontsizeBigButtons-k1600;

    padding-left: $marginMicro-k1600;
    padding-right: $marginMicro-k1600;

    & .btn_lefticon {
      & > * {
        width: $iconSizeStandart-k1600;
        height: $iconSizeStandart-k1600;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
    & .btn_text {
      font-size: inherit;
      line-height: $lineFontsizeBigButtons-k1600;
      margin-left: $marginMicro2-k1600;
      margin-right: $marginMicro2-k1600;
    }
    & .btn_righticon {
      & > * {
        width: $iconSizeStandart-k1600;
        height: $iconSizeStandart-k1600;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //sizes{
  .btn--width_standart {
    min-width: $widthMini-k1600;
    height: $heightStandart-k1600;
  }
  .btn--width_standart2x {
    min-width: $widthStandart2x-k1600;
    height: $heightStandart-k1600;
  }

  .btn--width_wide {
    width: auto; //$widthStandart-k1600;
    min-width: $widthStandart-k1600; //$widthMini-k1600;
    height: $heightStandart-k1600;
  }
  .btn--width_wider {
    width: auto; //$widthWide-k1600;
    min-width: $widthWide-k1600;
    height: $heightStandart-k1600;
  }
  .btn--width_max {
    width: 100%;
    min-width: $widthMini-k1600;
    height: $heightStandart-k1600;
  }

  .btn--width_auto {
    min-width: $widthMini-k1600;
    height: $heightStandart-k1600;
  }

  //sizes squre
  .btn--sqr_standart {
    border-radius: $borderRadiusStandart-k1600;
    width: $heightStandart-k1600;
    min-width: $heightStandart-k1600;
    height: $heightStandart-k1600;
  }

  .btn--sqr_mini {
    font-size: $fontsizeStandart-k1600;

    border-radius: $borderRadiusMini-k1600;
    width: $heightMedium-k1600;
    min-width: $heightMedium-k1600;
    height: $heightMedium-k1600;

    & .btn_text {
      line-height: $lineFontsizeStandart-k1600;
    }
  }

  .btn--sqr_micro {
    border-radius: $borderRadiusMicro-k1600;
    width: $heightMini-k1600;
    min-width: $heightMini-k1600;
    height: $heightMini-k1600;
    padding: 3px;
  }

  //sizes - mini height
  .btn--sqr_h-max {
    font-size: $fontsizeStandart-k1600;

    border-radius: $borderRadiusMicro-k1600;
    width: 100%;
    min-width: $heightMedium-k1600;
    height: $heightMini-k1600;

    text-wrap: none;
    text-overflow: ellipsis;

    & .btn_text {
      line-height: $lineFontsizeStandart-k1600;
    }

    & .btn_lefticon div {
      width: $iconSizeStandart-k1600 * 0.7;
      height: $iconSizeStandart-k1600 * 0.7;

      & svg {
        width: inherit;
        height: inherit;
      }
    }
  }

  .btn--sqr_h-standart {
  }

  //==================================\\

  // Loader
  .loader {
    border-top: $borderRadiusMicro-k1600 solid rgba(255, 255, 255, 0.2);
    border-right: $borderRadiusMicro-k1600 solid rgba(255, 255, 255, 0.2);
    border-bottom: $borderRadiusMicro-k1600 solid rgba(255, 255, 255, 0.2);
    border-left: $borderRadiusMicro-k1600 solid $colorLinesWhite;
  }

  // Состояние лоадинг
  .btn--loading {
    & .loader {
      width: $iconSizeStandart-k1600;
      height: $iconSizeStandart-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .btn {
    border-radius: $borderRadiusStandart-k1360;
    font-size: $fontsizeBigButtons-k1360;

    padding-left: $marginMicro-k1360;
    padding-right: $marginMicro-k1360;

    & .btn_lefticon {
      & > * {
        width: $iconSizeStandart-k1360;
        height: $iconSizeStandart-k1360;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
    & .btn_text {
      font-size: inherit;
      line-height: $lineFontsizeBigButtons-k1360;
      margin-left: $marginMicro2-k1360;
      margin-right: $marginMicro2-k1360;
    }
    & .btn_righticon {
      & > * {
        width: $iconSizeStandart-k1360;
        height: $iconSizeStandart-k1360;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //sizes{
  .btn--width_standart {
    min-width: $widthMini-k1360;
    height: $heightStandart-k1360;
  }
  .btn--width_standart2x {
    min-width: $widthStandart2x-k1360;
    height: $heightStandart-k1360;
  }
  .btn--width_wide {
    width: auto; //$widthStandart-k1360;
    min-width: $widthStandart-k1360;
    height: $heightStandart-k1360;
  }
  .btn--width_wider {
    width: auto;
    min-width: $widthWide-k1360;
    height: $heightStandart-k1360;
  }
  .btn--width_max {
    width: 100%;
    min-width: $widthMini-k1360;
    height: $heightStandart-k1360;
  }
  .btn--width_auto {
    min-width: $widthMini-k1360;
    height: $heightStandart-k1360;
  }

  //sizes squre
  .btn--sqr_standart {
    border-radius: $borderRadiusStandart-k1360;
    width: $heightStandart-k1360;
    min-width: $heightStandart-k1360;
    height: $heightStandart-k1360;
  }

  .btn--sqr_mini {
    font-size: $fontsizeStandart-k1360;

    border-radius: $borderRadiusMini-k1360;
    width: $heightMedium-k1360;
    min-width: $heightMedium-k1360;
    height: $heightMedium-k1360;

    & .btn_text {
      line-height: $lineFontsizeStandart-k1360;
    }
  }

  .btn--sqr_micro {
    border-radius: $borderRadiusMicro-k1360;
    width: $heightMini-k1360;
    min-width: $heightMini-k1360;
    height: $heightMini-k1360;
    padding: 3px;
  }

  //==================================\\

  // Loader
  .loader {
    border-top: $borderRadiusMicro-k1360 solid rgba(255, 255, 255, 0.2);
    border-right: $borderRadiusMicro-k1360 solid rgba(255, 255, 255, 0.2);
    border-bottom: $borderRadiusMicro-k1360 solid rgba(255, 255, 255, 0.2);
    border-left: $borderRadiusMicro-k1360 solid $colorLinesWhite;
  }

  // Состояние лоадинг
  .btn--loading {
    & .loader {
      display: flex;
      width: $iconSizeStandart-k1360;
      height: $iconSizeStandart-k1360;
    }
  }

  //sizes - mini height
  .btn--sqr_h-max {
    font-size: $fontsizeStandart-k1360;

    border-radius: $borderRadiusMicro-k1360;
    width: 100%;
    min-width: $heightMedium-k1360;
    height: $heightMini-k1360;

    text-wrap: none;
    text-overflow: ellipsis;

    & .btn_text {
      line-height: $lineFontsizeStandart-k1360;
    }

    & .btn_lefticon div {
      width: $iconSizeStandart-k1360 * 0.7;
      height: $iconSizeStandart-k1360 * 0.7;

      & svg {
        width: inherit;
        height: inherit;
      }
    }
  }

  .btn--sqr_h-standart {
  }
}
</style>
