<template>
  <div class="tablewrap" :class="{ 'tablewrap--disabled': disabled }">
    <div :class="{ 'rightzone_blockwithhead-head': headerText }">
      {{ headerText }}
    </div>

    <table
      :class="{
        'table--type1': true,
        'table--withhead': headerText,
        'table--with-hover': !disableHover,
      }"
      v-click-outside-v2="resetActiveCell"
      ref="table"
    >
      <thead>
        <tr>
          <!--class="col-usersurname"-->
          <th
            v-for="(header, index) in processedHeaders"
            :key="index"
            :width="header.width"
            :class="[
              header.class,
              {
                'unicol-colordark':
                  header.type === 'contacts' ||
                  header.type === 'edit' ||
                  header.type === 'linkto',
                'col-img': header.type === 'image',
                'col-btn': header.type === 'select',
              },
            ]"
          >
            {{ header.text }}
            <div v-if="header.icon" v-svg="{ name: header.icon }"></div>
            <slot :name="'slot_' + header.text" />
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-if="!tableLoading">
          <tr
            v-for="(el, index) in pagedProcessedDataArr"
            :key="index"
            class="row"
            :class="{
              'active-line': index === activeCell.row,
              duplicate: el[0] && el[0].duplicate,
            }"
          >
            <td
              title=""
              v-for="(header, headerIndex) in processedHeaders"
              :key="index + '-' + headerIndex"
              :class="[
                header.class,
                {
                  'unicol-withinput unicol-withinput--showinput':
                    header.class === 'col-element',
                  'col-status--alert':
                    header.type === 'status' &&
                    el[headerIndex].text === 'alert',
                  'col-status--on':
                    header.type === 'status' && el[headerIndex].text === 'on',
                  'col-status--off':
                    header.type === 'status' && el[headerIndex].text === 'off',
                  'col-contacts--nocontacts':
                    header.type === 'contacts' && !el[headerIndex].count,
                  'col-img': header.type === 'image',
                  'cell--containtrue':
                    (header.type === 'image' &&
                      el[headerIndex].images.length) ||
                    (header.type === 'link' && el[headerIndex].text),
                  'col-link': header.type === 'link',
                  'col-edit': header.type === 'linkto',
                  'col-edit': header.type === 'print',
                  'tbl-cellanalyzestatus--inprogress':
                    header.class === 'col-tbl-cellanalyzestatus' &&
                    el[headerIndex].id !== 5,
                  'tbl-cellanalyzestatus--ready':
                    header.class === 'col-tbl-cellanalyzestatus' &&
                    el[headerIndex].id === 5,
                  'active-cell':
                    index === activeCell.row &&
                    headerIndex === activeCell.column,
                  'col-warning--true':
                    header.class === 'col-warning' &&
                    el[headerIndex].text === 1,
                  'col-warning--true col-warning--fatality':
                    header.class === 'col-warning' &&
                    el[headerIndex].text === 2,
                },
              ]"
              @click="onTdClick(index, headerIndex)"
            >
              <template
                v-if="header.type === 'text' || header.type === 'duplicate'"
              >
                {{ el[headerIndex].text }}
              </template>
              <template v-if="header.type === 'contactType'">
                <div class="col_innerwrap">
                  <div
                    v-if="el[headerIndex].icon"
                    v-svg="{ name: el[headerIndex].icon }"
                  ></div>
                  <span class="col-contacttype_name">
                    {{ el[headerIndex].text }}
                  </span>
                </div>
              </template>
              <template v-if="header.type === 'input'">
                <div :key="header + '-' + headerIndex">
                  <input
                    v-if="
                      index === activeCell.row &&
                      headerIndex === activeCell.column &&
                      !disabled &&
                      !locked
                    "
                    class="inputintable"
                    type="text"
                    :key="header + '-' + headerIndex"
                    v-model="el[headerIndex].text"
                    ref="input"
                    @keydown.left.prevent="onInputPress('left')"
                    @keydown.right.prevent="onInputPress('right')"
                    @keydown.up.prevent="onInputPress('up')"
                    @keydown.down.prevent="onInputPress('down')"
                    @keypress.enter="
                      $emit('input', {
                        column: headerIndex,
                        row: index,
                        value: $refs.input[0].value,
                      })
                    "
                    @input="onTableInput($event, headerIndex, index)"
                    :state="disabled ? 'disabled' : ''"
                  />
                  <template v-else>
                    {{ el[headerIndex].text }}
                  </template>
                </div>
              </template>
              <template v-if="header.type === 'image'">
                <div v-svg="{ name: 'photo' }"></div>
                <img
                  v-if="el[headerIndex].images.length"
                  :src="getMainImageLinkThumb(el[headerIndex].images)"
                  :key="getMainImageLinkThumb(el[headerIndex].images)"
                />

                <div
                  class="tablepreview"
                  :class="{
                    'tablepreview--full': previewFullIndex === index,
                  }"
                  v-if="el[headerIndex].images.length > 0"
                >
                  <div class="tablepreview_imgslider">
                    <div class="slider-imgzone" @click="onShowPreview(index)">
                      <img
                        style="height: 100%; width: 100%"
                        :src="getImageLink(index, el[headerIndex].images)"
                        :key="getImageLink(index, el[headerIndex].images)"
                      />
                      <link
                        rel="preload"
                        as="image"
                        :href="getImageLink(index, el[headerIndex].images, +1)"
                      />
                      <link
                        rel="preload"
                        as="image"
                        :href="getImageLink(index, el[headerIndex].images, -1)"
                      />
                    </div>
                    <div class="slider-control">
                      <Button
                        text=""
                        size="s-micro"
                        color="transpgray"
                        icon="chevronleft"
                        :is-loading="false"
                        :loading-left="true"
                        :disabled="
                          getTextImageLink(index, el[headerIndex].images) === 1
                        "
                        @click="
                          setPreviewIndex(index, -1, el[headerIndex].images)
                        "
                      />

                      <div class="slider-control_counter">
                        {{ getTextImageLink(index, el[headerIndex].images) }}
                      </div>

                      <Button
                        text=""
                        size="s-micro"
                        color="transpgray"
                        icon="chevronright"
                        :is-loading="false"
                        :loading-left="true"
                        :disabled="
                          getTextImageLink(index, el[headerIndex].images) === 1
                        "
                        @click="
                          setPreviewIndex(index, +1, el[headerIndex].images)
                        "
                      />
                    </div>
                    <Button
                      class="slider-btnclose"
                      text=""
                      size="s-standart"
                      color="transpgray"
                      icon="x"
                      :is-loading="false"
                      :loading-left="true"
                      @click="onHidePreview"
                    />
                  </div>
                </div>
              </template>
              <template v-if="header.type === 'link'">
                <div v-svg="{ name: 'link' }"></div>

                <div class="tablepreview">
                  <a
                    :href="el[headerIndex].text"
                    class="tablepreview_link"
                    target="_blank"
                  >
                    {{ el[headerIndex].text }}
                  </a>
                </div>
              </template>
              <template v-if="header.type === 'status'">
                <div class="img-tick" v-svg="{ name: 'tick' }"></div>
                <div class="img-x" v-svg="{ name: 'x' }"></div>
              </template>
              <template v-if="header.type === 'contacts'">
                <div
                  v-svg="{ name: 'contacts' }"
                  @click="
                    el[headerIndex].count
                      ? $emit('contactClick', el[headerIndex].text)
                      : ''
                  "
                ></div>
              </template>
              <template v-if="header.type === 'edit'">
                <div
                  v-svg="{ name: header.icon || 'edit' }"
                  @click="editClick(el[headerIndex].text)"
                ></div>
              </template>
              <template v-if="header.type === 'linkto'">
                <div
                  v-if="el[headerIndex].text"
                  v-svg="{ name: 'linkto' }"
                  @click="linkToClick(el[headerIndex].text)"
                ></div>
              </template>
              <template v-if="header.type === 'select'">
                <Button
                  :text="$t('Table:SelectButtonText')"
                  size="h-max"
                  color="accent"
                  icon="tick2"
                  @click="editClick(el[headerIndex].text)"
                />
              </template>
              <template v-if="header.type === 'warning'">
                <slot name="warning-icons">
                  <div v-svg="{ name: 'warningdiese' }"></div>
                  <div v-svg="{ name: 'warningtriangle' }"></div>
                </slot>
              </template>
              <template v-if="header.type === 'print'">
                <div
                  v-svg="{ name: 'print' }"
                  @click="printClick(el[headerIndex].text)"
                ></div>
              </template>
            </td>
          </tr>
          <tr v-if="pagedProcessedDataArr.length === 0" class="row row--nodata">
            <td :colspan="processedHeaders.length">
              {{ $t('Table:NoData') }}
            </td>
          </tr>
        </template>
        <template v-if="tableLoading && showTable">
          <tr v-for="index in loadingCountItems" :key="index">
            <td
              v-for="(header, headerIndex) in processedHeaders"
              :key="headerIndex"
            >
              <div class="skeleton animation--wave" style="width: 100%"></div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="tableafter margintop--standart" v-if="!disablePagination">
      <!-- <Button
          text="Добавить контакт"
          size="wide"
          color="accentlines"
          icon="plus"
          :is-loading="false"
          :loading-left="false"/>-->
      <slot></slot>

      <div class="tableafter-pagiwrap">
        <Pagination
          class="marginleft--micro marginright--standart"
          v-if="countPages > 1 && !tableLoading"
          :count-pages="countPages"
          v-model="currentPage"
        />
        <Input
          v-if="isShowCountPages && !tableLoading"
          type="dropdown"
          label="-"
          icon=""
          state=""
          size="micro"
          color="grayLightLines"
          :dd-items="countPagesArr"
          @selected="onSelectedCountPages"
          :dd-selected-index="selectedIndexCountPages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Pagination from '@/components/views/Pagination';
import Input from '@/components/views/Input';
import { serverUrl } from '@/api/axios';
import browserHelper from '@/helpers/browserHelper';
import router from '@/router/router';
export default {
  name: 'AppTable',
  components: { Input, Button, Pagination },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataArr: {
      type: Array,
      default: () => [],
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    loadingCountItems: {
      type: Number,
      default: 10,
    },
    disabled: {
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      default: false,
    },
    disableHover: {
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      countPagesArr: [
        // { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      maxCountItemsInPage: 25, //10,
      selectedIndexCountPages: 0,
      activeCell: { column: null, row: null },
      previewFullIndex: false,
      indexImages: [],
      showTable: false,
      firstDataArr: true,
    };
  },
  methods: {
    onSelectedCountPages(event, dontPushRouter) {
      //console.log('onSelectedCountPages', event);

      this.maxCountItemsInPage = event.item.value;
      this.selectedIndexCountPages = event.index;
      if (!dontPushRouter) {
        this.$router
          .push({
            ...this.$route,
            query: {
              ...this.$route?.query,
              count: event.item.value,
            },
          })
          .catch(() => {});
      }
    },
    onTdClick(index, headerIndex) {
      let header = this.processedHeaders[headerIndex];
      switch (header.type) {
        case 'contacts': {
          if (this.pagedProcessedDataArr[index][headerIndex].count) {
            this.$emit(
              'contactClick',
              this.pagedProcessedDataArr[index][headerIndex].text
            );
          }
          break;
        }
        case 'edit': {
          if (!this.disabled)
            this.$emit(
              'editClick',
              this.pagedProcessedDataArr[index][headerIndex].text
            );
          break;
        }
        case 'print': {
          if (!this.disabled)
            this.$emit(
              'printClick',
              this.pagedProcessedDataArr[index][headerIndex].text
            );
          break;
        }
        case 'input': {
          this.setActiveCell(index, headerIndex);
        }
      }
    },
    setActiveCell(index, headerIndex) {
      if (this.locked) return;
      this.activeCell.row = index;
      this.activeCell.column = headerIndex;
      this.$nextTick(() => {
        if (this.activeCell.row !== null && this.activeCell.column !== null) {
          this.$refs.input[0].focus();
          this.$nextTick(() => {
            this.$refs.input[0].setSelectionRange(
              this.$refs.input[0].value.length + 1,
              this.$refs.input[0].value.length + 1
            );
            this.$refs.input[0].selectionEnd =
              this.$refs.input[0].value.length + 1;
          });
        }
      });
    },
    onInputPress(direction) {
      let newActiveRow = this.activeCell.row;
      let newActiveColumn = this.activeCell.column;
      switch (direction) {
        case 'right': {
          if (this.activeCell.column !== null) {
            newActiveColumn = this.activeCell.column + 1;
          }
          break;
        }
        case 'left': {
          if (this.activeCell.column !== null) {
            newActiveColumn = this.activeCell.column - 1;
          }
          break;
        }
        case 'up': {
          if (this.activeCell.row !== null) {
            newActiveRow = this.activeCell.row - 1;
          }
          break;
        }
        case 'down': {
          if (this.activeCell.row !== null) {
            newActiveRow = this.activeCell.row + 1;
          }
          break;
        }
      }

      if (
        newActiveColumn !== null &&
        newActiveColumn >= this.processedHeaders.length
      ) {
        newActiveColumn = 1;
      }
      if (newActiveColumn !== null && newActiveColumn < 1) {
        newActiveColumn = this.processedHeaders.length - 1;
      }
      if (
        newActiveRow !== null &&
        newActiveRow >= this.pagedProcessedDataArr.length
      ) {
        newActiveRow = 0;
      }
      if (newActiveRow !== null && newActiveRow < 0) {
        newActiveRow = this.pagedProcessedDataArr.length - 1;
      }

      this.setActiveCell(newActiveRow, newActiveColumn);
    },
    resetActiveCell() {
      this.setActiveCell(null, null);
    },
    onTableInput(event, headerIndex, index) {
      //Разрешает ввод только цифер и точку (+ запятую меняем на точку)
      let numsStr = event.target.value.replace(/[,]/g, '.');
      numsStr = numsStr.replace(/[^0-9,.]/g, ''); //Ломает сафари в 0 ->//.replace(/(?<=\..*)\./g, '');
      let firstOccuranceIndex = numsStr.search(/\./) + 1;
      numsStr =
        numsStr.substr(0, firstOccuranceIndex) +
        numsStr.slice(firstOccuranceIndex).replace(/\./g, ''); // Splitting into two string and replacing all the dots (.'s) in the second string

      this.$emit('input', { column: headerIndex, row: index, value: numsStr });
    },
    getMainImageLinkThumb(images) {
      return (
        serverUrl +
        this.getMainImageLink(images)
          .replace(serverUrl, '')
          .replace('/', '/thumbs/')
      );
    },
    getMainImageLink(images) {
      if (images instanceof Array && images.length > 0) {
        let find = images.find((image) => {
          if (image?.is_main) return true;
        });
        let main = find || images[0];
        return serverUrl + main.name;
      }
    },
    onShowPreview(index) {
      this.previewFullIndex = index;
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight =
        browserHelper.getScrollbarWidth() + 'px';
    },
    onHidePreview() {
      this.previewFullIndex = null;
      document.body.style.overflowY = 'scroll';
      document.body.style.paddingRight = '0';
    },
    getTextImageLink(index, images) {
      let count = images.length;
      if (count === 1) {
        return count;
      }
      let findIndex = images.findIndex((image) => {
        if (image?.is_main) return true;
      });
      let mainIndex = findIndex || 0;
      if (this.indexImages[index] === undefined) {
        this.$set(this.indexImages, index, mainIndex);
      }
      return `${this.indexImages[index] + 1} / ${count}`;
    },
    setPreviewIndex(index, item, images) {
      let val = (this.indexImages[index] + item) % images.length;
      if (val < 0) {
        val = images.length - 1;
      }
      this.$set(this.indexImages, index, val);
    },
    getImageLink(index, images, val) {
      if (!images) return '';
      val = val || 0;
      if (this.indexImages[index] === undefined) {
        let findIndex = images.findIndex((image) => {
          if (image?.is_main) return true;
        });
        let mainIndex = findIndex || 0;
        if (mainIndex === -1) mainIndex = 0;
        this.$set(this.indexImages, index, mainIndex);
      }
      let ii = (this.indexImages[index] + val) % images.length;
      ii = ii < 0 ? images.length - 1 : ii;
      return serverUrl + images[ii].name;
    },
    editClick(text) {
      if (!this.disabled) this.$emit('editClick', text);
    },
    linkToClick(text) {
      if (!this.disabled) this.$emit('linkToClick', text);
    },
    printClick(text) {
      if (!this.disabled) this.$emit('printClick', text);
    },
  },
  computed: {
    processedHeaders() {
      let arr = [];
      this.headers.forEach((value) => {
        arr.push({
          width: value.width ? value.width : '',
          text: value.text ? value.text : '',
          class: value.class ? value.class : '',
          type: value.type ? value.type : '',
          icon: value.icon ? value.icon : '',
        });
      });
      return arr;
    },
    processedDataArr() {
      let arr = [];

      this.dataArr.forEach((value) => {
        let subArr = [];
        value.forEach((v) => {
          subArr.push({
            text: v.text !== undefined && v.text !== null ? v.text : '',
            id: v.id !== undefined && v.id !== null ? v.id : '',
            icon: v.icon ? v.icon : '',
            count: v.count ? v.count : 0,
            images: v.images ? v.images : [],
            duplicate: v.duplicate ?? null,
          });
        });

        arr.push(subArr);
      });
      arr.forEach((value) => {
        let c = this.headers.length - value.length;
        for (let i = 0; i < c; i++) {
          value.push({ text: '', id: '', icon: '', images: [] });
        }
      });

      //Быть внимательным, можно через вотч менять
      //this.currentPage = 1;

      return arr;
    },
    pagedProcessedDataArr() {
      let pagedArr = [];
      let arr = this.processedDataArr;
      if (this.disablePagination) {
        pagedArr = arr;
      } else {
        if (arr.length > this.maxCountItemsInPage) {
          for (
            let i = 0 + (this.currentPage - 1) * this.maxCountItemsInPage;
            i <
            (this.currentPage - 1) * this.maxCountItemsInPage +
              this.maxCountItemsInPage;
            i++
          ) {
            if (arr[i]) {
              pagedArr.push(arr[i]);
            }
          }
        } else {
          pagedArr = arr;
        }
      }
      return pagedArr;
    },
    countPages() {
      let arr = this.processedDataArr;
      return Math.ceil(arr.length / this.maxCountItemsInPage);
    },
    isShowCountPages() {
      if (this.countPagesArr) {
        if (this.countPagesArr[0].value < this.processedDataArr.length) {
          return true;
        }
        if (this.selectedIndexCountPages !== 0) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    maxCountItemsInPage() {
      if (!this.tableLoading) {
        this.currentPage = 1;
        //console.log('maxCountItemsInPage this.currentPage = 1');
      }
    },
    dataArr(newValue, oldValue) {
      /*if (Number.isInteger(parseInt(this.$route?.query?.page))) {
        this.currentPage = parseInt(this.$route?.query?.page) || 1; // Учитываем отсутствие значения
      } else {
        this.currentPage = 1; // Учитываем отсутствие значения
      }*/
      if (!this.firstDataArr) {
        this.currentPage = 1;
        //console.log('dataArr this.currentPage = 1');
      }
      this.firstDataArr = false;
      //console.log(newValue, oldValue);
      //console.log('dataArr()');
    },
    countPages() {
      if (this.currentPage > this.countPages) {
        this.currentPage = 1;
        //console.log('countPages() currentPage = 1');
      }
    },
    currentPage() {
      this.$nextTick(() => {
        if (!browserHelper.isInViewport(this.$refs.table)) {
          this.$refs.table.scrollIntoView(true);
        }
        //console.log('currentPage page before', this.currentPage);
        //console.log('this.countPages', this.countPages);
        if (!this.tableLoading) {
          if (this.currentPage > this.countPages) {
            this.currentPage = 1;
            //console.log('currentPage() currentPage = 1');
          }

          //console.log('currentPage page', this.currentPage);
          this.$router
            .push({
              path: this.$route.path, // Текущий путь
              query: {
                ...this.$route.query,
                page: this.currentPage,
              },
            })
            .catch(() => {});
        }
      });
    },
    '$route.query.page': {
      handler(newValue, oldValue) {
        //console.log('Параметр page изменился:', { newValue, oldValue });
        //console.log(parseInt(newValue));
        if (Number.isInteger(parseInt(newValue))) {
          this.currentPage = parseInt(newValue) || 1; // Учитываем отсутствие значения
        } else {
          this.currentPage = 1; // Учитываем отсутствие значения
        }
      },
      immediate: true, // Выполнить сразу при загрузке компонента
    },
  },
  /*beforeMount() {
    if (this.$route?.query?.count) {
      let findIndex = this.countPagesArr.findIndex(
        (el) => el.text === this.$route?.query?.count
      );
      findIndex = findIndex < 0 ? 0 : findIndex;
      this.onSelectedCountPages(
        {
          item: { value: this.countPagesArr[findIndex]?.value },
          index: findIndex,
        },
        true
      );
    }
  },*/
  created() {
    //console.log('Created');
    if (this.$route?.query?.count) {
      let findIndex = this.countPagesArr.findIndex(
        (el) => `${el.value}` === `${this.$route?.query?.count}`
      );
      findIndex = findIndex < 0 ? 0 : findIndex;
      /*console.log({
        'this.$route?.query?.count': this.$route?.query?.count,
        findIndex: findIndex,
        'this.countPagesArr[findIndex]?.value':
          this.countPagesArr[findIndex]?.value,
      });*/
      this.onSelectedCountPages({
        item: { value: this.countPagesArr[findIndex]?.value },
        index: findIndex,
      });
    }
    setTimeout(() => {
      this.showTable = true;
    }, 150);
  },
};
</script>

<style lang="scss" scoped>
//Скрытие стрелок вверх вниз у намбера
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
