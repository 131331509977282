<!--Translated-->
<template>
  <div class="pageprint" :class="{ 'pageprint--horizontal': horizontal }">
    <LoaderBlock v-if="!p" />
    <div class="printbtnswrap">
      <div class="printbtns">
        <Button
          class="marginright--standart"
          :text="$t('PrintProgramDefense:ButtonPrint')"
          size="h-max"
          color="accent"
          icon="print"
          :is-loading="false"
          :loading-left="false"
          @click="printWindow"
          :disabled="!p"
        />

        <Button
          :text="$t('PrintProgramDefense:ButtonExit')"
          size="h-max"
          color="accentlines"
          icon="x"
          :is-loading="false"
          :loading-left="false"
          @click="closeTab"
        />
      </div>
    </div>

    <div v-if="p" class="printcontent printcontent--programdefence">
      <!--Колонтитул - верхний-->
      <div id="header" class="cwrap">
        <div class="cblock printtitle">
          <div class="printtitle_logo">
            <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
          </div>
          <div class="printtitle_h1 print-h1">
            {{ $t('PrintProgramDefense:Title') }}
          </div>
          <div class="printtitle_date print-h2">
            {{ new Date().toLocaleDateString('ru-RU') }}
          </div>
        </div>
      </div>

      <div class="cwrap margintop--mini cwrap--internalpadding el--borders-all">
        <div class="chead">
          <div class="chead__line-block">
            <div class="vsvg chead_icon" v-svg="{ name: 'info' }"></div>
            <div class="print-h2">
              {{ p.program.name || $t('PrintProgramDefense:NoProgramName') }}
            </div>
          </div>
        </div>

        <div class="margintop--micro marginbottom--micro print-text">
          <div v-if="p.program.description">({{ p.program.description }})</div>
        </div>
        <div class="cblock flex-between print-text">
          <div class="printblock printblock--clientdata">
            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:ClientName') }}
              </div>
              <div class="printblock_item-data">
                {{ p.program.client_name || p.client?.name || '-' }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:ClientCode') }}
              </div>
              <div class="printblock_item-data">
                {{ p.program.client_code || p.client?.code || '-' }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:ClientDiscount') }}
              </div>
              <div class="printblock_item-data">
                {{ p.program.client_discount || 0 }}%
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:GeoName') }}
              </div>
              <div class="printblock_item-data">
                {{ geoPlainDataAsMap.get(p.program.geo_id).text }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:ZoneName') }}
              </div>
              <div class="printblock_item-data">
                {{ p.program.zone_name || '-' }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:Area') }}
              </div>
              <div class="printblock_item-data">
                {{ p.program.place_area }}
                {{ $t('PrintProgramDefense:AreaHa') }}
              </div>
            </div>
          </div>

          <div class="printblock printblock--culture">
            <!--<div class="printblock_item">
                  <div class="printblock_item-key">Категория культуры:</div>
                  <div class="printblock_item-data">
                {{ culture.category.name }}
              </div>
            </div>
            -->

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:CultureName') }}
              </div>
              <div class="printblock_item-data">{{ culture.name }}</div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:CultureAge') }}
              </div>
              <div class="printblock_item-data">
                {{ culture.age }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:CultureSort') }}
              </div>
              <div class="printblock_item-data">
                {{ culture.sorts || '-' }}
              </div>
            </div>

            <div class="printblock_item">
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:Method') }}
              </div>
              <div class="printblock_item-data">{{ calcMethod }}</div>
            </div>

            <div
              class="printblock_item"
              v-if="p.currentManager?.full_name || p.currentManager?.name"
            >
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:Manager') }}
              </div>
              <div class="printblock_item-data">
                {{ p.currentManager?.name }} {{ p.currentManager?.full_name }}
              </div>
            </div>

            <div
              class="printblock_item"
              v-if="p.currentManagerContacts?.at(0)?.value"
            >
              <div class="printblock_item-key">
                {{ $t('PrintProgramDefense:ManagerPhone') }}
              </div>
              <div class="printblock_item-data">
                {{ p.currentManagerContacts.at(0).value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <!-- Печать - Список фаз -->
        <div class="cwrap">
          <div
            v-for="phase in phases"
            :key="phase.id"
            class="cblock phase margintop--mini cwrap--internalpadding el--borders-all"
            :class="{ 'phasewrap-temporary': phase.is_temporary }"
          >
            <section class="print-nobreak">
              <div class="chead marginbottom--mini">
                <div class="chead__line-block">
                  <div
                    class="vsvg chead_icon"
                    v-svg="{ name: 'phases_flower' }"
                  ></div>
                  <div class="print-h2">
                    <span v-if="phase.is_temporary"
                      >{{ $t('PrintProgramDefense:TempPhase') }} -
                    </span>
                    {{ phase.name }}
                  </div>
                </div>
              </div>

              <div class="cblock__line phase_dashline">
                <div
                  class="phase_dashline-sqr phase_dashline-sqr--num el--borders-all"
                >
                  {{ phase.order }}
                </div>
                <div
                  class="phase_dashline-sqr phase_dashline-sqr--img el--borders-all"
                >
                  <img :src="phase.image" />
                </div>
                <div
                  class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all"
                >
                  <div class="phase_dashline-sqr__ico">
                    <div v-svg="{ name: 'phases_timetable' }"></div>
                  </div>
                  <div class="phase_dashline-sqr__name">
                    {{ $t('PrintProgramDefense:PhasePeriod') }}
                  </div>
                  <div class="phase_dashline-sqr__content">
                    <div class="phase_dashline--longtext">
                      <div>
                        <span
                          >{{
                            $t('PrintProgramDefense:PhasePeriodFrom')
                          }}: </span
                        ><span>{{ phase.period.start }}</span>
                      </div>
                      <div>
                        <span
                          >{{ $t('PrintProgramDefense:PhasePeriodTo') }}: </span
                        ><span>{{ phase.period.end }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all"
                >
                  <div class="phase_dashline-sqr__ico">
                    <div v-svg="{ name: 'phases_flask' }"></div>
                  </div>
                  <div class="phase_dashline-sqr__name">
                    {{ $t('PrintProgramDefense:PhaseVolume') }}
                  </div>
                  <div class="phase_dashline-sqr__content">
                    <span>{{
                      phase.volume_per_ha
                        ? phase.volume_per_ha +
                          ' ' +
                          $t('PrintProgramDefense:PhaseVolumeVal')
                        : ''
                    }}</span>
                  </div>
                </div>
                <div
                  class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all"
                >
                  <div class="phase_dashline-sqr__ico">
                    <div v-svg="{ name: 'phases_money' }"></div>
                  </div>
                  <div class="phase_dashline-sqr__name">
                    {{ $t('PrintProgramDefense:PhasePrice') }}
                    <br />
                    {{ calcMethodShort }}
                    {{ isWater ? $t('ProgramTables:ListPests:Volume2') : '' }}
                  </div>
                  <div class="phase_dashline-sqr__content">
                    <span
                      >{{ formatNumber(phase.price) }}
                      {{ $t('PrintProgramDefense:PhasePriceGel') }}</span
                    >
                  </div>
                </div>
              </div>
            </section>

            <!--ТАБЛА Вр.Об.-->
            <table
              v-if="phase.pestsProducts.length"
              class="table--type3 print-text margintop--mini table--defense"
            >
              <thead>
                <tr>
                  <th class="tbl-cellproblem">
                    {{ $t('PrintProgramDefense:Pests:Name') }}
                  </th>
                  <th class="tbl-cellproduct">
                    {{ $t('PrintProgramDefense:Pests:Products') }}
                  </th>

                  <th v-if="showSubstance" class="tbl-cellelement">
                    {{ $t('PrintProgramDefense:Pests:ProductsSubstance') }}
                  </th>
                  <th v-if="showManufacturer" class="tbl-cellvendor">
                    {{ $t('PrintProgramDefense:Pests:ProductsVendor') }}
                  </th>
                  <th v-if="showCountry" class="tbl-cellcountry">
                    {{ $t('PrintProgramDefense:Pests:ProductsCountry') }}
                  </th>

                  <th class="tbl-cellinpt">
                    {{ $t('PrintProgramDefense:Pests:ProductsVolume') }}
                  </th>
                  <th class="tbl-cellprice">
                    {{ $t('PrintProgramDefense:Pests:ProductsPrice') }}
                  </th>
                </tr>
              </thead>

              <tbody
                :class="`tbl-color--${pestProducts[0].category.class}`"
                v-for="pestProducts in phase.pests"
                :key="pestProducts[0].id"
              >
                <tr
                  v-for="(product, indexOfProduct) in pestProducts"
                  class="row"
                  :key="indexOfProduct"
                >
                  <td
                    v-if="indexOfProduct === 0"
                    class="tbl-cellproblem"
                    :rowspan="pestProducts.length"
                  >
                    <div class="tbl_info">
                      <div class="tbl_info-wrap">
                        <div
                          v-svg="{ name: pestProducts[0].category.icon }"
                        ></div>
                      </div>

                      <div class="tbl_info-celltext">
                        {{ pestsAsMap[pestProducts[0].pest_id].name }}
                      </div>
                    </div>
                  </td>
                  <td class="tbl-cellproduct">
                    {{ product.productLink.name }}
                  </td>
                  <td v-if="showSubstance" class="tbl-cellelement">
                    {{ product.productLink.active_substance }}
                  </td>
                  <td v-if="showManufacturer" class="tbl-cellvendor">
                    {{
                      manufacturersAsMap[product.productLink.manufacturer_id]
                        ?.name
                    }}
                  </td>
                  <td v-if="showCountry" class="tbl-cellcountry">
                    {{
                      manufacturersCountriesAsMap[
                        product.productLink.manufacturer_country_id
                      ]?.name
                    }}
                  </td>
                  <td class="tbl-cellinpt">
                    {{ formatNumber(product.quantity) }}
                  </td>
                  <td class="tbl-cellprice">
                    {{ formatNumber(product.price) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!--ТАБЛА Стимул.-->
            <table
              v-if="phase.stimulatorsProducts.length"
              class="table--type3 print-text margintop--mini table--defense"
            >
              <thead>
                <tr>
                  <th class="tbl-cellproblem">
                    {{ $t('PrintProgramDefense:Stimulations:Name') }}
                  </th>
                  <th class="tbl-cellproduct">
                    {{ $t('PrintProgramDefense:Stimulations:Products') }}
                  </th>

                  <th v-if="showSubstance" class="tbl-cellelement">
                    {{
                      $t('PrintProgramDefense:Stimulations:ProductsSubstance')
                    }}
                  </th>
                  <th v-if="showManufacturer" class="tbl-cellvendor">
                    {{ $t('PrintProgramDefense:Stimulations:ProductsVendor') }}
                  </th>
                  <th v-if="showCountry" class="tbl-cellcountry">
                    {{ $t('PrintProgramDefense:Stimulations:ProductsCountry') }}
                  </th>

                  <th class="tbl-cellinpt">
                    {{ $t('PrintProgramDefense:Stimulations:ProductsVolume') }}
                  </th>
                  <th class="tbl-cellprice">
                    {{ $t('PrintProgramDefense:Stimulations:ProductsPrice') }}
                  </th>
                </tr>
              </thead>

              <tbody v-for="sTypes in phase.stimulators" :key="sTypes[0].id">
                <tr class="row" v-for="(sType, index) of sTypes" :key="index">
                  <td
                    v-if="index === 0"
                    :rowspan="sTypes.length"
                    class="tbl-cellproblem"
                  >
                    {{ sType.sTypeLink.name }}
                  </td>
                  <td class="tbl-cellproduct">{{ sType.productLink.name }}</td>
                  <td v-if="showSubstance" class="tbl-cellelement">
                    {{ sType.productLink.active_substance }}
                  </td>
                  <td v-if="showManufacturer" class="tbl-cellvendor">
                    {{
                      manufacturersAsMap[sType.productLink.manufacturer_id]
                        ?.name
                    }}
                  </td>
                  <td v-if="showCountry" class="tbl-cellcountry">
                    {{
                      manufacturersCountriesAsMap[
                        sType.productLink.manufacturer_country_id
                      ]?.name
                    }}
                  </td>
                  <td class="tbl-cellinpt">
                    {{ formatNumber(sType.quantity) }}
                  </td>
                  <td class="tbl-cellprice">
                    {{ formatNumber(sType.price) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Печать - Сумма фаз -->
        <section class="print-nobreak">
          <div
            class="cwrap margintop--mini cwrap--internalpadding el--borders-all"
          >
            <!--TODO: №7 Печать Защиты Менеджера -->
            <!--TODO: Stas верстка - ГОТОВО 15.01.2025-->
            <!--TODO: Витя Логика цен для ЗАЩИТЫ У МЕНЕДЖЕРА - ПЕЧАТЬ!!!-->
            <!--TODO: САША Перевод-->

            <div class="cblock cblock--totalprice2025print">
              <div class="cblock__line flex-between">
                <div class="print-h1">
                  <span>{{ $t('PrintProgramDefense:PhasesAll') }} </span>
                  <span> {{ phases.length }}</span>
                </div>

                <div class="cblock__line-block print-h1">
                  <span>{{ $t('ProgramTables:Sum') }}</span>
                  <span>{{ calcMethodShort }}:</span>
                  <span class="this-price"
                    >₾ {{ formatNumber(totalCostNew) }}</span
                  >
                </div>
              </div>

              <div class="cblock__line" v-if="totalCostNewDiscount > 0">
                <div class="cblock__line-block print-h1">
                  <span>{{ $t('ProgramTables:DiscountSum') }}</span>
                  <span>{{ calcMethodShort }}:</span>
                  <span class="this-price"
                    >₾ {{ formatNumber(totalCostNewDiscount) }}</span
                  >
                </div>
              </div>

              <div class="cblock__line" v-if="totalCostNewDiscount > 0">
                <div
                  class="cblock__line-block print-h1 uni_text-accentcolor uni_text-bold"
                >
                  <span>{{ $t('ProgramTables:DiscountSumFinal') }}</span>
                  <span>{{ calcMethodShort }}:</span>

                  <span class="this-price"
                    >₾ {{ formatNumber(totalCostNewWithDiscount) }}</span
                  >
                </div>
              </div>

              <div class="cblock__line uni_text-graysmalltext">
                <div class="">* {{ $t('ProgramTables:Tooltip.Text') }}</div>
              </div>
            </div>

            <!--ЭТО СТАРЫЙ БЛОК С ЦЕНОЙ ПОСЛЕ ФАЗ-->
            <!--                <div class="print-h1">
                  <span> {{ $t('PrintProgramDefense:PhasesAllPrice') }} </span>
                  <span> ₾ {{ formatNumber(totalCostNew) }} </span>
                </div>


                <div v-if="p.program.client_discount > 0" class="print-h1">
                  <span> Скидка: </span>
                  <span> ₾ {{ formatNumber(totalCostNewDiscount) }} </span>
                </div>


                <div v-if="p.program.client_discount > 0" class="print-h1 uni_text-accentcolor">
                  <span> Цена со скидкой: </span>
                  <span> ₾ {{ formatNumber(totalCostNewWithDiscount) }} </span>
                </div>
                -->
          </div>
        </section>
      </section>

      <section class="print-nobreak">
        <!-- Печать - Таблица Итог - вредные об -->
        <div class="cwrap print-nobreak margintop--standart">
          <div class="chead marginbottom--micro">
            <div class="chead__line-block">
              <div class="vsvg chead_icon" v-svg="{ name: 'badobject' }"></div>
              <div class="print-h2">
                {{ $t('PrintProgramDefense:PestsAll:Title') }}
              </div>
            </div>
          </div>

          <table class="table--type3 print-text table--paycheck">
            <thead>
              <tr>
                <th class="tbl-cellproduct">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsName') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsPick') }}
                </th>
                <th v-if="isWater" class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume1') }}
                  {{ calcMethodShort }}
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume2') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume3') }}
                  1
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume4') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume3') }}
                  {{ p.program.place_area }}
                  {{ $t('PrintProgramDefense:PestsAll:ProductsVolume4') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsCount') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:PestsAll:ProductsCountAvg') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="row" v-for="row in pestsProductsMapped" :key="row.id">
                <td>{{ row.product.name }}</td>
                <td>{{ row.product.picking }}</td>
                <td v-if="isWater">{{ formatNumber(row.quantity) }}</td>
                <td>{{ formatNumber(row.totalVolume) }}</td>
                <td>{{ formatNumber(row.forArea) }}</td>
                <td>{{ formatNumber(row.packages) }}</td>
                <td>{{ row.packagesInt.toLocaleString('ru-RU') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Печать - Таблица Итог - стимуляторы-->
        <div class="cwrap print-nobreak margintop--mini">
          <div class="chead marginbottom--micro">
            <div class="chead__line-block">
              <div class="vsvg chead_icon" v-svg="{ name: 'stimulator' }"></div>
              <div class="print-h2">
                {{ $t('PrintProgramDefense:StimulationsAll:Title') }}
              </div>
            </div>
          </div>

          <table class="table--type3 print-text table--paycheck">
            <thead>
              <tr>
                <th class="tbl-cellproduct">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductName') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductPick') }}
                </th>
                <!--fixme-->
                <th v-if="isWater" class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume1') }}
                  {{ calcMethodShort }}
                  <!--{{ $t('PrintProgramDefense:StimulationsAll:ProductVolume2') }}-->
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume3') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume4') }}
                  1
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume5') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume4') }}
                  {{ p.program.place_area }}
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductVolume5') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{ $t('PrintProgramDefense:StimulationsAll:ProductCount') }}
                </th>
                <th class="tbl-cellpaycheck">
                  {{
                    $t('PrintProgramDefense:StimulationsAll:ProductCountAvg')
                  }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="row"
                v-for="row in stimulatorProductsMapped"
                :key="row.id"
              >
                <td>{{ row.product.name }}</td>
                <td>{{ row.product.picking }}</td>
                <td v-if="isWater">{{ formatNumber(row.quantity) }}</td>
                <td>{{ formatNumber(row.totalVolume) }}</td>
                <td>{{ formatNumber(row.forArea) }}</td>
                <td>{{ formatNumber(row.packages) }}</td>
                <td>{{ row.packagesInt.toLocaleString('ru-RU') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Печать - Итог цен и дат -->

        <section class="print-nobreak">
          <div class="cwrap print-nobreak margintop--standart">
            <div class="chead">
              <div class="chead__line-block">
                <div class="vsvg chead_icon" v-svg="{ name: 'paycheck' }"></div>
                <div class="print-h2">
                  {{ $t('PrintProgramDefense:Summary:Title') }}
                </div>
              </div>
            </div>
            <div class="cblock flex-between margintop--mini print-text">
              <div class="printblock printblock--dates">
                <div class="printblock_item">
                  <div class="printblock_item-key">
                    {{ $t('PrintProgramDefense:Summary:DateCreate') }}
                  </div>
                  <div class="printblock_item-data">{{ createdAt }}</div>
                </div>
                <div class="printblock_item">
                  <div class="printblock_item-key">
                    {{ $t('PrintProgramDefense:Summary:DateEdit') }}
                  </div>
                  <div class="printblock_item-data">{{ updatedAt }}</div>
                </div>
                <!-- <div class="printblock_item">
                  <div class="printblock_item-key">Дата фиксирования цены:</div>
                  <div class="printblock_item-data">13:45 22.04.2022</div>
                </div> -->
              </div>
              <div class="printblock printblock--prices">
                <div v-if="p?.program?.rate_dollar" class="printblock_item">
                  <div class="printblock_item-key">
                    {{ $t('PrintProgramDefense:Summary:SumDollar') }}
                  </div>
                  <div class="printblock_item-data">
                    {{
                      totalPriceWithDiscountUSD
                        ? '$' + formatNumber(totalPriceWithDiscountUSD)
                        : '-'
                    }}
                  </div>
                </div>
                <div v-if="p?.program?.rate_euro" class="printblock_item">
                  <div class="printblock_item-key">
                    {{ $t('PrintProgramDefense:Summary:SumEuro') }}
                  </div>
                  <div class="printblock_item-data">
                    {{
                      totalPriceWithDiscountEU
                        ? '€' + formatNumber(totalPriceWithDiscountEU)
                        : '-'
                    }}
                  </div>
                </div>
                <div class="printblock_item">
                  <div class="printblock_item-key">
                    {{ $t('PrintProgramDefense:Summary:SumGel') }}
                  </div>
                  <div class="printblock_item-data">
                    {{ totalPrice ? '₾' + formatNumber(totalPrice) : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="cwrap print-nobreak margintop--mini cwrap--internalpadding el--borders-all el--borders-coloraccent"
          >
            <div class="print-total cblock flex-between">
              <div class="print-h1">
                <span> {{ $t('PrintProgramDefense:Summary:Discount') }} </span>
                <span>
                  {{ p?.program?.client_discount || 0 }}% (₾
                  {{
                    formatNumber(
                      (totalPrice / 100) * (p?.program?.client_discount || 0)
                    )
                  }})
                </span>
              </div>

              <div class="print-h1">
                <span>{{ $t('PrintProgramDefense:Summary:Sum') }} </span>
                <span>
                  ₾
                  {{
                    formatNumber(
                      totalPrice -
                        (totalPrice / 100) * (p?.program?.client_discount || 0)
                    )
                  }}
                </span>
              </div>
            </div>
          </div>

          <!--Колонтитул - нижний-->
          <div id="footer" class="margintop--standart cwrap">
            <div class="cblock printtitle">
              <div class="printtitle_logo">
                <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
              </div>
              <div class="printtitle_contact">
                <div class="vsvg" v-svg="{ name: 'contactwebsite' }"></div>
                <div>cartlis.ge</div>
              </div>
              <div class="printtitle_contact">
                <div class="vsvg" v-svg="{ name: 'contactphone' }"></div>
                <div>+995 32 244 55 42</div>
              </div>
              <div class="printtitle_contact">
                <div class="vsvg" v-svg="{ name: 'contactmail' }"></div>
                <div>info@cartlis.ge</div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import apiBase from '@/api/plantsCare';
import apiClient from '@/api/clientsArea/programs';
import deepFreeze from 'deep-freeze';
import groupBy from 'lodash.groupby';
import cloneDeep from 'lodash.clonedeep';
import { format } from 'date-fns';
import { ka } from 'date-fns/locale';
import { mapGetters, mapState } from 'vuex';

import Button from '@/components/views/Button';
import LoaderBlock from '@/components/blocks/LoaderBlock';

import { serverUrl } from '@/api/axios.js';
import { staticData } from '@/store/modules/plantsCare';
import { mutationTypes as geoBaseMutaionTypes } from '@/store/modules/glossaryGeobase';
import { mapPestCategoryToProductCategory } from '../components/mapCategoryToPest';
import routeNames from '@/router/routeNames';

export default {
  name: 'PrintProgramDefense',
  components: { Button, LoaderBlock },
  data() {
    return {
      isLoading: true,
      p: null,
    };
  },
  methods: {
    printWindow: function () {
      window.print();
    },
    closeTab() {
      window.close();
    },
    formatNumber(num) {
      return num.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    mapRows(list, forPests = true) {
      const result = [];
      for (const phase of this.p.programPhases) {
        const products = list.filter(
          (pp) =>
            pp.program_phase_id === phase.id &&
            pp.is_selected &&
            pp.quantity > 0
        );
        for (const objPropuct of products) {
          let product = result.find((p) => p.id === objPropuct.product_id);
          if (!product) {
            product = { id: objPropuct.product_id, quantity: 0 };
            result.push(product);
          }
          product.quantity += objPropuct.quantity;
        }
      }
      return result
        .map((p) => ({
          ...p,
          product: this.productsAsMap[p.id],
        }))
        .map((e) => this.calc(e, forPests))
        .filter(({ packagesInt }) => Number.isSafeInteger(packagesInt));
    },
    round(num) {
      const result = Math.round(num);
      return result === 0 && num > 0 ? 1 : result;
    },
    calc(input, forPests) {
      let totalVolume = input.quantity;
      if (this.isWater) {
        totalVolume = this.phases.reduce((acc, cur) => {
          let q = 0;
          const list = forPests
            ? this.p.programPestsProducts
            : this.p.programTypesStimulationProducts;

          const products = list.filter(
            (pp) =>
              pp.program_phase_id === cur.id &&
              pp.product_id === input.id &&
              pp.is_selected === 1
          );
          for (const product of products) {
            const divider =
              this.p.program.calculation_method_id === 2 ? 1000 : 100;
            q += (product.quantity * cur.volume_per_ha) / divider;
          }
          return acc + q;
        }, 0);
      }

      const forArea = totalVolume * this.p.program.place_area;
      const packages = forArea / input.product.picking;
      const packagesInt = this.round(packages);
      return {
        ...input,
        totalVolume,
        forArea,
        packages,
        packagesInt,
        totalPrice: packagesInt * input.product.price,
      };
    },
  },
  mounted() {
    const api = this.isClientArea ? apiClient : apiBase;
    api
      .getEditProgramData(this.$route.params.id, 0, 0, Date.now())
      .then((data) => {
        this.p = deepFreeze(data);
        //симуляция скидки
        // this.p = deepFreeze({
        //   ...data,
        //   program: {
        //     ...data.program,
        //     client_discount: data.program.client_discount || 10,
        //   },
        // });
        this.$store.commit(geoBaseMutaionTypes.getGeobaseSuccess, data.geoBase);

        document.title = this.$t('PrintProgramDefense:PageTitle:Text');
        document.title += ` «${
          this.p.program.name || this.$t('PrintProgramDefense:PageTitle:NoName')
        }»`;
        if (this.p.program.client_name) {
          document.title += ` – ${this.p.program.client_name}`;
        } else if (this.p.client.name) {
          document.title += ` – ${this.p.client.name}`;
        }
        document.title += ` (${new Date().toLocaleDateString('ru-RU')})`;
      });
  },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      productsPestsSubcategories: (state) =>
        state.products.productsPestsSubcategories,
    }),
    isClientArea() {
      return routeNames.userPrintProgramDefense === this.$route.name;
    },
    horizontal() {
      return [
        this.$route.query.substance,
        this.$route.query.manufacturer,
        this.$route.query.country,
      ].some((e) => e === 'true');
    },
    culture() {
      if (!this.p) return;
      const culture = cloneDeep(
        this.p.cultures.find(({ id }) => id === this.p.program.culture_id)
      );
      culture.category = this.p.categoriesCultures.find(
        ({ id }) => id === culture.category_id
      );
      const age = this.p.program.culture_age;
      culture.age = age < 1 ? '<1' : age > 10 ? '11+' : age;
      culture.sorts = this.p.programCulturesSorts
        .map(({ sort_id }) =>
          this.p.culturesSorts.find(({ id }) => id === sort_id)
        )
        .map((sort) => sort.name)
        .join(', ');
      return culture;
    },
    calcMethod() {
      if (!this.p) return;
      return staticData.calculationMethods[
        this.p.program.calculation_method_id
      ];
    },
    calcMethodShort() {
      if (!this.p) return;
      return staticData.calculationMethodsShort[
        this.p.program.calculation_method_id
      ];
    },
    isWater() {
      return this.p ? this.p.program.calculation_method_id < 3 : undefined;
    },
    showManufacturer() {
      return this.$route.query.manufacturer === 'true';
    },
    showSubstance() {
      return this.$route.query.substance === 'true';
    },
    showCountry() {
      return this.$route.query.country === 'true';
    },
    ...Object.fromEntries(
      ['pests', 'products', 'manufacturers', 'manufacturersCountries'].map(
        (name) => [
          `${name}AsMap`,
          function () {
            return this.p
              ? Object.fromEntries(this.p[name].map((p) => [p.id, p]))
              : [];
          },
        ]
      )
    ),
    phases() {
      if (!this.p) return;
      return cloneDeep(this.p.programPhases)
        .sort((a, b) => a.order - b.order)
        .map((phase) => {
          phase.image = phase.main_image
            ? `${serverUrl}${phase.main_image}`.replace(
                'uploads/',
                'uploads/thumbs/'
              )
            : null;

          const start = format(
            new Date(2020, phase.start_month - 1, phase.start_day),
            'd MMMM',
            { locale: ka }
          );
          const end = format(
            new Date(2020, phase.end_month - 1, phase.end_day),
            'd MMMM',
            { locale: ka }
          );
          phase.period = { start, end };

          const filterProducts = (product) =>
            product.program_phase_id === phase.id && product.is_selected;

          phase.pestsProducts = cloneDeep(this.p.programPestsProducts)
            .filter(filterProducts)
            .map((e) => {
              const pestLink = this.p.pests.find(({ id }) => id == e.pest_id);
              const category = this.productsPestsSubcategories.find(
                ({ id }) =>
                  id === mapPestCategoryToProductCategory(pestLink.category_id)
              );
              const productLink = this.p.products.find(
                ({ id }) => id === e.product_id
              );
              if (!e.quantity) e.quantity = 0;
              const multiplier = productLink.picking
                ? productLink.price / productLink.picking
                : 0;
              e.price = (e.quantity ?? 0) * multiplier;
              return { ...e, pestLink, productLink, category };
            })
            .filter((e) => e.quantity > 0);

          phase.stimulatorsProducts = cloneDeep(
            this.p.programTypesStimulationProducts
          )
            .filter(filterProducts)
            .map((e) => {
              const sTypeLink = this.p.typesStimulation.find(
                ({ id }) => id == e.type_stimulation_id
              );
              const productLink = this.p.products.find(
                ({ id }) => id === e.product_id
              );
              if (!e.quantity) e.quantity = 0;
              const multiplier = productLink.picking
                ? productLink.price / productLink.picking
                : 0;
              e.price = (e.quantity ?? 0) * multiplier;
              return { ...e, sTypeLink, productLink };
            })
            .filter((e) => e.quantity > 0);

          phase.pests = groupBy(phase.pestsProducts, 'pest_id');
          phase.stimulators = groupBy(
            phase.stimulatorsProducts,
            'type_stimulation_id'
          );

          phase.price = [
            ...phase.pestsProducts,
            ...phase.stimulatorsProducts,
          ].reduce((acc, cur) => {
            const product = this.productsAsMap[cur.product_id];
            const multiplier = product.picking
              ? product.price / product.picking
              : 0;
            return acc + (cur.quantity ?? 0) * multiplier;
          }, 0);

          return phase;
        });
    },
    activePhases() {
      return this.phases.filter((p) => p.price > 0);
    },
    totalWaterVolume() {
      return this.activePhases.reduce((a, b) => a + b.volume_per_ha, 0);
    },
    totalCostNew() {
      const pricePerHa = this.totalPrice / this.p.program.place_area;
      return this.isWater
        ? pricePerHa *
            ((this.p.program.calculation_method_id === 2 ? 1000 : 100) /
              this.totalWaterVolume) *
            this.activePhases.length
        : pricePerHa;
    },
    totalCostNewDiscount() {
      return (this.totalCostNew * this.p.program.client_discount) / 100;
    },
    totalCostNewWithDiscount() {
      return this.totalCostNew - this.totalCostNewDiscount;
    },
    totalPriceWithDiscount() {
      return (
        this.totalPrice -
        (this.totalPrice * (this.p.program.client_discount ?? 0)) / 100
      );
    },
    totalPriceWithDiscountUSD() {
      return this.totalPriceWithDiscount / this.p.program.rate_dollar;
    },
    totalPriceWithDiscountEU() {
      return this.totalPriceWithDiscount / this.p.program.rate_euro;
    },
    totalCost() {
      return this.p ? this.phases.reduce((a, b) => a + b.price, 0) : 0;
    },
    pestsProductsMapped() {
      return this.p ? this.mapRows(this.p.programPestsProducts) : [];
    },
    stimulatorProductsMapped() {
      return this.p
        ? this.mapRows(this.p.programTypesStimulationProducts, false)
        : [];
    },
    createdAt() {
      return this.p.program.date_create
        ? format(new Date(this.p.program.date_create), 'dd.MM.yyyy HH:MM')
        : '';
    },
    updatedAt() {
      return this.p.program.date_create
        ? format(new Date(this.p.program.date_update), 'dd.MM.yyyy HH:MM')
        : '';
    },
    totalPrice() {
      return [
        ...this.pestsProductsMapped,
        ...this.stimulatorProductsMapped,
      ].reduce((a, b) => a + b.totalPrice, 0);
    },
    totalPriceUSD() {
      return this.p?.program?.rate_dollar
        ? this.totalPrice / this.p.program.rate_dollar
        : null;
    },
    totalPriceEU() {
      return this.p?.program?.rate_euro
        ? this.totalPrice / this.p.program.rate_euro
        : null;
    },
  },
  beforeMount() {
    const css = `@media print {
    @page {
    size: ${this.horizontal ? 'landscape' : 'portrait'};
  }
`;

    const style = document.createElement('style');
    style.textContent = css;
    style.type = 'text/css';
    document.head.appendChild(style);
  },
};
</script>
