function shuffle(str) {
  let a = str.split(''),
    n = a.length;

  for (let i = n - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join('');
}

function generateRandomString(len) {
  return shuffle(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  ).substring(0, len);
}

function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export function removeQueryParams(url) {
  if (!url) return ''; // Если строка пустая, вернуть пустую строку
  const index = url.indexOf('?'); // Найти индекс символа '?'
  return index === -1 ? url : url.slice(0, index); // Вернуть строку до '?' или саму строку, если '?' нет
}
export function parseQueryString(url) {
  const queryIndex = url.indexOf('?'); // Найти символ '?'
  if (queryIndex === -1) return {}; // Если '?' нет, вернуть пустой объект

  const queryString = url.slice(queryIndex + 1); // Получить часть строки после '?'
  const queryObject = {};

  queryString.split('&').forEach((param) => {
    const [key, value] = param.split('='); // Разделить на ключ и значение
    if (key) {
      queryObject[decodeURIComponent(key)] = value
        ? decodeURIComponent(value)
        : null; // Декодировать и добавить в объект
    }
  });

  return queryObject;
}

export default {
  generateRandomString,
  dynamicSort,
  validateEmail,
  removeQueryParams,
};
