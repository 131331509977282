<template>
  <article class="rightzone rightzone--userhomepage">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('AdminArticlesEditor:Title:0'), route: 'Articles' },
          {
            title: isEdit
              ? $t('AdminArticlesEditor:Title:1')
              : $t('AdminArticlesEditor:Title:2'),
          },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AdminArticlesEditor:ButtonUndo')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Articles' })"
        />
        <Button
          :text="$t('AdminArticlesEditor:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isUpdating"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <AppInput
                type="text"
                :label="$t('AdminArticlesEditor:ArticleTitle.Label')"
                :placeholder="
                  $t('AdminArticlesEditor:ArticleTitle.Placeholder')
                "
                color="accent"
                size="max"
                v-model="newArticle.title"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <DropdownSelectValue
                :label="$t('AdminArticlesEditor:ArticleCategory.Label')"
                :placeholder="
                  $t('AdminArticlesEditor:ArticleCategory.Placeholder')
                "
                color="accent"
                size="long"
                v-model="newArticle.category_id"
                :disabled="isLoading || isUpdating"
                :options="[
                  {
                    text: $t('UserHomePage:ArticlesCategories:Defence.Title'),
                    value: 0,
                  },
                  {
                    text: $t(
                      'UserHomePage:ArticlesCategories:Fertilizer.Title'
                    ),
                    value: 1,
                  },
                  {
                    text: $t(
                      'UserHomePage:ArticlesCategories:Irrigation.Title'
                    ),
                    value: 2,
                  },
                  {
                    text: $t('UserHomePage:ArticlesCategories:More.Title'),
                    value: 3,
                  },
                ]"
              />
              <DropdownSelectValue
                :label="$t('AdminArticlesEditor:ArticleType.Label')"
                :placeholder="$t('AdminArticlesEditor:ArticleType.Placeholder')"
                color="accent"
                v-model="newArticle.type_id"
                :disabled="isLoading || isUpdating"
                :options="articlesTypes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="newArticle.type_id === 1"
      class="cwrap marginbottom--standart"
      :key="1"
    >
      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <AppInput
                type="text"
                :label="$t('AdminArticlesEditor:ArticleLink.Placeholder')"
                :placeholder="$t('AdminArticlesEditor:ArticleLink.Label')"
                color=""
                icon="link"
                size="max"
                :state="isLoading || isUpdating ? 'disabled' : ''"
                v-model="newArticle.link"
              />
              <Button
                :text="$t('AdminArticlesEditor:ClearButton')"
                size="s-standart"
                :disabled="isLoading || isUpdating"
                :color="newArticle.link ? 'accentlines' : 'graylines'"
                icon="backspace"
                @click="newArticle.link = ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="newArticle.type_id === 2"
      class="cwrap marginbottom--standart"
      :key="2"
    >
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('AdminArticlesEditor:ArticleEditHeader') }}
          </div>
        </div>
      </div>
      <div
        class="cblock cblock el--rounded-bottom el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal2">
          <div class="cblock__line">
            <div class="cblock__line-block wysiwyg">
              <wysiwyg v-model="newArticle.text" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap">
      <div class="cblock">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('AdminArticlesEditor:ButtonSave')"
              size="auto"
              color="accent"
              icon="save"
              :is-loading="isUpdating"
              :loading-left="true"
              :disabled="isSaveButtonDisabled"
              @click="onSave"
            />
            <Button
              v-if="false"
              :text="$t('AdminArticlesEditor:ButtonOpen')"
              size="auto"
              color="accentlightlines"
              icon="linkto"
              :disabled="!isEdit"
            />
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              :text="$t('AdminArticlesEditor:ButtonClearAll')"
              size="auto"
              color="graylinesalert"
              icon="backspace"
              :disabled="isLoading || isUpdating"
              @click="clearAll"
            />
            <Button
              :text="$t('AdminArticlesEditor:ButtonDelete')"
              size="auto"
              color="alertlines"
              icon="delete"
              :disabled="isLoading || isUpdating || !isEdit"
              @click="onDelete"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { createConfirmCallback } from '@/helpers/confirmModalHelper';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import AppInput from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import CheckBox from '@/components/CheckBox';
import CheckToggle from '@/components/views/CheckToggle';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import { actionTypes } from '@/store/modules/articles';
import routeNames from '@/router/routeNames';

export default {
  name: 'AdminArticlesEditor',
  components: {
    DropdownSelectValue,
    CheckToggle,
    CheckBox,
    RadioToggle,
    AppInput,
    Button,
    Breadcrumps,
  },
  data() {
    return {
      state: {
        nextRouteConfirm: false,
        nextRoute: null,
      },
      newArticle: {
        id: undefined,
        title: '',
        category_id: null,
        type_id: null,
        link: '',
        text: '',
      },
    };
  },
  computed: {
    articlesTypes() {
      return this.$store.state.articles.articlesTypes.map((v) => {
        return { text: v.name, value: v.id };
      });
    },
    isEdit() {
      return this.$route.name === routeNames.editArticle;
    },
    editArticle() {
      return this.$store.state.articles.editArticle;
    },
    isSaveButtonDisabled() {
      if (!this.isEdit) {
        if (
          this.newArticle.title &&
          this.newArticle.category_id != null &&
          this.newArticle.type_id != null
        ) {
          switch (this.newArticle.type_id) {
            case 0: {
              return false;
            }
            case 1: {
              if (this.newArticle.link !== '') return false;

              break;
            }
            case 2: {
              if (this.newArticle.text !== '') return false;
              break;
            }
          }
          return true;
        } else {
          return true;
        }
      } else {
        if (this.hasChanges) {
          if (
            this.newArticle.title &&
            this.newArticle.category_id != null &&
            this.newArticle.type_id != null
          ) {
            switch (this.newArticle.type_id) {
              case 0: {
                return false;
              }
              case 1: {
                if (this.newArticle.link !== '') return false;
                break;
              }
              case 2: {
                if (this.newArticle.text !== '') return false;
                break;
              }
            }
            return true;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    isLoading() {
      return (
        this.$store.state.articles.isArticlesLoading ||
        this.$store.state.articles.isUpdateLoading
      );
    },
    isUpdating() {
      return this.$store.state.articles.isUpdateLoading;
    },
    isDeleting() {
      return this.$store.state.articles.isDeleteLoading;
    },
    isUpdateDone() {
      return this.$store.state.articles.isUpdateDone;
    },
    isDeleteDone() {
      return this.$store.state.articles.isDeleteDone;
    },
    hasChanges() {
      if (this.editArticle) {
        if (
          this.newArticle.title !== this.editArticle.title ||
          this.newArticle.type_id !== this.editArticle.type_id ||
          this.newArticle.category_id !== this.editArticle.category_id ||
          this.newArticle.text !== this.editArticle.text ||
          this.newArticle.link !== this.editArticle.link
        ) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    clearAll() {
      this.newArticle.link = '';
      this.newArticle.type_id = null;
      this.newArticle.category_id = null;
      this.newArticle.title = '';
      this.newArticle.text = '';
    },
    onSave() {
      this.$store.dispatch(actionTypes.updateArticle, this.newArticle);
    },
    onDelete() {
      this.$confirm({
        message:
          this.$t('AdminArticlesEditor:ModalConfirmDelete:Text:0') +
          '<br/>' +
          this.$t('AdminArticlesEditor:ModalConfirmDelete:Text:1'),
        buttons: {
          left: {
            text: this.$t('AnalyzesEdit:ModalConfirmDelete:ButtonDelete'), //'Удалить',
            icon: 'tick',
            color: 'alertlines',
          },
          right: {
            text: this.$t('AnalyzesEdit:ModalConfirmDelete:ButtonUndo'), //'Отмена',
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch(actionTypes.deleteArticle, this.newArticle);
          }
        },
      });
    },
  },
  watch: {
    isUpdateDone() {
      if (this.isUpdateDone && !this.isDeleteDone)
        this.$confirm({
          message:
            this.$t('AdminArticlesEditor:ModalSaved:Text:0') +
            '<br/>' +
            this.$t('AdminArticlesEditor:ModalSaved:Text:1'),
          buttons: {
            left: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonOk'), //'Перейти',
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonUndo'), //'Остаться',
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.state.nextRouteConfirm = true;
              this.$router.push({ name: routeNames.articles });
            } else {
              if (this.$route.name === routeNames.editArticle) {
                this.state.nextRouteConfirm = true;
                this.$router.go();
              } else {
                if (this.$store.state.articles?.updatingResId) {
                  this.state.nextRouteConfirm = true;
                  this.$router.push({
                    name: routeNames.editArticle,
                    params: { id: this.$store.state.articles?.updatingResId },
                  });
                } else {
                  this.state.nextRouteConfirm = true;
                  this.$router.go();
                }
              }
            }
          },
        });
    },
    isDeleteDone() {
      if (this.isDeleteDone) {
        this.state.nextRouteConfirm = true;
        this.$router.push({ name: routeNames.articles });
      }
    },
    editArticle: {
      handler() {
        if (this.isEdit && this.editArticle) {
          this.newArticle.id = this.editArticle?.id;
          this.newArticle.type_id = this.editArticle?.type_id;
          this.newArticle.title = this.editArticle?.title;
          this.newArticle.category_id = this.editArticle?.category_id;
          this.newArticle.link = this.editArticle?.link;
          this.newArticle.text = this.editArticle?.text;
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const showConfirm = () =>
      this.$confirm({
        message:
          this.$t('AnalyzesEdit:ModalNotSaved:Text:0') +
          '<br>' +
          this.$t('AnalyzesEdit:ModalNotSaved:Text:1'),
        callback: createConfirmCallback(next),
      });

    if (this.isDeleteDone || this.state.nextRouteConfirm) {
      next();
    } else if (this.$route.name === routeNames.editArticle) {
      if (!this.isSaveButtonDisabled) {
        if (to?.params?.skipModal) {
          next();
          return;
        }
        showConfirm();
      } else {
        next();
      }
    } else {
      if (
        this.newArticle.title !== '' ||
        this.newArticle.text !== '' ||
        this.newArticle.type_id !== null ||
        this.newArticle.link !== '' ||
        this.newArticle.category_id !== null
      ) {
        //this.nextRoute = to;
        if (to?.params?.skipModal) {
          next();
          return;
        }
        showConfirm();
      } else {
        next();
      }
    }
  },
};
</script>

<style lang="scss">
.wysiwyg {
  .editr--toolbar {
    padding-left: 6px !important;

    div:nth-child(15) {
      display: none;
    }
    .dashboard {
      width: 96.2%;
    }
  }
  .editr--content {
    padding-left: 10px !important;
  }
}
</style>
