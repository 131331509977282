<template>
  <article
    class="rightzone rightzone--fertilizer-newprogram rightzone--userfertilizer--newprogram"
  >
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <!--<Button
          text="Инструкция"
          size="s-standart"
          color="graylines"
          icon="info"
          :is-loading="false"
          :loading-left="false"
          @click="goToGuide"
        />-->
        <Button
          :text="$t('UserFertilizerProgramEdit:TopButtons:Cancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('UserFertilizerProgramEdit:TopButtons:Save')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaving"
          :loading-left="true"
          :disabled="isLoading || !unCanSaveProgram"
          @click="saveProgram"
        />
      </div>
    </div>

    <LoaderBlock v-if="isLoading" />
    <template v-else>
      <FertilizerProgramDescription />

      <div
        v-if="analysisNotFound"
        class="warningblock"
        :class="{ 'warningblock--fatality': fatality }"
      >
        <div class="rightzone_block warningblock-internal">
          <div class="warningblock-colorline"></div>
          <div
            class="warningblock-iconzone marginleft--standart marginright--mini"
          >
            <div v-svg="{ name: 'warningtriangle' }"></div>
          </div>
          <div class="warningblock-text">
            {{ $t('UserFertilizerProgramEdit:AnalyseNotFoundText1') }}<br />
            {{ $t('UserFertilizerProgramEdit:AnalyseNotFoundText2') }}
          </div>
        </div>
        <div class="warningblock-buttonzone marginleft--standart">
          <Button
            :text="$t('UserFertilizerProgramEdit:AnalyseGet')"
            size="standart"
            color="accentlines"
            icon="buy"
            @click="goToAnalyses"
          />
        </div>
      </div>

      <FertilizerProgramAnalysis />

      <FertilizerProgramPeriodSelection
        ref="period"
        :period="period"
        @change="periodChange"
        :disabled="isLoading || isSaving" />

      <FertilizerProgramPhases />

      <FertilizerProgramTables />

      <div class="cwrap">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('UserFertilizerProgramEdit:BottomButtons:Save')"
              size="standart"
              color="accent"
              icon="save"
              :is-loading="isSaving"
              :loading-left="true"
              :disabled="isLoading || !unCanSaveProgram"
              @click="saveProgram"
            />
            <Button
              text="print"
              v-tooltip="{ text: $t('PrintProgramDefense:ButtonPrint') }"
              size="s-standart"
              color="accentlines"
              icon="print"
              @click="printProgram"
              :disabled="unCanSaveProgram"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditing && !(isLoading || isSaving)"
        class="cwrap marginbottom--standart margintop--standart"
      >
        <div class="chead el--borders-top el--borders-sides el--rounded-top">
          <div class="chead__line-block">
            <div
              class="vsvg vsvg--standart chead_icon"
              v-svg="{ name: 'delete' }"
            ></div>
            <div class="chead_text">
              {{ $t('UserFertilizerProgramEdit:DeleteProgram:Header') }}
            </div>
          </div>
        </div>

        <div class="cblock el--borders-all el--rounded-bottom">
          <div class="cblock__infoblock">
            <p>
              {{ $t('UserFertilizerProgramEdit:DeleteProgram:Text1') }}
              {{ $t('UserFertilizerProgramEdit:DeleteProgram:Text2') }}
            </p>

            <Button
              :text="$t('UserFertilizerProgramEdit:DeleteProgram:DeleteButton')"
              size="standart"
              color="alertlines"
              icon="userdelete"
              @click="deleteProgram"
            />
          </div>
        </div></div
    ></template>

    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
      :clientArea="true"
      :forNutrition="true"
    />
  </article>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import {
  mutationTypes,
  actionTypes,
} from '@/store/modules/clientsArea/nutritionPrograms';
import routeNames from '@/router/routeNames';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import FertilizerProgramDescription from './userFertilizersComponents/FertilizerProgramDescription';
import FertilizerProgramAnalysis from './userFertilizersComponents/FertilizerProgramAnalysis';
import FertilizerProgramPeriodSelection from './userFertilizersComponents/FertilizerProgramPeriodSelection';
import FertilizerProgramPhases from './userFertilizersComponents/FertilizerProgramPhases';
import FertilizerProgramTables from './userFertilizersComponents/FertilizerProgramTables';
import PrintModal from '@/components/modals/ModalPrint';
import isEqual from 'lodash.isequal';
import LoaderBlock from '@/components/blocks/LoaderBlock';

export default {
  name: 'UserFertilizerProgramEdit',
  components: {
    Button,
    Breadcrumps,
    FertilizerProgramDescription,
    FertilizerProgramAnalysis,
    FertilizerProgramPeriodSelection,
    FertilizerProgramPhases,
    FertilizerProgramTables,
    PrintModal,
    LoaderBlock,
  },
  data() {
    return { nextRouteConfirm: false, selectedPrintId: null };
  },
  computed: {
    ...mapGetters(['unCanSaveProgram', 'unProgramEdited']),
    ...mapState({
      isLoading: (state) => state.userNutrition.isLoading,
      isSaving: (state) => state.userNutrition.isSaving,
      program: (state) => state.userNutrition.program,
      cultureId: (state) => state.userNutrition.program.culture_id,
      hasPhases: (state) => state.userNutrition.programPhases.length > 0,
      products: (state) => state.userNutrition.programFertilizerProducts,
      analysisNotFound: (state) => state.userNutrition.analysisForZoneNotFound,
      error: (state) => state.userNutrition.error,
    }),
    isEditing() {
      return this.$route.name === routeNames.userFertilizerProgramEdit;
    },
    routeBack() {
      return routeNames.userFertilizers;
    },
    breadcrumpsArr() {
      return [
        {
          title: this.$t('UserFertilizerProgramEdit:Header1'),
          route: this.routeBack,
        },
        {
          title: this.isEditing
            ? this.$t('UserFertilizerProgramEdit:Header2')
            : this.$t('UserFertilizerProgramEdit:Header3'),
        },
      ];
    },
    period: {
      get() {
        if (!this.program.period_start || !this.program.period_end) {
          return {
            selectedYearStart: null,
            selectedYearEnd: null,
            selectedMonthStart: null,
            selectedMonthEnd: null,
            selectedDayStart: null,
            selectedDayEnd: null,
          };
        }

        const start = new Date(this.program.period_start);
        const end = new Date(this.program.period_end);
        return {
          selectedYearStart: start.getUTCFullYear(),
          selectedYearEnd: end.getUTCFullYear(),
          selectedMonthStart: start.getUTCMonth(),
          selectedMonthEnd: end.getUTCMonth(),
          selectedDayStart: start.getUTCDate(),
          selectedDayEnd: end.getUTCDate(),
        };
      },
      set(value) {
        this.$store.commit(mutationTypes.setPeriod, value);
      },
    },
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    cultureId() {
      if (this.cultureId !== null) {
        this.$store.dispatch(actionTypes.fetchCultureData, this.cultureId);
      } else {
        this.$store.commit(mutationTypes.cultureDataFetchSuccess, []);
      }
    },
  },
  methods: {
    periodChange(period) {
      const samePeriod = isEqual(period, this.period);
      if (samePeriod) return;
      const hasProducts =
        this.products.filter((p) => !p.is_auto && p.count_per_ha !== null)
          .length > 0;
      if (this.period && hasProducts) {
        this.$confirm({
          message:
            this.$t('UserFertilizerProgramEdit:PeriodChange:Message1') +
            '</br>' +
            this.$t('UserFertilizerProgramEdit:PeriodChange:Message2'),
          buttons: {
            left: {
              text: this.$t('UserFertilizerProgramEdit:PeriodChange:Confirm'),
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('UserFertilizerProgramEdit:PeriodChange:Cancel'),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.period = period;
              this.$store.commit(mutationTypes.createPhases);
              this.$store.commit(mutationTypes.fillAutoProducts);
              this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
            } else {
              this.$refs.period.update();
            }
          },
        });
      } else {
        this.period = period;
        this.$store.commit(mutationTypes.createPhases);
        this.$store.commit(mutationTypes.fillAutoProducts);
        this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
      }
    },
    saveProgram() {
      this.$store
        .dispatch(actionTypes.saveProgram)
        .then(this.afterSaveNavifation);
    },
    deleteProgram() {
      this.$confirm({
        message: this.$t(
          'UserFertilizerProgramEdit:DeleteConfirmModal:Message'
        ),
        buttons: {
          left: {
            text: this.$t(
              'UserFertilizerProgramEdit:DeleteConfirmModal:ButtonDelete'
            ),
            icon: 'delete',
          },
          right: {
            text: this.$t(
              'UserFertilizerProgramEdit:DeleteConfirmModal:ButtonCancel'
            ),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store
              .dispatch(actionTypes.deleteProgram)
              .then(() => this.$router.push({ name: this.routeBack }));
          }
        },
      });
    },
    printProgram() {
      this.selectedPrintId = this.program.id;
    },
    afterSaveNavifation() {
      this.$confirm({
        message:
          this.$t('UserFertilizerProgramEdit:RouteConfirmModal:Message1') +
          '<br/>' +
          this.$t('UserFertilizerProgramEdit:RouteConfirmModal:Message2'),
        buttons: {
          left: {
            text: this.$t(
              'UserFertilizerProgramEdit:RouteConfirmModal:ButtonGo'
            ),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t(
              'UserFertilizerProgramEdit:RouteConfirmModal:ButtonCancel'
            ),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$router.push({ name: routeNames.userFertilizers });
          } else {
            if (this.isEditing) {
              this.$store.commit(mutationTypes.resetState);
              this.$store.dispatch(
                actionTypes.fetchProgram,
                this.$store.state.userNutrition.savedRedirectId
              );
            } else {
              this.$router.push({
                name: routeNames.userFertilizerProgramEdit,
                params: { id: this.$store.state.userNutrition.savedRedirectId },
              });
            }
          }
        },
      });
    },
    goToGuide() {
      const { href } = this.$router.resolve({
        name: routeNames.userGuideFertilizer,
      });
      window.open(href, '_blank');
    },
    goToAnalyses() {
      const { href } = this.$router.resolve({
        name: routeNames.userAnalyzes,
      });
      window.open(href, '_blank');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.unProgramEdited) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('UserFertilizerProgramEdit:RouteLeaveModal:Message1') +
          '<br />' +
          this.$t('UserFertilizerProgramEdit:RouteLeaveModal:Message2'),
        buttons: {
          left: {
            text: this.$t(
              'UserFertilizerProgramEdit:RouteLeaveModal:LeftButton'
            ),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t(
              'UserFertilizerProgramEdit:RouteLeaveModal:RightButton'
            ),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
};
</script>
