<!--Translated-->
<template>
  <article class="rightzone rightzone--defence-newprogram">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('DefenseProgramEdit:ButtonsCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('DefenseProgramEdit:ButtonsSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaving"
          :loading-left="true"
          :disabled="isLoading || !programsCanSave || !canEdit"
          @click="saveProgram"
        />
      </div>
    </div>
    <Warningblock v-if="usedInactiveProducts.length" :show-button="false">
      {{ $t('DefenseProgramEdit:Warning:NotActiveProducts') }}
    </Warningblock>

    <Warningblock
      v-if="programHasPestsOrSTypesWithoutProducts"
      :show-button="false"
    >
      {{ $t('DefenseProgramEdit:Warning:NoProductsPests') }}
    </Warningblock>

    <ProgramDescription :readonly="!canEdit" />

    <TemplatesList
      v-if="!isEditing && !selectedTemplate"
      :templates="templates"
    />

    <SelectedTemplateBlock
      v-if="isEditing ? program.template_name : selectedTemplate"
      :text="isEditing ? program.template_name : selectedTemplate.name"
      :canCancel="!isEditing"
      @click="cancelTemplateSelection"
    />

    <LoaderBlock v-if="templateFetching" />

    <DefenseProgram v-if="programHasPhases" :readonly="!canEdit" />

    <div class="cwrap" v-if="programHasPhases">
      <div class="cblock__line">
        <div class="cblock__line-block">
          <Button
            :text="$t('DefenseProgramEdit:ButtonsSave')"
            size="standart"
            color="accent"
            icon="save"
            :is-loading="isSaving"
            :loading-left="true"
            :disabled="isLoading || !programsCanSave || !canEdit"
            @click="saveProgram"
          />
          <Button
            text="print"
            v-tooltip="{ text: $t('PrintProgramDefense:ButtonPrint') }"
            size="s-standart"
            color="accentlines"
            icon="print"
            @click="printProgram"
            :disabled="!programHasPhases || programsCanSave || !programHasPrice"
          />
          <Button
            text="copy"
            v-tooltip="{ text: $t('DefenseProgramEdit:ModalClone:ButtonOk') }"
            size="s-standart"
            color="accentlines"
            icon="copy"
            state="disabled"
            :disabled="isSaving || !canEdit"
            @click="cloneProgram"
          />
        </div>
      </div>
    </div>

    <div
      v-if="canEdit && isEditing && !(isLoading || isSaving)"
      class="cwrap marginbottom--standart margintop--standart"
    >
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">
            {{ $t('DefenseProgramEdit:Delete:Header') }}
          </div>
        </div>
      </div>

      <div v-if="isEditing" class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('DefenseProgramEdit:Delete:Message1') }}<br />
            {{ $t('DefenseProgramEdit:Delete:Message2') }}
          </p>

          <Button
            :text="$t('DefenseProgramEdit:Delete:ButtonDelete')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            @click="deleteProgram"
          />
        </div>
      </div>
    </div>
    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
    />
    <ModalCloneWithProductsConflict
      v-if="modalCloneWithProductsConflict"
      @close="modalCloneWithProductsConflict = false"
      :message="message"
      :products="usedInactiveProducts"
      @clone="cloneProgramCallback"
    />
  </article>
</template>

<script>
import { createConfirmCallback } from '@/helpers/confirmModalHelper';
import { mapState, mapGetters } from 'vuex';
import { mutationTypes, actionTypes } from '@/store/modules/programs';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import ProgramDescription from './components/ProgramDescription';
import TemplatesList from './components/TemplatesList';
import DefenseProgram from './components/DefenseProgram';
import SelectedTemplateBlock from './components/SelectedTemplateBlock';
import LoaderBlock from '@/components/blocks/LoaderBlock';
import PrintModal from '@/components/modals/ModalPrint';
import ModalCloneWithProductsConflict from '@/components/modals/ModalCloneWithProductsConflict';
import Warningblock from '@/components/views/Warningblock.vue';

export default {
  name: 'DefenseProgramEdit',
  components: {
    ProgramDescription,
    Button,
    Breadcrumps,
    TemplatesList,
    DefenseProgram,
    SelectedTemplateBlock,
    LoaderBlock,
    PrintModal,
    ModalCloneWithProductsConflict,
    Warningblock,
  },
  data() {
    return {
      nextRouteConfirm: false,
      selectedPrintId: null,
      modalCloneWithProductsConflict: false,
      message: '',
    };
  },
  computed: {
    ...mapGetters([
      'programsCanSave',
      'programSTypesProductsAsMap',
      'programPestsProductsAsMap',
      'plantsCareProductsAsMap',
      'programHasPestsOrSTypesWithoutProducts',
      'programPricesMap',
    ]),
    ...mapState({
      templates: (state) => state.programs.templates,
      selectedTemplate: (state) => state.programs.selectedTemplate,
      program: (state) => state.programs.program,
      phaseIds: (state) => state.programs.programPhases.map(({ id }) => id),
      programHasPhases: (state) => Boolean(state.programs.programPhases.length),
      isLoading: (state) => state.programs.isLoading,
      templateFetching: (state) => state.programs.templateFetching,
      isSaving: (state) => state.programs.isSaving,
      error: (state) => state.programs.error,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
    }),
    programHasPrice() {
      return Object.values(this.programPricesMap).some((price) => price > 0);
    },
    isEditing() {
      return this.$route.name === routeNames.editDefenseProgram;
    },
    routeBack() {
      return routeNames.defensePrograms;
    },
    usedInactiveProducts() {
      const products = [
        ...Object.values(this.programPestsProductsAsMap),
        ...Object.values(this.programSTypesProductsAsMap),
      ]
        .filter(
          (product) =>
            product.is_selected === 1 &&
            this.phaseIds.includes(product.program_phase_id)
        )
        .map((product) => this.plantsCareProductsAsMap[product.product_id])
        .filter((product) => product.is_active === 0);
      return [...new Set(products)];
    },
    breadcrumpsArr() {
      return [
        { title: this.$t('DefenseProgramEdit:Title:0'), route: this.routeBack },
        {
          title: this.isEditing
            ? this.$t('DefenseProgramEdit:Title:1')
            : this.$t('DefenseProgramEdit:Title:2'),
        },
      ];
    },
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    selectedTemplate(template) {
      if (template) {
        this.$store.dispatch(actionTypes.fetchTemplate, template.id);
      }
    },
  },
  methods: {
    printProgram() {
      this.selectedPrintId = this.program.id;
    },
    cancelTemplateSelection() {
      this.$confirm({
        message: this.$t('DefenseProgramEdit:ModalNotSaved:Message'), //'Несохраненные данные будут потеряны. Вы уверены?',
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalNotSaved:ButtonOk'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalNotSaved:ButtonUndo'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(mutationTypes.selectTemplate, null);
          }
        },
      });
    },
    saveProgram() {
      this.$store
        .dispatch(actionTypes.saveProgram)
        .then(this.afterSaveNavifation);
    },
    afterSaveNavifation() {
      this.$confirm({
        message:
          this.$t('DefenseProgramEdit:ModalSaved:Message1') +
          '<br/>' +
          this.$t('DefenseProgramEdit:ModalSaved:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalSaved:ButtonOk'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalSaved:ButtonCancel'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          this.nextRouteConfirm = true;
          if (confirm) {
            this.$router.push({ name: routeNames.defensePrograms });
          } else {
            this.$router.push({
              name: routeNames.editDefenseProgram,
              params: { id: this.$store.state.programs.savedRedirectId },
            });
          }
        },
      });
    },
    deleteProgram() {
      this.$confirm({
        message: this.$t('DefenseProgramEdit:ModalDelete:Message'),
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalDelete:ButtonOk'),
            icon: 'delete',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalDelete:ButtonCancel'),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store
              .dispatch(actionTypes.deleteProgram)
              .then(() => this.$router.push({ name: this.routeBack }));
          }
        },
      });
    },
    cloneProgram() {
      let message = this.$t('DefenseProgramEdit:ModalClone:Message1');
      if (this.programsCanSave)
        message += '<br>' + this.$t('DefenseProgramEdit:ModalClone:Message2');

      if (this.usedInactiveProducts.length) {
        this.message = message;
        this.modalCloneWithProductsConflict = true;
      } else {
        this.$confirm({
          message,
          buttons: {
            left: {
              text: this.$t('DefenseProgramEdit:ModalClone:ButtonOk'),
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('DefenseProgramEdit:ModalClone:ButtonCancel'),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.cloneProgramCallback([]);
            }
          },
        });
      }
    },
    cloneProgramCallback(selectedProducts = []) {
      this.modalCloneWithProductsConflict = false;
      this.nextRouteConfirm = true;
      this.$store.commit(mutationTypes.deleteId);
      this.$store.commit(
        mutationTypes.clearInnactiveProducts,
        selectedProducts
      );
      this.$store.dispatch(actionTypes.saveProgram).then(() => {
        this.$awn.info(this.$t('DefenseProgramEdit:MessageCloned'));
        this.$router.push({
          name: routeNames.editDefenseProgram,
          params: { id: this.$store.state.programs.savedRedirectId },
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to?.params?.skipModal ||
      this.nextRouteConfirm ||
      !this.programsCanSave ||
      !this.canEdit
    ) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('DefenseProgramEdit:ModalRoute:Message1') +
          '<br />' +
          this.$t('DefenseProgramEdit:ModalRoute:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonOk'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonCancel'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
};
</script>
