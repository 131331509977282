<!--Translated-->
<template>
  <div
    class="tablepreview"
    ref="tooltip"
    :style="`opacity: ${visible ? 1 : 0};`"
  >
    <div
      v-if="visible"
      class="tablepreview_textblock tablepreview_textblock--list"
    >
      <h4 @click="goToProductPage" style="cursor: pointer">
        {{ product.name }}
        <span v-if="!product.is_active" style="color: red">{{
          $t('HoverTooltipProduct:ProductIsNotActive')
        }}</span>
      </h4>
      <p>
        <span>{{ $t('HoverTooltipFertilizer:Vendor') }} - </span>
        <span>{{ manufacturerName }}</span>
      </p>
      <p>
        <span>{{ $t('HoverTooltipFertilizer:Country') }} - </span>
        <span> {{ countryName.trim() }} </span>
      </p>
      <p v-if="!noSubstance">
        <span>{{ $t('HoverTooltipFertilizer:ActiveSubstance') }} - </span>
        <span>{{ product.active_substance || '...' }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import routeNames from '@/router/routeNames';
import { getFullArray } from '@/helpers/getFullArray';

export default {
  name: 'HoverTooltipFertilizer',
  props: {
    product: { type: Object, required: true },
    noSubstance: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapState({
      manufacturers(state) {
        return getFullArray([
          state.nutrition.manufacturers,
          state.plantsCare.manufacturers,
          state.programs.manufacturers,
          state.products.manufacturers,
        ]);
      },
      manufacturersCountries(state) {
        return getFullArray([
          state.nutrition.manufacturersCountries,
          state.plantsCare.manufacturersCountries,
          state.programs.manufacturersCountries,
          state.products.manufacturersCountries,
        ]);
      },
    }),
    manufacturer() {
      return this.manufacturers.find(
        ({ id }) => id === this.product.manufacturer_id
      );
    },
    countryName() {
      return (
        this.manufacturersCountries.find(
          ({ id }) => id === this.product.manufacturer_country_id
        )?.name ?? '...'
      );
    },
    manufacturerName() {
      return this.manufacturer?.name ?? '...';
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.visible = entries[0].isIntersecting;
    });
    this.observer.observe(this.$refs.tooltip);
  },
  beforeDestroy() {
    this.observer?.unobserve(this.$refs.tooltip);
  },
  methods: {
    goToProductPage() {
      if (this.noSubstance) return;
      const routeData = this.$router.resolve({
        name: routeNames.editProduct,
        params: { id: this.product.id },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
