function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Функция для сохранения данных в sessionStorage
export function saveToSession(key, data) {
  if (typeof key !== 'string') {
    throw new Error('Ключ должен быть строкой.');
  }
  sessionStorage.setItem(key, JSON.stringify(data));
}

// Функция для восстановления данных из sessionStorage
export function restoreFromSession(key) {
  if (typeof key !== 'string') {
    throw new Error('Ключ должен быть строкой.');
  }
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

export default {
  getScrollbarWidth,
  isInViewport,
  saveToSession,
  restoreFromSession,
};
