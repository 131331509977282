var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"rightzone rightzone--article"},[_c('div',{staticClass:"rightzone_top"},[_c('Breadcrumps',{attrs:{"data":_vm.breadcrumpsArr}})],1),_c('div',{staticClass:"cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"},[_vm._m(0),_c('div',{staticClass:"artclblock"},[_c('div',{staticClass:"margintop--standart"},[_c('GuideAnalysisFiles')],1)])]),_c('div',{staticClass:"cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"},[_vm._m(1),_c('div',{staticClass:"artclblock"},[_c('div',{staticClass:"margintop--standart"},[_c('GuideAnalysisFiles')],1)])]),_c('div',{staticClass:"cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"},[_vm._m(2),_c('div',{staticClass:"artclblock"},[_c('div',{staticClass:"margintop--standart"},[_c('GuideAnalysisFiles')],1)])]),_c('div',{staticClass:"cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"},[_vm._m(3),_c('div',{staticClass:"artclblock"},[_c('div',{staticClass:"margintop--standart"},[_c('GuideAnalysisFiles')],1)])]),_c('div',{staticClass:"cwrap artclwrap artclwrap--buttons el--borders-all el--rounded-all margintop--standart"},[_vm._m(4),_c('div',{staticClass:"artclblock"},[_vm._m(5),_c('div',{staticClass:"artclblock-buttons"},[_c('Button',{attrs:{"text":"Cоздать программу удобрений","size":"standart","color":"accentlines","icon":"linkto"},on:{"click":function($event){return _vm.$router.push({ name: 'UserFertilizers' })}}})],1)])]),_c('UserFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclhead"},[_c('div',{staticClass:"artclhead-text"},[_vm._v(" როგორ შევარჩიოთ სასუქი? - Как выбрать удобрение ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclhead"},[_c('div',{staticClass:"artclhead-text"},[_vm._v(" ძირითადი საკვები ელემენტები - Основные элементы питания ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclhead"},[_c('div',{staticClass:"artclhead-text"},[_vm._v(" რა უნდა გაკეთდეს მაღალი pH-ისა და EC-ს დროს? - Что должно быть сделано При высоких значениях pH и ЕС? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclhead"},[_c('div',{staticClass:"artclhead-text"},[_vm._v("სად შევიძინო?! - где купить?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclhead"},[_c('div',{staticClass:"artclhead-text"},[_vm._v(" Создайте, или закажите программу удобрений ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artclblock-text"},[_c('p',[_vm._v(" Вы можете заказать программу удобрений у нашего менеджера, или создать ее самостоятельно с помощью интерфейса создания программ удобрений ")])])
}]

export { render, staticRenderFns }