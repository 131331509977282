<template>
  <article
    class="chkset chkset--badobject"
    :class="{ 'inptwrap--locked': disabled }"
  >
    <label class="lable"> {{ label }}</label>
    <div class="blocklinewrap sets-wrap">
      <div class="sets-column--full sets-column--scroll">
        <CheckBox
          v-for="option in optionsSorted"
          :key="option.text"
          :text="option.text"
          color-checked="accentfill"
          color-un-checked="gray"
          @input="onInput(option, $event)"
          :value="option.selected"
        />
        <div v-if="options.length === 0" class="chkset-textnodata">
          {{ $t('ChksetBadObjects:NoBadObjects.Text') }}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import CheckBox from '@/components/CheckBox';

export default {
  name: 'ChksetBadObjects',
  props: {
    label: { type: String },
    options: { type: Array, required: true },
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  components: { CheckBox },
  computed: {
    optionsSorted() {
      const selected = this.options
        .filter(({ value }) => this.value.includes(value))
        .map((o) => ({ ...o, selected: true }));
      const unSelected = this.options
        .filter(({ value }) => !this.value.includes(value))
        .map((o) => ({ ...o, selected: false }));
      return [...selected, ...unSelected];
    },
  },
  methods: {
    onInput(option, selected) {
      this.$emit(
        'input',
        selected
          ? [...this.value, option.value]
          : [...this.value].filter((id) => id !== option.value)
      );
    },
  },
};
</script>
