import Vue from 'vue';

// 1. Положение
// * Над  - "tooltip--top"
// * Под -  "tooltip--bottom"
// * Слева - "tooltip--left"
// * Справа -  "tooltip--right"
// 2. Направление
// * Влево - "tooltip--toleft"
// * Вправо - "tooltip--toright"
// 3. Размер
// * По своей ширине, БЕЗ ПЕРЕНОСА - "tooltip--oneline"
// * По ширине родителя - "tooltip--inherit"
// * Фикс размер - Маленький, иначе перенос текста - "tooltip--small"
// * Фикс размер - Средний, иначе перенос текста - "tooltip--medium"
// * Фикс размер - Широкий, иначе перенос текста - "tooltip--long"
// 4. Анимация
// * Дефолт - нету
// * быстрая - "tooltip--anim0"
// * с задержкой 1с - "tooltip--anim1"
// * с задержкой 2с - "tooltip--anim0"

Vue.directive('tooltip', {
  bind: function (el, binding) {
    el.classList.add('tooltip-wrapper');

    const div1 = el.appendChild(document.createElement('div'));
    div1.classList.add('tooltip');
    if (binding.value.classList) {
      for (const className of binding.value.classList) {
        div1.classList.add(className);
      }
    } else {
      div1.classList.add('tooltip--top');
      div1.classList.add('tooltip--toright');
      div1.classList.add('tooltip--oneline');
      div1.classList.add('tooltip--anim1');
    }

    const div2 = div1.appendChild(document.createElement('div'));
    div2.textContent = binding.value.text;
  },
});
