<!--Translated-->
<template>
  <article class="rightzone rightzone--fertilizer-newprogram">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('FertilizerProgramEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('FertilizerProgramEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaving"
          :loading-left="true"
          :disabled="isLoading || !nutritionCanSaveProgram || !canEdit"
          @click="saveProgram"
        />
      </div>
    </div>

    <Warningblock v-if="usedInactiveProducts.length" :show-button="false">
      {{ $t('DefenseProgramEdit:Warning:NotActiveProducts') }}
    </Warningblock>

    <FertilizerProgramDescription />

    <FertilizerProgramAnalysis />

    <FertilizerProgramPeriodSelection
      ref="period"
      :period="period"
      @change="periodChange"
      :disabled="isLoading || isSaving"
      :locked="!canEdit"
    />

    <FertilizerProgramPhases />

    <FertilizerProgramTables />

    <div class="cwrap">
      <div class="cblock__line">
        <div class="cblock__line-block">
          <Button
            :text="$t('FertilizerProgramEdit:ButtonSave')"
            size="standart"
            color="accent"
            icon="save"
            :is-loading="isSaving"
            :loading-left="true"
            :disabled="isLoading || !nutritionCanSaveProgram || !canEdit"
            @click="saveProgram"
          />

          <Button
            text="print"
            v-tooltip="{ text: $t('PrintProgramDefense:ButtonPrint') }"
            size="s-standart"
            color="accentlines"
            icon="print"
            @click="printProgram"
            :disabled="nutritionCanSaveProgram"
          />

          <Button
            text="copy"
            v-tooltip="{ text: $t('DefenseProgramEdit:ModalClone:ButtonOk') }"
            size="s-standart"
            color="accentlines"
            icon="copy"
            state="disabled"
            :disabled="isSaving || !hasPhases || !canEdit"
            @click="cloneProgram"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isEditing && !(isLoading || isSaving) && canEdit"
      class="cwrap marginbottom--standart margintop--standart"
    >
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">
            {{ $t('FertilizerProgramEdit:DeleteBlock:Header') }}
          </div>
        </div>
      </div>

      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('FertilizerProgramEdit:DeleteBlock:Message1') }}<br />
            {{ $t('FertilizerProgramEdit:DeleteBlock:Message2') }}
          </p>

          <Button
            :text="$t('FertilizerProgramEdit:DeleteBlock:ButtonDelete')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            @click="deleteProgram"
          />
        </div>
      </div>
    </div>

    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
      :forNutrition="true"
    />

    <ModalCloneWithProductsConflict
      v-if="modalCloneWithProductsConflict"
      @close="modalCloneWithProductsConflict = false"
      :message="message"
      :products="usedInactiveProducts"
      @clone="cloneProgramCallback"
    />
  </article>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { mutationTypes, actionTypes } from '@/store/modules/nutritionPrograms';
import routeNames from '@/router/routeNames';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import FertilizerProgramDescription from './fertilizers/FertilizerProgramDescription';
import FertilizerProgramAnalysis from './fertilizers/FertilizerProgramAnalysis';
import FertilizerProgramPeriodSelection from './fertilizers/FertilizerProgramPeriodSelection';
import FertilizerProgramPhases from './fertilizers/FertilizerProgramPhases';
import FertilizerProgramTables from './fertilizers/FertilizerProgramTables';
import PrintModal from '@/components/modals/ModalPrint';
import Warningblock from '@/components/views/Warningblock.vue';
import ModalCloneWithProductsConflict from '@/components/modals/ModalCloneWithProductsConflict.vue';

import isEqual from 'lodash.isequal';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

export default {
  name: 'FertilizerProgramEdit',
  components: {
    Button,
    Breadcrumps,
    FertilizerProgramDescription,
    FertilizerProgramAnalysis,
    FertilizerProgramPeriodSelection,
    FertilizerProgramPhases,
    FertilizerProgramTables,
    PrintModal,
    Warningblock,
    ModalCloneWithProductsConflict,
  },
  data() {
    return {
      nextRouteConfirm: false,
      selectedPrintId: null,
      modalCloneWithProductsConflict: false,
      message: '',
    };
  },
  computed: {
    ...mapGetters([
      'nutritionCanSaveProgram',
      'nutritionProgramEdited',
      'nutritionTotalProductList',
    ]),
    ...mapState({
      isLoading: (state) => state.nutrition.isLoading,
      isSaving: (state) => state.nutrition.isSaving,
      program: (state) => state.nutrition.program,
      cultureId: (state) => state.nutrition.program.culture_id,
      hasPhases: (state) => state.nutrition.programPhases.length > 0,
      products: (state) => state.nutrition.programFertilizerProducts,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_add === 1,
      error: (state) => state.nutrition.error,
    }),
    isEditing() {
      return this.$route.name === routeNames.editFertilizerProgram;
    },
    routeBack() {
      return routeNames.fertilizerPrograms;
    },
    breadcrumpsArr() {
      return [
        {
          title: this.$t('FertilizerProgramEdit:Title:0'),
          route: this.routeBack,
        },
        {
          title: this.isEditing
            ? this.$t('FertilizerProgramEdit:Title:1')
            : this.$t('FertilizerProgramEdit:Title:2'),
        },
      ];
    },
    period: {
      get() {
        if (!this.program.period_start || !this.program.period_end) {
          return {
            selectedYearStart: null,
            selectedYearEnd: null,
            selectedMonthStart: null,
            selectedMonthEnd: null,
            selectedDayStart: null,
            selectedDayEnd: null,
          };
        }

        const start = new Date(this.program.period_start);
        const end = new Date(this.program.period_end);
        return {
          selectedYearStart: start.getUTCFullYear(),
          selectedYearEnd: end.getUTCFullYear(),
          selectedMonthStart: start.getUTCMonth(),
          selectedMonthEnd: end.getUTCMonth(),
          selectedDayStart: start.getUTCDate(),
          selectedDayEnd: end.getUTCDate(),
        };
      },
      set(value) {
        this.$store.commit(mutationTypes.setPeriod, value);
      },
    },
    usedInactiveProducts() {
      return this.nutritionTotalProductList
        .map((p) => p.productLink)
        .filter((p) => !p.is_active);
    },
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    cultureId() {
      if (this.cultureId !== null) {
        this.$store.dispatch(actionTypes.fetchCultureData, this.cultureId);
      } else {
        this.$store.commit(mutationTypes.cultureDataFetchSuccess, []);
      }
    },
  },
  methods: {
    periodChange(period) {
      const samePeriod = isEqual(period, this.period);
      if (samePeriod) return;
      const hasProducts =
        this.products.filter((p) => !p.is_auto && p.count_per_ha !== null)
          .length > 0;
      if (this.period && hasProducts) {
        this.$confirm({
          message:
            this.$t('FertilizerProgramEdit:ModalPeriod:Message1') +
            '</br>' +
            this.$t('FertilizerProgramEdit:ModalPeriod:Message2'),
          buttons: {
            left: {
              text: this.$t('FertilizerProgramEdit:ModalPeriod:ButtonOk'),
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('FertilizerProgramEdit:ModalPeriod:ButtonCancel'),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.period = period;
              this.$store.commit(mutationTypes.createPhases);
              this.$store.commit(mutationTypes.fillAutoProducts);
              this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
            } else {
              this.$refs.period.update();
            }
          },
        });
      } else {
        this.period = period;
        this.$store.commit(mutationTypes.createPhases);
        this.$store.commit(mutationTypes.fillAutoProducts);
        this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
      }
    },
    saveProgram() {
      this.$store
        .dispatch(actionTypes.saveProgram)
        .then(this.afterSaveNavifation);
    },
    deleteProgram() {
      this.$confirm({
        message: this.$t('FertilizerProgramEdit:ModalDelete:Message'),
        buttons: {
          left: {
            text: this.$t('FertilizerProgramEdit:ModalDelete:ButtonOk'),
            icon: 'delete',
          },
          right: {
            text: this.$t('FertilizerProgramEdit:ModalDelete:ButtonCancel'),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store
              .dispatch(actionTypes.deleteProgram)
              .then(() => this.$router.push({ name: this.routeBack }));
          }
        },
      });
    },
    printProgram() {
      this.selectedPrintId = this.program.id;
    },
    cloneProgram() {
      let message = this.$t('FertilizerProgramEdit:ModalClone:Message1');
      if (this.nutritionCanSaveProgram)
        message +=
          '<br>' + this.$t('FertilizerProgramEdit:ModalClone:Message2');

      if (this.usedInactiveProducts.length) {
        this.message = message;
        this.modalCloneWithProductsConflict = true;
      } else {
        this.$confirm({
          message,
          buttons: {
            left: {
              text: this.$t('FertilizerProgramEdit:ModalClone:ButtonOk'),
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('FertilizerProgramEdit:ModalClone:ButtonCancel'),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.cloneProgramCallback();
            }
          },
        });
      }
    },
    cloneProgramCallback(selectedProducts = []) {
      this.modalCloneWithProductsConflict = false;
      this.nextRouteConfirm = true;
      this.$store.commit(mutationTypes.deleteId);
      this.$store.commit(
        mutationTypes.clearInnactiveProducts,
        selectedProducts
      );
      this.$store.dispatch(actionTypes.saveProgram).then(() => {
        this.$awn.info(this.$t('FertilizerProgramEdit:CloneInfoMessage'));
        this.$router.push({
          name: routeNames.editFertilizerProgram,
          params: { id: this.$store.state.nutrition.savedRedirectId },
        });
      });
    },
    afterSaveNavifation() {
      this.$confirm({
        message:
          this.$t('FertilizerProgramEdit:ModalSaved:Message1') +
          '<br/>' +
          this.$t('FertilizerProgramEdit:ModalSaved:Message2'),
        buttons: {
          left: {
            text: this.$t('FertilizerProgramEdit:ModalSaved:ButtonOk'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('FertilizerProgramEdit:ModalSaved:ButtonCancel'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          this.nextRouteConfirm = true;
          if (confirm) {
            this.$router.push({ name: routeNames.fertilizerPrograms });
          } else {
            this.$router.push({
              name: routeNames.editFertilizerProgram,
              params: { id: this.$store.state.nutrition.savedRedirectId },
            });
          }
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to?.params?.skipModal ||
      this.nextRouteConfirm ||
      !this.nutritionProgramEdited ||
      !this.canEdit
    ) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('FertilizerProgramEdit:ModalRoute:Message1') +
          '<br />' +
          this.$t('FertilizerProgramEdit:ModalRoute:Message2'),
        buttons: {
          left: {
            text: this.$t('FertilizerProgramEdit:ModalRoute:ButtonOk'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('FertilizerProgramEdit:ModalRoute:ButtonCancel'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
};
</script>
