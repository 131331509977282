import Vue from 'vue';
import VueRouter from 'vue-router';
import SystemPageWrap from '@/pages/system/SystemPageWrap';
import { getItem } from '@/helpers/persistanceStorage';
import Main from '@/pages/Main';
import Users from '@/pages/administration/Users';
import Roles from '@/pages/administration/Roles';
import store from '@/store';
import { actionTypes as adminActionTypes } from '@/store/modules/admin';
import { actionTypes as authActionTypes } from '@/store/modules/auth';
import { actionTypes as glossaryCategoriesCulturesActionTypes } from '@/store/modules/glossaryCategoriesCultures';
import { actionTypes as glossaryTypesStimulationActionTypes } from '@/store/modules/glossaryTypesStimulation';
import { actionTypes as glossaryCulturesActionTypes } from '@/store/modules/glossaryCultures';
import { actionTypes as glossaryGeobaseActionTypes } from '@/store/modules/glossaryGeobase';
import { actionTypes as productsActionTypes } from '@/store/modules/products';
import { actionTypes as userSettingsActionTypes } from '@/store/modules/clientsArea/myData';
import { actionTypes as userAnalyzesActionTypes } from '@/store/modules/clientsArea/analyzes';
import { actionTypes as userInstructionsActionTypes } from '@/store/modules/clientsArea/instructions';
import {
  actionTypes as clientsActionTypes,
  mutationTypes as clientMutationTypes,
} from '@/store/modules/clients';
import {
  actionTypes as clients2ActionTypes,
  mutationTypes as client2MutationTypes,
} from '@/store/modules/clientsArea/clients';
import { actionTypes as clientsListActionTypes } from '@/store/modules/clientsList';
import {
  actionTypes as plantsCareActionTypes,
  mutationTypes as plantsCareMutationTypes,
} from '@/store/modules/plantsCare';
import { actionTypes as plantsCareTemplatesActionTypes } from '@/store/modules/plantsCareTemplates';
import { actionTypes as analyzesActionTypes } from '@/store/modules/analyzes';
import {
  actionTypes as programActionTypes,
  mutationTypes as programsMutationTypes,
} from '@/store/modules/programs';
import {
  actionTypes as udActionTypes,
  mutationTypes as udMutationTypes,
} from '@/store/modules/clientsArea/programs';
import { actionTypes as programsListActionTypes } from '@/store/modules/programsList';
import { actionTypes as udProgramsListActionTypes } from '@/store/modules/clientsArea/programsList';
import { actionTypes as nutritionProgramsListActionTypes } from '@/store/modules/nutritionProgramsList';
import { actionTypes as userNutritionProgramsListActionTypes } from '@/store/modules/clientsArea/nutritionProgramsList';
import {
  actionTypes as nutritionActionTypes,
  mutationTypes as nutritionMutationTypes,
} from '@/store/modules/nutritionPrograms';
import {
  actionTypes as userNutritionActionTypes,
  mutationTypes as userNutritionMutationTypes,
} from '@/store/modules/clientsArea/nutritionPrograms';
import { actionTypes as articlesActionTypes } from '@/store/modules/articles';
import { actionTypes as instructionsActionTypes } from '@/store/modules/instructions';
import { actionTypes as userArticlesActionTypes } from '@/store/modules/clientsArea/articles';

import UsersAddEdit from '@/pages/administration/UsersAddEdit';
import GlossaryCategories from '@/pages/glossary/GlossaryCategories';
import GlossaryCultures from '@/pages/glossary/GlossaryCultures';
import GlossaryPhases from '@/pages/glossary/GlossaryPhases';
import GlossaryPhasesEdit from '@/pages/glossary/GlossaryPhasesEdit';
import GlossaryBadObjects from '@/pages/glossary/GlossaryBadObjects';
import GlossaryBadObjectsEdit from '@/pages/glossary/GlossaryBadObjectsEdit';
import GlossaryRegions from '@/pages/glossary/GlossaryRegions';
import GlossaryRegionsEdit from '@/pages/glossary/GlossaryRegionsEdit';
import GlossaryRegionsNew from '@/pages/glossary/GlossaryRegionsNew';
import GlossaryTypeStimulations from '@/pages/glossary/GlossaryTypeStimulations';
import routeNames from '@/router/routeNames';
import RolesAddEdit from '@/pages/administration/RolesAddEdit';
import GlossaryCategoriesEdit from '@/pages/glossary/GlossaryCategoriesEdit';
import GlossaryTypeStimulationsEdit from '@/pages/glossary/GlossaryTypeStimulationsEdit';
import GlossaryCulturesEdit from '@/pages/glossary/GlossaryCulturesEdit';
import Products from '@/pages/productsclients/Products.vue';
import Clients from '@/pages/productsclients/Clients.vue';
import ProductsEdit from '@/pages/productsclients/ProductsEdit';
import ClientsEdit from '@/pages/productsclients/ClientsEdit';
import DefenseTemplates from '@/pages/programs/DefenseTemplates';
import DefenseTemplateEdit from '@/pages/programs/DefenseTemplateEdit';
import DefensePrograms from '@/pages/programs/DefensePrograms';
import DefenseProgramEdit from '@/pages/programs/DefenseProgramEdit';
import FertilizerPrograms from '@/pages/programs/FertilizerPrograms';
import FertilizerProgramEdit from '@/pages/programs/FertilizerProgramEdit';
import PrintPage from '@/pages/PrintPage';
import PrintProgram from '@/pages/programs/programprint/PrintProgramDefense';
import PrintProgramFertilizer from '@/pages/programs/programprint/PrintProgramFertilizer';
import AnalyzesList from '@/pages/analyzes/AnalyzesList';
import AnalyzesEdit from '@/pages/analyzes/AnalyzesEdit';
import FertilizerDefaultList from '@/pages/programs/FertilizerDefaultList';
import FertilizerDefaultEdit from '@/pages/programs/FertilizerDefaultEdit';

import DemoIcons from '@/pages/demoicons';

import SettingsPage from '@/pages/administration/SettingsPage';

import AdminAdwBannersEditor from '@/pages/administration/AdminAdwBannersEditor';
import AdminAdwBanners from '@/pages/administration/AdminAdwBanners';

import AdminArticlesEditor from '@/pages/administration/AdminArticlesEditor';
import AdminArticleInstructionsEditor from '@/pages/administration/AdminArticleInstructionsEditor';
import AdminArticles from '@/pages/administration/AdminArticles';

//====== userproject ======\\

import UserMain from '@/pages/userproject/UserMain';
import UserHomePage from '@/pages/userproject/UserHomePage';
import UserArticlesList1 from '@/pages/userproject/userArticles/UserArticlesList1';
import UserArticlesList2 from '@/pages/userproject/userArticles/UserArticlesList2';
import UserArticlesList3 from '@/pages/userproject/userArticles/UserArticlesList3';
import UserArticlesList4 from '@/pages/userproject/userArticles/UserArticlesList4';
import UserStartscreen from '@/pages/userproject/UserStartscreen';
import UserAnalyzes from '@/pages/userproject/UserAnalyzes';

import UserDefence from '@/pages/userproject/userPrograms/UserDefence';
import UserFertilizers from '@/pages/userproject/userPrograms/UserFertilizers';
import UserFertilizerProgramEdit from '@/pages/userproject/userPrograms/UserFertilizerProgramEdit';
import UserDefenceProgramEdit from '@/pages/userproject/userPrograms/UserDefenceProgramEdit';

import UserMyCultures from '@/pages/userproject/UserMyCultures';
import UserSettings from '@/pages/userproject/UserSettings';
import UserAnalysesInstruction1 from '@/pages/userproject/userArticles/Guides/UserAnalysesInstruction1';
import UserAnalysesInstruction2 from '@/pages/userproject/userArticles/Guides/UserAnalysesInstruction2';
import UserAnalysesInstruction3 from '@/pages/userproject/userArticles/Guides/UserAnalysesInstruction3';
import UserAnalysesInstruction4 from '@/pages/userproject/userArticles/Guides/UserAnalysesInstruction4';
import UserGuideFertilizer from '@/pages/userproject/userArticles/Guides/UserGuideFertilizer';
import UserPrintProgramFertilizer from '@/pages/userproject/userPrograms/UserPrintProgramFertilizer';
import UserArticlesList from '@/pages/userproject/userArticles/UserArticlesList';
import UserAnalysesInstruction from '@/pages/userproject/userArticles/Guides/UserAnalysesInstruction';
import AdminInstructions from '@/pages/administration/AdminInstructions';
import DemoArticle from '@/pages/userproject/userArticles/DemoArticle';
import UserArticle from '@/pages/userproject/userArticles/UserArticle';
import i18n from '@/i18n';
import ClientsDuplicates from '@/pages/productsclients/ClientsDuplicates';
import { parseQueryString, removeQueryParams } from '@/helpers/textHelper';

//====== ============ ======\\

Vue.use(VueRouter);

export const routeHistory = [];

const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/UserStartscreen', // <- редирект
    children: [
      // По умолчанию, что показывать:
      /* {
                path: '',
                name: 'Users',
                component: Users,
                //auth: true
            },*/
      {
        path: 'users',
        name: routeNames.users,
        component: Users,
        meta: {
          title: i18n.t('Users:Title'),
        },
        //auth: true
      },
      {
        path: 'users/new',
        name: routeNames.newUser,
        component: UsersAddEdit,
        meta: {
          title:
            i18n.t('UsersAddEdit:TitleUsers') +
            ' - ' +
            i18n.t('UsersAddEdit:TitleNew'),
        },
      },
      {
        path: 'users/:id/edit',
        name: routeNames.editUser,
        component: UsersAddEdit,
        meta: {
          title:
            i18n.t('UsersAddEdit:TitleUsers') +
            ' - ' +
            i18n.t('UsersAddEdit:TitleEdit'),
        },
      },
      {
        path: 'roles',
        name: routeNames.roles,
        component: Roles,
        meta: {
          title: i18n.t('Roles:Title'),
        },
      },
      {
        path: 'roles/new',
        name: routeNames.newRole,
        component: RolesAddEdit,
        meta: {
          title:
            i18n.t('RolesAddEdit:TitleRoles') +
            ' - ' +
            i18n.t('RolesAddEdit:TitleNew'),
        },
      },
      {
        path: 'roles/:id/edit',
        name: routeNames.editRole,
        component: RolesAddEdit,
        meta: {
          title:
            i18n.t('RolesAddEdit:TitleRoles') +
            ' - ' +
            i18n.t('RolesAddEdit:TitleEdit'),
        },
      },
      {
        path: 'categories',
        name: routeNames.categories,
        component: GlossaryCategories,
        meta: {
          title: i18n.t('GlossaryCategories:Title'),
        },
      },
      {
        path: 'categories/new',
        name: routeNames.newCategory,
        component: GlossaryCategoriesEdit,
        meta: {
          title:
            i18n.t('GlossaryCategoriesEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryCategoriesEdit:Title3'),
        },
      },
      {
        path: 'categories/:id/edit',
        name: routeNames.editCategory,
        component: GlossaryCategoriesEdit,
        meta: {
          title:
            i18n.t('GlossaryCategoriesEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryCategoriesEdit:Title2'),
        },
      },
      {
        path: 'cultures',
        name: routeNames.cultures,
        component: GlossaryCultures,
        meta: {
          title: i18n.t('GlossaryCultures:Title'),
        },
      },
      {
        path: 'cultures/new',
        name: routeNames.newCulture,
        component: GlossaryCulturesEdit,
        meta: {
          title: i18n.t('GlossaryCulturesEdit:Title3'),
        },
      },
      {
        path: 'cultures/:id/edit',
        name: routeNames.editCulture,
        component: GlossaryCulturesEdit,
        meta: {
          title: i18n.t('GlossaryCulturesEdit:Title2'),
        },
      },
      {
        path: 'phases',
        name: routeNames.phases,
        component: GlossaryPhases,
        meta: {
          title: i18n.t('GlossaryPhases:Title'),
        },
      },
      {
        path: 'phases/new',
        name: routeNames.newPhase,
        component: GlossaryPhasesEdit,
        meta: {
          title: i18n.t('GlossaryPhasesEdit:Title3'),
        },
      },
      {
        path: 'phases/:id/edit',
        name: routeNames.editPhase,
        component: GlossaryPhasesEdit,
        meta: {
          title: i18n.t('GlossaryPhasesEdit:Title2'),
        },
      },
      {
        path: 'bad-objects',
        name: routeNames.badObjects,
        component: GlossaryBadObjects,
        meta: {
          title: i18n.t('GlossaryBadObjects:Title'),
        },
      },
      {
        path: 'bad-objects/:id/edit',
        name: routeNames.editPest,
        component: GlossaryBadObjectsEdit,
        meta: {
          title:
            i18n.t('GlossaryBadObjectsEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryBadObjectsEdit:Title2'),
        },
      },
      {
        path: 'bad-objects/new',
        name: routeNames.newPest,
        component: GlossaryBadObjectsEdit,
        meta: {
          title:
            i18n.t('GlossaryBadObjectsEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryBadObjectsEdit:Title3'),
        },
      },
      {
        path: 'regions',
        name: routeNames.regions,
        component: GlossaryRegions,
        meta: {
          title: i18n.t('GlossaryRegions:Title'),
        },
      },
      {
        path: 'regions/:id/edit',
        name: routeNames.editRegion,
        component: GlossaryRegionsEdit,
        meta: {
          title:
            i18n.t('GlossaryRegions:Title') +
            ' - ' +
            i18n.t('GlossaryRegionsEdit:Edit'),
        },
      },
      {
        path: 'regions/:type/add',
        name: routeNames.newRegion,
        component: GlossaryRegionsNew,
        meta: {
          title:
            i18n.t('GlossaryRegions:Title') +
            ' - ' +
            i18n.t('GlossaryRegionsEdit:Edit'),
        },
      },
      {
        path: 'stimulations',
        name: routeNames.typesStimulation,
        component: GlossaryTypeStimulations,
        meta: {
          title: i18n.t('GlossaryTypeStimulations:Title'),
        },
      },
      {
        path: 'stimulations/new',
        name: routeNames.newTypeStimulation,
        component: GlossaryTypeStimulationsEdit,
        meta: {
          title:
            i18n.t('GlossaryTypeStimulationsEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryTypeStimulationsEdit:Title3'),
        },
      },
      {
        path: 'stimulations/:id/edit',
        name: routeNames.editTypeStimulation,
        component: GlossaryTypeStimulationsEdit,
        meta: {
          title:
            i18n.t('GlossaryTypeStimulationsEdit:Title1') +
            ' - ' +
            i18n.t('GlossaryTypeStimulationsEdit:Title2'),
        },
      },
      {
        path: 'products',
        name: routeNames.products,
        component: Products,
        meta: {
          title: i18n.t('Products:Title'),
        },
      },
      {
        path: 'products/new',
        name: routeNames.newProduct,
        component: ProductsEdit,
        meta: {
          title:
            i18n.t('ProductsEdit:Title1') +
            ' - ' +
            i18n.t('ProductsEdit:Title3'),
        },
      },
      {
        path: 'products/:id/edit',
        name: routeNames.editProduct,
        component: ProductsEdit,
        meta: {
          title:
            i18n.t('ProductsEdit:Title1') +
            ' - ' +
            i18n.t('ProductsEdit:Title2'),
        },
      },
      {
        path: 'clients',
        name: routeNames.clients,
        component: Clients,
        meta: {
          title: i18n.t('Clients:Title'),
        },
      },
      {
        path: 'clientsDuplicates',
        name: routeNames.clientsDuplicates,
        component: ClientsDuplicates,
        meta: {
          title: i18n.t('Clients:Title'),
        },
      },
      {
        path: 'clients/new',
        name: routeNames.newClient,
        component: ClientsEdit,
        meta: {
          title:
            i18n.t('ClientsEdit:Title:0') +
            ' - ' +
            i18n.t('ClientsEdit:Title:2'),
        },
      },
      {
        path: 'clients/:id/edit',
        name: routeNames.editClient,
        component: ClientsEdit,
        meta: {
          title:
            i18n.t('ClientsEdit:Title:0') +
            ' - ' +
            i18n.t('ClientsEdit:Title:1'),
        },
      },
      {
        path: 'defenseTemplates',
        name: routeNames.defenseTemplates,
        component: DefenseTemplates,
        meta: {
          title: i18n.t('DefenseTemplates:Title'),
        },
      },
      {
        path: 'defenseTemplates/new',
        name: routeNames.newDefenseTemplate,
        component: DefenseTemplateEdit,
        meta: {
          title:
            i18n.t('DefenseTemplateEdit:Title:0') +
            ' - ' +
            i18n.t('DefenseTemplateEdit:Title:2'),
        },
      },
      {
        path: 'defenseTemplates/:id/edit',
        name: routeNames.editDefenseTemplate,
        component: DefenseTemplateEdit,
        meta: {
          title:
            i18n.t('DefenseTemplateEdit:Title:0') +
            ' - ' +
            i18n.t('DefenseTemplateEdit:Title:1'),
        },
      },
      {
        path: 'defensePrograms',
        name: routeNames.defensePrograms,
        component: DefensePrograms,
        meta: {
          title: i18n.t('DefensePrograms:Title'),
        },
      },
      {
        path: 'defensePrograms/new',
        name: routeNames.newDefenseProgram,
        component: DefenseProgramEdit,
        meta: {
          title:
            i18n.t('DefenseProgramEdit:Title:0') +
            ' - ' +
            i18n.t('DefenseProgramEdit:Title:2'),
        },
      },
      {
        path: 'defensePrograms/:id/edit',
        name: routeNames.editDefenseProgram,
        component: DefenseProgramEdit,
        meta: {
          title:
            i18n.t('DefenseProgramEdit:Title:0') +
            ' - ' +
            i18n.t('DefenseProgramEdit:Title:1'),
        },
      },
      {
        path: 'fertilizerPrograms',
        name: routeNames.fertilizerPrograms,
        component: FertilizerPrograms,
        meta: {
          title: i18n.t('FertilizerPrograms:Title'),
        },
      },
      {
        path: 'fertilizerDefaultList',
        name: routeNames.fertilizerDefaultList,
        component: FertilizerDefaultList,
        meta: {
          //FIXME
          //title: i18n.t('DefenseProgramEdit:Title:1'),
        },
      },
      {
        path: 'fertilizerDefaultList/new',
        name: routeNames.newFertilizerDefault,
        component: FertilizerDefaultEdit,
        meta: {
          //FIXME
          //title: i18n.t('DefenseProgramEdit:Title:1'),
        },
      },
      {
        path: 'fertilizerDefaultList/:id/edit',
        name: routeNames.editFertilizerDefault,
        component: FertilizerDefaultEdit,
        meta: {
          //FIXME
          //title: i18n.t('DefenseProgramEdit:Title:1'),
        },
      },

      {
        path: 'demoicons',
        name: routeNames.demoIcons,
        component: DemoIcons,
      },

      {
        path: 'fertilizerPrograms/new',
        name: routeNames.newFertilizerProgram,
        component: FertilizerProgramEdit,
        meta: {
          title:
            i18n.t('FertilizerProgramEdit:Title:0') +
            ' - ' +
            i18n.t('FertilizerProgramEdit:Title:2'),
        },
      },
      {
        path: 'fertilizerPrograms/:id/edit',
        name: routeNames.editFertilizerProgram,
        component: FertilizerProgramEdit,
        meta: {
          title:
            i18n.t('FertilizerProgramEdit:Title:0') +
            ' - ' +
            i18n.t('FertilizerProgramEdit:Title:1'),
        },
      },
      {
        path: 'analyzes',
        name: routeNames.analyzes,
        component: AnalyzesList,
        meta: {
          title: i18n.t('AnalyzesList:Title'),
        },
      },
      {
        path: 'analyzes/new',
        name: routeNames.newAnalyse,
        component: AnalyzesEdit,
        meta: {
          title:
            i18n.t('AnalyzesEdit:Title:0') +
            ' - ' +
            i18n.t('AnalyzesEdit:Title:1'),
        },
      },
      {
        path: 'analyzes/:id/edit',
        name: routeNames.editAnalyse,
        component: AnalyzesEdit,
        meta: {
          title:
            i18n.t('AnalyzesEdit:Title:0') +
            ' - ' +
            i18n.t('AnalyzesEdit:Title:2'),
        },
      },
      {
        path: 'settingsPage',
        name: routeNames.settingsPage,
        component: SettingsPage,
        meta: {
          title: i18n.t('SettingsPage:TitleText'),
        },
      },
      {
        path: 'banners',
        name: routeNames.adminAdwBanners,
        component: AdminAdwBanners,
        meta: {
          title: i18n.t('AdminAdwBanners:title'),
        },
      },
      {
        path: 'banners/new',
        name: routeNames.newAdminAdwBanner,
        component: AdminAdwBannersEditor,
        meta: {
          title:
            i18n.t('AdminAdwBannersEditor:Title:0') +
            ' - ' +
            i18n.t('AdminAdwBannersEditor:Title:2'),
        },
      },
      {
        path: 'banners/:id/edit',
        name: routeNames.editAdminAdwBanner,
        component: AdminAdwBannersEditor,
        meta: {
          title:
            i18n.t('AdminAdwBannersEditor:Title:0') +
            ' - ' +
            i18n.t('AdminAdwBannersEditor:Title:1'),
        },
      },
      {
        path: 'instructions',
        name: routeNames.instructions,
        component: AdminInstructions,
        meta: {
          title: i18n.t('AdminInstructions:Title'),
        },
      },
      {
        path: 'instructions/:id/edit',
        name: routeNames.editInstruction,
        component: AdminArticleInstructionsEditor,
        meta: {
          title:
            i18n.t('AdminInstructions:Title') +
            ' - ' +
            i18n.t('AdminInstructions:Title2'),
        },
      },
      {
        path: 'articles',
        name: routeNames.articles,
        component: AdminArticles,
        meta: {
          title: i18n.t('AdminArticles:Title'),
        },
      },
      {
        path: 'articles/new',
        name: routeNames.newArticle,
        component: AdminArticlesEditor,
        meta: {
          title:
            i18n.t('AdminArticlesEditor:Title:0') +
            ' - ' +
            i18n.t('AdminArticlesEditor:Title:2'),
        },
      },
      {
        path: 'articles/:id/edit',
        name: routeNames.editArticle,
        component: AdminArticlesEditor, //AdminArticleInstructionsEditor,
        meta: {
          title:
            i18n.t('AdminArticlesEditor:Title:0') +
            ' - ' +
            i18n.t('AdminArticlesEditor:Title:1'),
        },
      },
      {
        path: 'demoArticle',
        name: 'demoArticle',
        component: DemoArticle,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: SystemPageWrap,
    props: { content: 'login' },
    meta: {
      title: i18n.t('UserLogin:ButtonEnter'),
    },
  },
  {
    path: '/newpassword',
    name: 'NewPassword',
    component: SystemPageWrap,
    props: { content: 'newPassword' },
    meta: {
      title: i18n.t('LoginNewPassword:Title'),
    },
  },
  {
    path: '/printpage',
    name: 'PrintPage',
    component: PrintPage,
    props: { content: 'printPage' },
  },
  {
    path: '/defensePrograms/:id/print',
    name: routeNames.printProgramDefense,
    component: PrintProgram,
  },
  {
    path: '/plantsNutrition/:id/print',
    name: routeNames.printProgramFertilizers,
    component: PrintProgramFertilizer,
  },
  {
    path: '/usermain',
    name: 'UserMain',
    component: UserMain,
    meta: { chat: true },
    children: [
      {
        path: 'UserHomePage',
        name: routeNames.userHomePage,
        component: UserHomePage,
        meta: { title: i18n.t('UserSideMenu:Main') },
      },
      {
        path: 'UserHomePage/UserArticlesList/:id/',
        name: routeNames.userArticlesList,
        component: UserArticlesList,
        meta: { title: i18n.t('UserSideMenu:Main') },
      },
      {
        path: 'UserHomePage/UserArticlesList/:id/:articleId',
        name: routeNames.userArticle,
        component: UserArticle,
        meta: { title: i18n.t('UserSideMenu:Main') },
      },
      {
        path: 'UserArticlesList1',
        name: routeNames.userArticlesList1,
        component: UserArticlesList1,
      },
      {
        path: 'UserArticlesList2',
        name: routeNames.userArticlesList2,
        component: UserArticlesList2,
      },
      {
        path: 'UserArticlesList3',
        name: routeNames.userArticlesList3,
        component: UserArticlesList3,
      },
      {
        path: 'UserArticlesList4',
        name: routeNames.userArticlesList4,
        component: UserArticlesList4,
      },
      {
        path: 'UserAnalyzes/userAnalysesInstruction/:id/',
        name: routeNames.userAnalysesInstruction,
        component: UserAnalysesInstruction,
      },
      {
        path: 'userAnalysesInstruction1',
        name: routeNames.userAnalysesInstruction1,
        component: UserAnalysesInstruction1,
      },
      {
        path: 'userAnalysesInstruction2',
        name: routeNames.userAnalysesInstruction2,
        component: UserAnalysesInstruction2,
      },
      {
        path: 'userAnalysesInstruction3',
        name: routeNames.userAnalysesInstruction3,
        component: UserAnalysesInstruction3,
      },
      {
        path: 'userAnalysesInstruction4',
        name: routeNames.userAnalysesInstruction4,
        component: UserAnalysesInstruction4,
      },
      {
        path: 'userGuideFertilizer',
        name: routeNames.userGuideFertilizer,
        component: UserGuideFertilizer,
      },

      {
        path: 'UserMyCultures',
        name: routeNames.userMyCultures,
        component: UserMyCultures,
        meta: { title: i18n.t('UserMyCultures:Title') },
      },
      {
        path: 'UserAnalyzes',
        name: routeNames.userAnalyzes,
        component: UserAnalyzes,
        meta: { title: i18n.t('UserAnalyzes:Title') },
      },
      {
        path: 'UserDefence',
        name: routeNames.userDefence,
        component: UserDefence,
        meta: { title: i18n.t('UserDefence:Title') },
      },
      {
        path: 'UserFertilizers',
        name: routeNames.userFertilizers,
        component: UserFertilizers,
        meta: { title: i18n.t('UserFertilizers:Title') },
      },
      {
        path: 'UserFertilizers/UserFertilizerProgramCreate',
        name: routeNames.userFertilizerProgramCreate,
        component: UserFertilizerProgramEdit,
        auth: 'client',
        meta: {
          title:
            i18n.t('UserFertilizerProgramEdit:Header1') +
            ' - ' +
            i18n.t('UserFertilizerProgramEdit:Header3'),
        },
      },
      {
        path: 'UserFertilizers/UserFertilizerProgram/:id/edit',
        name: routeNames.userFertilizerProgramEdit,
        component: UserFertilizerProgramEdit,
        meta: {
          title:
            i18n.t('UserFertilizerProgramEdit:Header1') +
            ' - ' +
            i18n.t('UserFertilizerProgramEdit:Header2'),
        },
      },
      {
        path: 'UserDefence/UserDefenceProgramCreate',
        name: routeNames.userDefenceProgramCreate,
        component: UserDefenceProgramEdit,
        auth: 'client',
        meta: {
          title:
            i18n.t('UserDefenceProgramEdit:Title') +
            ' - ' +
            i18n.t('UserDefenceProgramEdit:TitleCreate'),
        },
      },
      {
        path: 'UserDefence/UserDefenceProgram/:id/edit',
        name: routeNames.userDefenceProgramEdit,
        component: UserDefenceProgramEdit,
        meta: {
          title:
            i18n.t('UserDefenceProgramEdit:Title') +
            ' - ' +
            i18n.t('UserDefenceProgramEdit:TitleEdit'),
        },
      },
      {
        path: 'UserSettings',
        name: routeNames.userSettings,
        component: UserSettings,
        meta: {
          title: i18n.t('UserSettings:Title'),
        },
      },
    ],
  },
  {
    path: '/UserPlantsNutrition/:id/print',
    name: routeNames.userPrintProgramFertilizers,
    component: UserPrintProgramFertilizer,
  },
  {
    path: '/UserDefensePrograms/:id/print',
    name: routeNames.userPrintProgramDefense,
    component: PrintProgram,
  },
  {
    path: '/UserStartscreen',
    name: 'UserStartscreen',
    component: UserStartscreen,
  },
];
//-------------------------------------------------------
//Разделение роутера на 2 (главных в зависимости от домена)
if (window.location.host === 'digitalagro.cartlis.ge') {
  routes[0].redirect = '/users';
}
//-------------------------------------------------------

const router = new VueRouter({
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});
router.beforeEach(function (to, from, next) {
  //console.log('beforeEach', to.path )
  let isUserLogin = getItem('token');

  if (to.name?.toLowerCase().startsWith('user')) {
    next();
    return;
  }

  if (to.path !== '/login' && to.path !== 'login' && !isUserLogin) {
    next({ path: '/login' });
  } else {
    if (isUserLogin && !store.state.auth.currentUser) {
      //------------------------------------------------------------------
      //Получить пользователя и роль
      if (to.path !== '/login') {
        store.dispatch(authActionTypes.getCurrentUser);
      }
    }
    if (
      isUserLogin &&
      store.state.auth.currentUser?.is_standard_password === 0 &&
      to.path !== '/newpassword'
    ) {
      next({ path: '/newpassword' });
    } else {
      next();
    }
  }
});

router.beforeEach((to, from, next) => {
  //console.log(from);
  //console.log(to);
  if (to.name === from.name && to.query?.page !== from.query?.page) {
    //console.log('block before each');
    next();
    return;
  }

  function isParentRoute(to, from) {
    if (!to || !from || to?.path?.length < 2 || from?.path?.length < 2) {
      return false;
    }
    if (from?.path?.startsWith(to?.path) && from?.path !== to?.path) {
      return true;
    }
    return false;
  }

  if (isParentRoute(to, from) && !(to?.params && to?.params?.fromMenu)) {
    //console.log(`${to.path} является родительским для ${from.path}`);
    //console.log(this.$router.history.previous);
    let url = router.getOneStepsBack();
    /*console.log({
      url: url,
      removeQueryParams: removeQueryParams(url),
      to_path: to.path,
      to: to,
    });*/
    if (
      url &&
      removeQueryParams(url) === to.path &&
      url !== to.path &&
      Object.keys(to.query).length === 0
    ) {
      //console.log('redirect to ' + url);
      //next(url);
      /*next({
        ...to,
        query: parseQueryString(url),
      });*/

      setTimeout(() => {
        //console.log('push to query', parseQueryString(url));
        router.push({
          ...to,
          query: parseQueryString(url),
          params: {
            ...to?.params,
            skipModal: true,
          },
        });
      }, 10);
      return false;

      /*router.push({
        ...to,
        query: parseQueryString(url),
      });*/
      //return false;
    }
  } else {
    //console.log(`${to.path} не является родительским для ${from.path}`);
  }

  switch (to.name) {
    case routeNames.users: {
      store.dispatch(adminActionTypes.getUsers);
      next();
      break;
    }
    case routeNames.newUser: {
      store.state.admin.editUser = null;
      store.state.admin.editUserContacts = null;
      if (!store.state.admin.roles || !store.state.admin.contactTypes) {
        store.dispatch(adminActionTypes.getNewUserData);
      }
      next();
      break;
    }
    case routeNames.editUser: {
      store.dispatch(adminActionTypes.getEditUserData, to.params.id);
      next();
      break;
    }
    case routeNames.roles: {
      store.dispatch(adminActionTypes.getRoles);
      next();
      break;
    }
    case routeNames.editRole: {
      store.dispatch(adminActionTypes.getEditRole, to.params.id);
      next();
      break;
    }
    case routeNames.newAdminAdwBanner: {
      store.dispatch(glossaryGeobaseActionTypes.getGeobase);
      next();
      break;
    }
    case routeNames.editAdminAdwBanner: {
      store.dispatch(glossaryGeobaseActionTypes.getGeobase);
      next();
      break;
    }
    case routeNames.adminAdwBanners: {
      store.dispatch(glossaryGeobaseActionTypes.getGeobase);
      next();
      break;
    }
    case routeNames.categories: {
      store.dispatch(
        glossaryCategoriesCulturesActionTypes.getCategoriesCultures
      );
      next();
      break;
    }
    case routeNames.editCategory: {
      store.dispatch(
        glossaryCategoriesCulturesActionTypes.getCategoriesCultures,
        to.params.id
      );
      next();
      break;
    }
    case routeNames.typesStimulation: {
      store.dispatch(glossaryTypesStimulationActionTypes.getTypesStimulation);
      next();
      break;
    }
    case routeNames.editTypeStimulation: {
      store.dispatch(
        glossaryTypesStimulationActionTypes.getTypesStimulation,
        to.params.id
      );
      next();
      break;
    }
    case routeNames.cultures: {
      store.dispatch(glossaryCulturesActionTypes.getCultures, to.params.id);
      next();
      break;
    }
    case routeNames.newCulture: {
      store.dispatch(glossaryCulturesActionTypes.getNewCulture);
      next();
      break;
    }
    case routeNames.editCulture: {
      store.dispatch(
        glossaryCulturesActionTypes.getEditCultureData,
        to.params.id
      );
      next();
      break;
    }
    case routeNames.products: {
      //store.dispatch(products.getProducts,to.params.id);
      store.dispatch(productsActionTypes.getProducts);
      next();
      break;
    }
    case routeNames.newProduct: {
      //store.dispatch(products.getProducts,to.params.id);
      next();
      break;
    }
    case routeNames.editProduct: {
      //store.dispatch(products.getProducts,to.params.id);
      next();
      break;
    }
    case routeNames.clients: {
      store.dispatch(clientsListActionTypes.getClients);
      next();
      break;
    }
    case routeNames.newClient: {
      store.commit(clientMutationTypes.setDefaultState);
      store.dispatch(clientsActionTypes.getClientData);
      next();
      break;
    }
    case routeNames.editClient: {
      store.commit(clientMutationTypes.setDefaultState);
      store.dispatch(clientsActionTypes.getClientData, { id: to.params.id });
      next();
      break;
    }
    case routeNames.regions: {
      store.dispatch(glossaryGeobaseActionTypes.getGeobase);
      next();
      break;
    }
    case routeNames.editRegion:
    case routeNames.newRegion: {
      if (store.state.glossaryGeobase.geobaseRaw.length === 0) {
        store.dispatch(glossaryGeobaseActionTypes.getGeobase);
      }
      next();
      break;
    }
    case routeNames.newDefenseTemplate: {
      store.commit(plantsCareMutationTypes.resetState);
      store.dispatch(plantsCareActionTypes.getNewTemplateData);
      next();
      break;
    }
    case routeNames.editDefenseTemplate: {
      store.commit(plantsCareMutationTypes.resetState);
      store.dispatch(plantsCareActionTypes.getNewTemplateData, to.params.id);
      next();
      break;
    }
    case routeNames.defenseTemplates: {
      store.dispatch(plantsCareTemplatesActionTypes.fetchTemplates);
      next();
      break;
    }
    case routeNames.newDefenseProgram: {
      store.commit(programsMutationTypes.resetState);
      store.dispatch(programActionTypes.fetchProgram);
      next();
      break;
    }
    case routeNames.newAnalyse: {
      store.dispatch(analyzesActionTypes.getNewAnalyseData);
      next();
      break;
    }
    case routeNames.editAnalyse: {
      store.dispatch(analyzesActionTypes.getEditAnalyseData, {
        id: to.params.id,
      });
      next();
      break;
    }
    case routeNames.analyzes: {
      store.dispatch(analyzesActionTypes.getAnalyzes);
      next();
      break;
    }
    case routeNames.editDefenseProgram: {
      store.commit(programsMutationTypes.resetState);
      store.dispatch(programActionTypes.fetchProgram, to.params.id);
      next();
      break;
    }
    case routeNames.defensePrograms: {
      store.dispatch(programsListActionTypes.fetchPrograms);
      next();
      break;
    }
    case routeNames.newFertilizerProgram: {
      store.commit(nutritionMutationTypes.resetState);
      store.dispatch(nutritionActionTypes.fetchProgram);
      next();
      break;
    }
    case routeNames.editFertilizerProgram: {
      store.commit(nutritionMutationTypes.resetState);
      store.dispatch(nutritionActionTypes.fetchProgram, to.params.id);
      next();
      break;
    }
    case routeNames.fertilizerPrograms: {
      store.dispatch(nutritionProgramsListActionTypes.fetchPrograms);
      next();
      break;
    }
    //-----------------------------------------------------------------------
    case routeNames.userMyCultures: {
      store.commit(client2MutationTypes.setDefaultState);
      store.dispatch(clients2ActionTypes.getClientData);
      next();
      break;
    }
    case routeNames.userDefence: {
      store.dispatch(udProgramsListActionTypes.fetchPrograms);
      next();
      break;
    }
    case routeNames.userDefenceProgramCreate: {
      store.commit(udMutationTypes.resetState);
      store.dispatch(udActionTypes.fetchProgram);
      next();
      break;
    }
    case routeNames.userDefenceProgramEdit: {
      store.commit(udMutationTypes.resetState);
      store.dispatch(udActionTypes.fetchProgram, to.params.id);
      next();
      break;
    }
    case routeNames.userSettings: {
      store.dispatch(userSettingsActionTypes.getMyData);
      next();
      break;
    }
    case routeNames.userAnalyzes: {
      store.dispatch(userAnalyzesActionTypes.getAnalyzes);
      next();
      break;
    }
    case routeNames.userFertilizers: {
      store.dispatch(userNutritionProgramsListActionTypes.fetchPrograms);
      next();
      break;
    }
    case routeNames.userFertilizerProgramCreate: {
      store.commit(userNutritionMutationTypes.resetState);
      store.dispatch(userNutritionActionTypes.fetchProgram);
      next();
      break;
    }
    case routeNames.userFertilizerProgramEdit: {
      store.commit(userNutritionMutationTypes.resetState);
      store.dispatch(userNutritionActionTypes.fetchProgram, to.params.id);
      next();
      break;
    }
    case routeNames.userAnalysesInstruction: {
      store.dispatch(userInstructionsActionTypes.getInstructions);
      next();
      break;
    }
    case routeNames.userStartscreen: {
      store.dispatch(glossaryCulturesActionTypes.getCultures);
      next();
      break;
    }
    case routeNames.settingsPage: {
      store.dispatch(authActionTypes.getCurrentUserFull);
      next();
      break;
    }
    case routeNames.articles: {
      store.dispatch(articlesActionTypes.getArticles);
      next();
      break;
    }
    case routeNames.userArticle: {
      store.dispatch(userArticlesActionTypes.getArticle, {
        id: to.params.articleId,
      });
      next();
      break;
    }
    case routeNames.editArticle: {
      store.dispatch(articlesActionTypes.getEditArticleData, {
        id: to.params.id,
      });
      next();
      break;
    }
    case routeNames.editInstruction: {
      store.dispatch(instructionsActionTypes.getEditInstructionData, {
        categoryId: to.params.id,
      });
      next();
      break;
    }
    default: {
      next();
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta?.chat)) {
    window.enableChat();
    window.showChat = true;
    if (window?.chat?.showWidget) {
      window.chat.showWidget();
      window.showChat = true;
    }
  } else {
    //window.disableChat();
    //window.showChat = false;
    if (window?.Tawk_API?.hideWidget) {
      window.Tawk_API.hideWidget();
      window.showChat = false;
    }
  }
  if (to.path === '/login' || to.path === 'login') {
    window.disableChat();
  }
  next();
});

router.beforeEach((to, from, next) => {
  switch (to.name) {
    case routeNames.users: {
      if (store.state.auth?.currentUserRole?.claim_users_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.categories: {
      if (store.state.auth?.currentUserRole?.claim_glossary_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.products: {
      if (store.state.auth?.currentUserRole?.claim_products_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.clients: {
      if (store.state.auth?.currentUserRole?.claim_clients_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.defensePrograms: {
      if (store.state.auth?.currentUserRole?.claim_plants_care_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.fertilizerPrograms: {
      if (store.state.auth?.currentUserRole?.claim_plants_nutrition_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
    case routeNames.analyzes: {
      if (store.state.auth?.currentUserRole?.claim_analyse_see === 0) {
        redirectUserToFirstAvailablePage();
        return;
      }
      break;
    }
  }
  next();
});

router.afterEach((to, from) => {
  document.title = to.meta.title || 'Cartlis';
});

//Этот должен быть последним - регистрирует переход
router.afterEach((to, from) => {
  // Добавляем предыдущий маршрут в историю
  if (from.fullPath && from.fullPath !== '/') {
    routeHistory.push(from.fullPath);
  }

  // Ограничиваем размер истории (например, до 10 элементов)
  if (routeHistory.length > 20) {
    routeHistory.shift();
  }

  //console.log('История маршрутов:', routeHistory);
});

// Метод для получения "предыдущего предыдущего" URL
router.getTwoStepsBack = () => {
  if (routeHistory.length >= 2) {
    return routeHistory[routeHistory.length - 2]; // Предыдущий предыдущий URL
  }
  return null; // Если недостаточно данных
};
router.getOneStepsBack = () => {
  if (routeHistory.length >= 1) {
    return routeHistory[routeHistory.length - 1]; // Предыдущий предыдущий URL
  }
  return null; // Если недостаточно данных
};

function redirectUserToFirstAvailablePage() {
  if (store.state.auth?.currentUserRole?.claim_users_see === 1) {
    return router.push({ name: routeNames.users });
  }
  if (store.state.auth?.currentUserRole?.claim_glossary_see === 1) {
    return router.push({ name: routeNames.categories });
  }
  if (store.state.auth?.currentUserRole?.claim_products_see === 1) {
    return router.push({ name: routeNames.products });
  }
  if (store.state.auth?.currentUserRole?.claim_clients_see === 1) {
    return router.push({ name: routeNames.clients });
  }
  if (store.state.auth?.currentUserRole?.claim_plants_care_see === 1) {
    return router.push({ name: routeNames.defensePrograms });
  }
  if (store.state.auth?.currentUserRole?.claim_plants_nutrition_see === 1) {
    return router.push({ name: routeNames.fertilizerPrograms });
  }
  if (store.state.auth?.currentUserRole?.claim_analyse_see === 1) {
    return router.push({ name: routeNames.analyzes });
  }
  return router.push({ name: routeNames.settingsPage });
}

export default router;
