const state = {
  filterProducts: {
    nameOrId: '',
    activeSubstance: '',
    typeProduct: null,
    typePest: null,
    typeStimulation: null,
    typeFertilizer: null,
    manufacture: null,
    manufactureCountry: null,
    productsPests: null,
  },
  filterAnalyzes: {
    isActive: false,
    geo: null,
    analyseTypeId: null,
    analyseStatus: null,
    clientName: '',
    clientCode: '',
  },
  filterCultures: {
    name: '',
    category_id: '',
  },
  filterBadObjects: {
    name: '',
    category_id: null,
  },
  filterPhases: {
    name: '',
    culture: null,
  },
  filterClients: {
    geo: null,
    code: '',
    name: '',
    phone: '',
    email: '',
    regMethod: null,
    type: null,
    status: null,
    culture: null,
  },
  filterPlantsCareTemplates: {
    name: '',
    status: null,
    template: null,
    cultureCategory: null,
    culture: null,
    age: null,
    calcMethod: null,
    geo: null,
  },
  filterPlantsCarePrograms: {
    name: '',
    creatorType: null,
    clientName: '',
    code: '',
    cultureCategory: null,
    culture: null,
    age: null,
    calcMethod: null,
    geo: null,
  },
  filterPlantsNutritionPrograms: {
    name: '',
    creatorType: null,
    clientName: '',
    code: '',
    start: { year: null, month: null },
    cultureCategory: null,
    culture: null,
    age: null,
    geo: null,
  },
  filterArticles: {
    name: '',
    category: null,
    type: null,
  },
  filterBanners: {
    geo: null,
    type: null,
  },
  filterClientsDuplicates: {
    duplicate: '',
  },
};

export const mutationTypes = {
  setFilterProducts: '[filtersState] setFilterProducts',
  setFilterAnalyzes: '[filtersState] setFilterAnalyzes',
  setFilterCultures: '[filtersState] setFilterCultures',
  setFilterBadObjects: '[filtersState] setFilterBadObjects',
  setFilterPhases: '[filtersState] setFilterPhases',
  setFilterClients: '[filtersState] setFilterClients',
  setFilterPlantsCareTemplates: '[filtersState] setFilterPlantsCareTemplates',
  setFilterPlantsCarePrograms: '[filtersState] setFilterPlantsCarePrograms',
  setFilterPlantsNutritionPrograms:
    '[filtersState] setFilterPlantsNutritionPrograms',
  setFilterArticles: '[filtersState] setFilterArticles',
  setFilterBanners: '[filtersState] setFilterBanners',
  setFilterClientsDuplicates: '[filtersState] setFilterClientsDuplicates',
};

const mutations = {
  [mutationTypes.setFilterProducts](state, payload) {
    state.filterProducts = payload;
  },
  [mutationTypes.setFilterAnalyzes](state, payload) {
    state.filterAnalyzes = payload;
  },
  [mutationTypes.setFilterCultures](state, payload) {
    state.filterCultures = payload;
  },
  [mutationTypes.setFilterBadObjects](state, payload) {
    state.filterBadObjects = payload;
  },
  [mutationTypes.setFilterPhases](state, payload) {
    state.filterPhases = payload;
  },
  [mutationTypes.setFilterClients](state, payload) {
    state.filterClients = payload;
  },
  [mutationTypes.setFilterPlantsCareTemplates](state, payload) {
    state.filterPlantsCareTemplates = payload;
  },
  [mutationTypes.setFilterPlantsCarePrograms](state, payload) {
    state.filterPlantsCarePrograms = payload;
  },
  [mutationTypes.setFilterPlantsNutritionPrograms](state, payload) {
    state.filterPlantsNutritionPrograms = payload;
  },
  [mutationTypes.setFilterArticles](state, payload) {
    state.filterArticles = payload;
  },
  [mutationTypes.setFilterBanners](state, payload) {
    state.filterBanners = payload;
  },
  [mutationTypes.setFilterClientsDuplicates](state, payload) {
    state.filterClientsDuplicates = payload;
  },
};

export default {
  state,
  mutations,
};
