<template>
  <dropdown-plus-select v-bind="$attrs" :options="options" v-model="value1" />
</template>

<script>
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  name: 'DropdownPlusSelectValue',
  components: { DropdownPlusSelect },
  props: ['value', 'options'],
  data() {
    return {
      tmpValue: null,
      timeStampSetTmpValue: 0,
    };
  },
  computed: {
    value1: {
      get() {
        return this.options.find((v) => v.value === this.value) ?? null;
      },
      set(newValue) {
        /*console.log(
          'value1 new Value',
          newValue,
          newValue === null
            ? null
            : this.options.find(({ value }) => value === newValue.value)
                ?.value ?? null
        );*/
        this.$emit(
          'input',
          newValue === null
            ? null
            : this.options.find(({ value }) => value === newValue.value)
                ?.value ?? null
        );
      },
    },
  },
  watch: {
    //Автосброс дропдауна, если пытаемся установить значение, которого не существует
    value: {
      handler(valueInc) {
        //console.log(this.options, valueInc);
        if (!this.options.find(({ value }) => value === valueInc)) {
          this.value1 = null;
          if (!this.tmpValue) {
            this.tmpValue = valueInc;
            this.timeStampSetTmpValue = new Date().getTime();
          }
        }
      },
      immediate: true,
    },
    options: {
      handler(valueInc) {
        //console.log('options изменился', this.tmpValue, this.options);
        if (
          this.options?.length >= this.tmpValue &&
          new Date().getTime() - this.timeStampSetTmpValue < 3000
        ) {
          this.value1 = { value: this.tmpValue };
          //console.log('this.value1 = this.tmpValue', this.tmpValue);
          this.timeStampSetTmpValue = 0;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
