<!--Translated-->
<template>
  <article class="rightzone rightzone--defence-newprogram">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('DefenseTemplateEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: backRoute })"
        />
        <Button
          :text="$t('DefenseTemplateEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaving"
          :loading-left="true"
          :disabled="!canSubmit"
          @click="saveTemplate"
        />
      </div>
    </div>

    <Warningblock v-if="usedInactiveProducts.length" :show-button="false">
      {{ $t('DefenseTemplateEdit:Warningblock:NotActiveProducts') }}
    </Warningblock>

    <Warningblock
      v-if="programHasPestsOrSTypesWithoutProducts"
      :show-button="false"
    >
      {{ $t('DefenseTemplateEdit:Warningblock:NoPests') }}
    </Warningblock>

    <TemplateDescription :readonly="!canEdit" />

    <div class="cwrap marginbottom--standart" v-if="cultureId === null">
      <div class="cblock_bigtext">
        {{ $t('DefenseTemplateEdit:MainText') }}
      </div>
    </div>

    <div class="cwrap marginbottom--standart" v-else-if="phases.length === 0">
      <div class="cblock_bigtext">{{ $t('DefenseTemplateEdit:NoPhase') }}</div>
    </div>

    <template v-else>
      <div class="cwrap cwrap--phase-template marginbottom--standart">
        <TemplatePhase
          v-for="phase in phases"
          :phase="phase"
          :key="phase.id"
          :readonly="!canEdit"
        />
      </div>

      <div class="cwrap marginbottom--standart">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('DefenseTemplateEdit:ButtonSave')"
              size="standart"
              color="accent"
              icon="save"
              :is-loading="isSaving"
              :loading-left="true"
              :disabled="!canSubmit"
              @click="saveTemplate"
            />

            <Button
              v-if="canEdit"
              text="copy"
              v-tooltip="{ text: $t('DefenseProgramEdit:ModalClone:ButtonOk') }"
              size="s-standart"
              color="accentlines"
              icon="copy"
              :disabled="!plantsCareTemplateFormValid || isSaving"
              @click="cloneTemplate"
            />
          </div>
        </div>
      </div>

      <div class="cwrap marginbottom--standart" v-if="isEditing && canEdit">
        <div class="chead el--borders-top el--borders-sides el--rounded-top">
          <div class="chead__line-block">
            <div
              class="vsvg vsvg--standart chead_icon"
              v-svg="{ name: 'delete' }"
            />
            <div class="chead_text">
              {{ $t('DefenseTemplateEdit:Delete:Header') }}
            </div>
          </div>
        </div>

        <div class="cblock el--borders-all el--rounded-bottom">
          <div class="cblock__infoblock">
            <p>
              {{ $t('DefenseTemplateEdit:Delete:Text1') }}<br />
              {{ $t('DefenseTemplateEdit:Delete:Text2') }}
            </p>

            <Button
              :text="$t('DefenseTemplateEdit:Delete:DeleteButton')"
              size="standart"
              color="alertlines"
              icon="userdelete"
              :is-loading="false"
              :loading-left="true"
              @click="deleteTemplate"
              :disabled="isLoading || isSaving"
            />
          </div>
        </div>
      </div>
    </template>
  </article>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import {
  actionTypes,
  mutationTypes,
  staticData,
} from '@/store/modules/plantsCare';
import routeNames from '@/router/routeNames';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import TemplatePhase from './components/TemplatePhase';
import TemplateDescription from './components/TemplateDescription';
import Warningblock from '@/components/views/Warningblock';

export default {
  name: 'DefenseTemplateEdit',
  components: {
    Button,
    Breadcrumps,
    TemplateDescription,
    TemplatePhase,
    Warningblock,
  },
  data() {
    return { nextRouteConfirm: false };
  },
  computed: {
    ...mapGetters([
      'plantsCareTemplateFormValid',
      'plantsCareWasEdited',
      'plantsCarePestsProductsAsMap',
      'plantsCareSTypesProductsAsMap',
      'plantsCareProductsAsMap',
      'programHasPestsOrSTypesWithoutProducts',
    ]),
    ...mapState({
      isLoading: (state) => state.plantsCare.isLoading,
      isSaving: (state) => state.plantsCare.isSaving,
      error: (state) => state.plantsCare.error,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_edit === 1,
      cultureId: (state) => state.plantsCare.template.culture_id,
      phases(state) {
        return this.cultureId !== null
          ? state.plantsCare.phases.filter(
              (phase) => phase.culture_id === this.cultureId
            )
          : [];
      },
    }),
    phaseIds() {
      return this.phases.map(({ id }) => id);
    },
    backRoute() {
      return routeNames.defenseTemplates;
    },
    isEditing() {
      return this.$route.name === routeNames.editDefenseTemplate;
    },
    canSubmit() {
      if (!this.canEdit) return false;
      return (
        !(this.isLoading || this.isSaving) &&
        this.plantsCareTemplateFormValid &&
        this.plantsCareWasEdited
      );
    },
    usedInactiveProducts() {
      return [
        ...Object.values(this.plantsCarePestsProductsAsMap),
        ...Object.values(this.plantsCareSTypesProductsAsMap),
      ]
        .filter(
          (product) =>
            product.quantity > 0 && this.phaseIds.includes(product.phase_id)
        )
        .map((product) => this.plantsCareProductsAsMap[product.product_id])
        .filter((product) => product.is_active === 0);
    },
    breadcrumpsArr() {
      return [
        {
          title: this.$t('DefenseTemplateEdit:Title:0'),
          route: this.backRoute,
        },
        {
          title: this.isEditing
            ? this.$t('DefenseTemplateEdit:Title:1')
            : this.$t('DefenseTemplateEdit:Title:2'),
        },
      ];
    },
  },
  methods: {
    saveTemplate() {
      const template = this.$store.state.plantsCare.template;
      const isDefault = Boolean(template?.is_default);
      const wasDefault = Boolean(
        this.$store.state.plantsCare.serverData.template?.is_default
      );

      if (isDefault && !wasDefault) {
        const cultureName = this.$store.state.plantsCare.cultures.find(
          ({ id }) => id === this.cultureId
        ).name;
        this.$confirm({
          message: `<b>${this.$t(
            'DefenseTemplateEdit:ModalConfirm:Message1'
          )} </br>
          ${this.$t(
            'DefenseTemplateEdit:ModalConfirm:Message2'
          )} &ndash; ${cultureName}, ${template.culture_age} ${this.$t(
            'DefenseTemplateEdit:ModalConfirm:Message3'
          )}, ${staticData.calculationMethods[
            template.calculation_method_id
          ].toLowerCase()}?</b>
          <p style="font-size: 12px"
          >${this.$t('DefenseTemplateEdit:ModalConfirm:Message4')}</p>
          `,
          buttons: {
            left: {
              text: this.$t('DefenseTemplateEdit:ModalConfirm:ButtonConfirm'),
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('DefenseTemplateEdit:ModalConfirm:ButtonCancel'),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.$store
                .dispatch(actionTypes.saveTemplateData)
                .then(this.afterSaveNavifation);
            }
          },
        });
      } else {
        this.$store
          .dispatch(actionTypes.saveTemplateData)
          .then(this.afterSaveNavifation);
      }
    },
    afterSaveNavifation() {
      this.$confirm({
        message:
          this.$t('DefenseTemplateEdit:ModalSave:Message1') +
          '<br/>' +
          this.$t('DefenseTemplateEdit:ModalSave:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseTemplateEdit:ModalSave:ButtonOk'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('DefenseTemplateEdit:ModalSave:ButtonUndo'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          this.nextRouteConfirm = true;
          if (confirm) {
            this.$router.push({ name: routeNames.defenseTemplates });
          } else {
            this.$router.push({
              name: routeNames.editDefenseTemplate,
              params: { id: this.$store.state.plantsCare.lastSavedId },
            });
          }
        },
      });
    },
    deleteTemplate() {
      this.$confirm({
        message: this.$t('DefenseTemplateEdit:ModalDelete:Message'),
        buttons: {
          left: {
            text: this.$t('DefenseTemplateEdit:ModalDelete:ButtonDelete'),
            icon: 'delete',
          },
          right: {
            text: this.$t('DefenseTemplateEdit:ModalDelete:ButtonUndo'),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store
              .dispatch(actionTypes.deleteTemplate)
              .then(() => this.$router.push({ name: this.backRoute }));
          }
        },
      });
    },
    cloneTemplate() {
      let message = this.$t('DefenseTemplateEdit:ModalClone:Message1');
      if (this.plantsCareWasEdited)
        message += '<br>' + this.$t('DefenseTemplateEdit:ModalClone:Message2');
      this.$confirm({
        message,
        buttons: {
          left: {
            text: this.$t('DefenseTemplateEdit:ModalClone:ButtonClone'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('DefenseTemplateEdit:ModalClone:ButtonUndo'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store.commit(mutationTypes.deleteId);
            this.$store.commit(mutationTypes.setDefault, false);
            this.$store.dispatch(actionTypes.saveTemplateData).then(() => {
              this.$awn.info(this.$t('DefenseTemplateEdit:Clone:Message'));
              this.$router.push({
                name: routeNames.editDefenseTemplate,
                params: { id: this.$store.state.plantsCare.lastSavedId },
              });
            });
          }
        },
      });
    },
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to?.params?.skipModal ||
      this.nextRouteConfirm ||
      !this.plantsCareWasEdited ||
      !this.canEdit
    ) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('DefenseTemplateEdit:ModalRoute:Message1') +
          '<br />' +
          this.$t('DefenseTemplateEdit:ModalRoute:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseTemplateEdit:ModalRoute:ButtonOk'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('DefenseTemplateEdit:ModalRoute:ButtonCancel'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
};
</script>
