<!--Translated-->
<template>
  <div class="pageprint" :class="{ 'pageprint--horizontal': horizontal }">
    <LoaderBlock v-if="isLoadig" />
    <template v-else>
      <div class="printbtnswrap">
        <div class="printbtns">
          <Button
            class="marginright--standart"
            :text="$t('UserPrintProgramFertilizer:Buttons:Print')"
            size="h-max"
            color="accent"
            icon="print"
            :is-loading="false"
            :loading-left="false"
            @click="printWindow"
            :disabled="isLoadig"
          />

          <Button
            :text="$t('UserPrintProgramFertilizer:Buttons:Save')"
            size="h-max"
            color="accentlines"
            icon="x"
            :is-loading="false"
            :loading-left="false"
            @click="closeTab"
          />
        </div>
      </div>
      <div class="printcontent printcontent--programfertilizer">
        <!--Колонтитул - верхний-->
        <div id="header" class="cwrap">
          <div class="cblock printtitle">
            <div class="printtitle_logo">
              <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
            </div>
            <div class="printtitle_h1 print-h1">
              {{ $t('UserPrintProgramFertilizer:Title') }}
            </div>
            <div class="printtitle_date print-h2">
              {{ new Date().toLocaleDateString('ru-RU') }}
            </div>
          </div>
        </div>

        <div
          class="cwrap margintop--standart cwrap--internalpadding el--borders-all"
        >
          <div class="chead">
            <div class="chead__line-block">
              <div class="vsvg chead_icon" v-svg="{ name: 'info' }"></div>
              <div class="print-h2">{{ program.name }}</div>
            </div>
          </div>

          <div class="margintop--mini marginbottom--standart print-text">
            <div v-if="program.description">({{ program.description }})</div>
          </div>
          <div class="cblock flex-between print-text">
            <div class="printblock printblock--clientdata">
              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:ClientName') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.client_name || '-' }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:ClientCode') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.client_code || '-' }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:ClientDiscount') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.client_discount || 0 }}%
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:ClientGeo') }}
                </div>
                <div class="printblock_item-data">
                  {{ geoPlainDataAsMap.get(program.geo_id)?.text }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:Zone') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.zone_name || '-' }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:Area') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.place_area }}
                  {{ $t('UserPrintProgramFertilizer:Ha') }}
                </div>
              </div>
            </div>

            <div class="printblock printblock--culture">
              <!-- <div class="printblock_item">
                <div class="printblock_item-key">Категория культуры:</div>
                <div class="printblock_item-data">Эфиромаслечные</div>
              </div> -->

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:Culture') }}
                </div>
                <div class="printblock_item-data">
                  {{ culture?.name ?? '-' }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:CultureAge') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.culture_age || '-' }}
                </div>
              </div>

              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:CountPlantsPerHa') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.count_plants_per_ha }}
                </div>
              </div>
              <div class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:CountPlantsAll') }}
                </div>
                <div class="printblock_item-data">
                  {{ program.count_plants_per_ha * program.place_area }}
                </div>
              </div>

              <div v-if="sorts" class="printblock_item">
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:Sorts') }}
                </div>
                <div class="printblock_item-data">
                  {{ sorts }}
                </div>
              </div>

              <div
                class="printblock_item"
                v-if="currentManager?.full_name || currentManager?.name"
              >
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:Manager') }}
                </div>
                <div class="printblock_item-data">
                  {{ currentManager?.name }} {{ currentManager?.full_name }}
                </div>
              </div>

              <div
                class="printblock_item"
                v-if="currentManagerContacts?.at(0)?.value"
              >
                <div class="printblock_item-key">
                  {{ $t('UserPrintProgramFertilizer:ManagerPhone') }}
                </div>
                <div class="printblock_item-data">
                  {{ currentManagerContacts.at(0).value }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Печать - Табл анализов -->
        <section>
          <!-- Печать - Табл Результаты анализа -->
          <div class="cwrap margintop--standart">
            <table class="table--type3 print-text table--elements">
              <thead>
                <tr class="row print-row-elements">
                  <th rowspan="2" class="tbl-cellproblem">
                    {{ $t('PrintProgramFertilizer:AnalyzesResultHeader') }}
                  </th>
                  <th
                    class="tbl-element"
                    v-for="element in elementsData"
                    :key="element.id"
                  >
                    <div>{{ element.name }}</div>
                  </th>
                </tr>

                <tr class="row print-row-elementsescr">
                  <th
                    class="tbl-element"
                    v-for="element in elementsData"
                    :key="element.id"
                  >
                    <div>
                      {{ getUnitsText(element.id, element.units_id || 1) }}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr class="row">
                  <td class="tbl-cellproduct">
                    {{ $t('PrintProgramFertilizer:AnalyzesResultDataHeader') }}
                  </td>
                  <td v-for="element in elementsData" :key="element.id">
                    {{ element.quantity }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Печать - Табл Потребность-->
          <!--ВАЖНО!!! - Необходим вот этот набор элементов в хеаде, без едениц измерения https://i.imgur.com/8VZ523P.png-->
          <!-- НЕ ЗАБЫТЬ ТО ЖЕ перенести для клиента-->
          <div class="cwrap margintop--standart">
            <table class="table--type3 print-text table--elements">
              <thead>
                <tr class="row print-row-elements">
                  <th class="tbl-cellproblem">
                    {{ $t('UserPrintProgramFertilizer:TableAnalyse:Header') }}
                  </th>
                  <th
                    class="tbl-element"
                    v-for="element in elementsData"
                    :key="element.id"
                  >
                    <div>
                      {{ getOxydeName(element.name) }}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <!-- <tr class="row">
                <td class="tbl-cellproduct">Потребность растений</td>
                <td v-for="element in elementsData" :key="element.id">
                  {{ element.demand }}
                </td>
              </tr>
              <tr class="row">
                <td class="tbl-cellproduct">Потребность с учетом анализа</td>
                <td v-for="element in elementsData" :key="element.id">
                  {{ element.demand }}
                </td>
              </tr> -->
                <tr class="row">
                  <td class="tbl-cellproduct">
                    {{ $t('UserPrintProgramFertilizer:TableAnalyse:Lines:1') }}
                  </td>
                  <td
                    v-for="element in elementsData"
                    :key="element.id"
                    :class="
                      element.demandWithAnalysis >
                      nutritionProductsByElement[element.id]
                        ? 'cell--bad'
                        : 'cell--good'
                    "
                  >
                    {{
                      nutritionProductsByElement[element.id]
                        ? round(nutritionProductsByElement[element.id])
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <!-- Печать - Список периодов -->
          <div class="cwrap">
            <div class="cblock margintop--standart">
              <section class="print-nobreak">
                <div class="chead">
                  <div class="chead__line-block">
                    <div
                      class="vsvg chead_icon"
                      v-svg="{ name: 'calendar' }"
                    ></div>
                    <div class="print-h2">
                      {{ formatDate(program.period_start) }}
                      -
                      {{ formatDate(program.period_end) }}
                    </div>
                  </div>
                </div>
                <div class="margintop--mini marginbottom--mini print-text">
                  {{ program.period_description }}
                </div>
              </section>

              <table class="table--type3 print-text table--period">
                <thead>
                  <tr>
                    <th class="tbl-cellperiod">
                      {{ $t('UserPrintProgramFertilizer:PeriodText') }}
                    </th>
                    <th class="tbl-cellproduct">
                      {{ $t('UserPrintProgramFertilizer:ProductName') }}
                    </th>
                    <th v-if="showManufacturer" class="tbl-cellvendor">
                      {{ $t('UserPrintProgramFertilizer:ProductVendor') }}
                    </th>
                    <th v-if="showCountry" class="tbl-cellcountry">
                      {{ $t('UserPrintProgramFertilizer:ProductCountry') }}
                    </th>
                    <th v-if="showSubstance" class="tbl-cellelement">
                      {{ $t('UserPrintProgramFertilizer:ProductSubstance') }}
                    </th>
                    <th class="tbl-cellmethod">
                      {{ $t('UserPrintProgramFertilizer:ProductMethod') }}
                    </th>
                    <th class="tbl-cellkgtoga">
                      {{ $t('UserPrintProgramFertilizer:KgL') }}<br />
                      {{ $t('UserPrintProgramFertilizer:PerHa') }}
                    </th>
                    <th class="tbl-cellkgtoitem">
                      {{ $t('UserPrintProgramFertilizer:ProductAmount') }}
                    </th>
                    <th class="tbl-cellprice">
                      {{ $t('UserPrintProgramFertilizer:ProductPrice')
                      }}<br />{{
                        $t('UserPrintProgramFertilizer:ProductPriceByKg')
                      }}
                    </th>
                  </tr>
                </thead>

                <tbody
                  class="row"
                  v-for="phase in phasesShrinked"
                  :key="phase.id"
                >
                  <template
                    v-if="
                      phase.id === null ||
                      nutritionPhasesProductsAsMap[phase.id].length === 0
                    "
                  >
                    <tr>
                      <td class="tbl-cellperiod">
                        <span>{{ phase.number }}) </span>
                        <span>
                          {{ formatPeriod(phase.phase_start, phase.phase_end) }}
                        </span>
                      </td>
                      <td :colspan="colspan" style="text-align: center">
                        {{ $t('UserPrintProgramFertilizer:NoProducts') }}
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-for="(product, index) in nutritionPhasesProductsAsMap[
                      phase.id
                    ]"
                    :key="product.id"
                  >
                    <td
                      class="tbl-cellperiod"
                      v-if="index === 0"
                      :rowspan="nutritionPhasesProductsAsMap[phase.id].length"
                    >
                      <span>{{ phase.number }}) </span>
                      <span>
                        {{ formatPeriod(phase.phase_start, phase.phase_end) }}
                      </span>
                    </td>
                    <td class="tbl-cellproduct">
                      {{ product.productRef.name }}
                    </td>
                    <td v-if="showManufacturer" class="tbl-cellvendor">
                      {{ manufacturers[product.productRef.manufacturer_id] }}
                    </td>
                    <td v-if="showCountry" class="tbl-cellcountry">
                      {{
                        countries[product.productRef.manufacturer_country_id]
                      }}
                    </td>
                    <td v-if="showSubstance" class="tbl-cellelement">
                      {{ product.productRef.active_substance }}
                    </td>
                    <td class="tbl-cellmethod">
                      {{ methods[product.method_id] }}
                    </td>
                    <td class="tbl-cellkgtoga">{{ product.count_per_ha }}</td>
                    <td class="tbl-cellkgtoitem">
                      {{ product.count_per_plant }}
                    </td>
                    <td class="tbl-cellprice">
                      {{
                        calcPrice(product).toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Печать - Сумма периодов -->
          <section class="print-nobreak">
            <div
              class="cwrap margintop--standart cwrap--internalpadding el--borders-all"
            >
              <!--TODO: №8 Печать Удобрений Менеджера -->
              <!--TODO: Stas верстка - ГОТОВО 15.01.2025-->
              <!--TODO: Витя Логика цен для УДОБРЕНИЙ У МЕНЕДЖЕРА - ПЕЧАТЬ!!!-->
              <!--TODO: Витя ЗАКОМЕНЧЕНЫ УСЛОВИЯ ИЗ ЗАЩИТЫ У МЕНЕДЖЕРА, нужно поменять на соответствующее условие тут-->
              <!--TODO: САША Перевод-->

              <template v-if="pricePerHaDiscount > 0">
                <div class="cblock cblock--totalprice2025print">
                  <div class="cblock__line flex-between">
                    <div class="print-h1">
                      <span>{{ $t('PrintProgramDefense:PhasesAll') }} </span>
                      <span> {{ phases.length }}</span>
                    </div>

                    <div class="cblock__line-block print-h1">
                      <span>{{ $t('ProgramTables:Sum') }}</span>
                      <span
                        >{{
                          $t('Vuex:plantsCare:CalculationMethodsShort:3')
                        }}:</span
                      >
                      <span class="this-price"
                        >₾ {{ formatNumber(pricePerHaNew) }}</span
                      >
                    </div>
                  </div>

                  <div class="cblock__line">
                    <div class="cblock__line-block print-h1">
                      <span>{{ $t('ProgramTables:DiscountSum') }}</span>

                      <span
                        >{{
                          $t('Vuex:plantsCare:CalculationMethodsShort:3')
                        }}:</span
                      >

                      <span class="this-price"
                        >₾ {{ formatNumber(pricePerHaDiscount) }}</span
                      >
                    </div>
                  </div>
                  <div class="cblock__line">
                    <div
                      class="cblock__line-block print-h1 uni_text-accentcolor uni_text-bold"
                    >
                      <span>{{ $t('ProgramTables:DiscountSumFinal') }}</span>
                      <span
                        >{{
                          $t('Vuex:plantsCare:CalculationMethodsShort:3')
                        }}:</span
                      >

                      <span class="this-price"
                        >₾ {{ formatNumber(pricePerHaDiscounted) }}</span
                      >
                    </div>
                  </div>

                  <div class="cblock__line uni_text-graysmalltext">
                    <div class="">* {{ $t('ProgramTables:Tooltip.Text') }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="cblock cblock--totalprice2025print">
                  <div class="cblock__line flex-between">
                    <div class="print-h1">
                      <span>{{ $t('PrintProgramDefense:PhasesAll') }} </span>
                      <span> {{ phases.length }}</span>
                    </div>

                    <div
                      class="cblock__line-block print-h1 uni_text-accentcolor uni_text-bold"
                    >
                      <span>{{ $t('ProgramTables:DiscountSumFinal') }}</span>
                      <span
                        >{{
                          $t('Vuex:plantsCare:CalculationMethodsShort:3')
                        }}:</span
                      >
                      <span class="this-price"
                        >₾ {{ formatNumber(pricePerHaNew) }}</span
                      >
                    </div>
                  </div>

                  <div class="cblock__line uni_text-graysmalltext">
                    <div class="">* {{ $t('ProgramTables:Tooltip.Text') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </section>
        </section>

        <section class="print-nobreak">
          <!-- Печать - Таблица Итог - стимуляторы-->
          <div class="cwrap print-nobreak margintop--standart">
            <div class="chead marginbottom--micro">
              <div class="chead__line-block marginbottom--micro">
                <div
                  class="vsvg chead_icon"
                  v-svg="{ name: 'fertilizer' }"
                ></div>
                <div class="print-h2">
                  {{ $t('UserPrintProgramFertilizer:ResultTable:Title') }}
                </div>
              </div>
            </div>

            <table class="table--type3 print-text table--paycheck">
              <thead>
                <tr>
                  <th class="tbl-cellproduct">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:0') }}
                  </th>
                  <th class="tbl-cellpaycheck">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:1') }}
                  </th>
                  <th class="tbl-cellpaycheck">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:2') }}
                    / 1 {{ $t('UserPrintProgramFertilizer:Ha') }}
                  </th>
                  <th class="tbl-cellpaycheck">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:2') }}
                    / {{ program.place_area }}
                    {{ $t('UserPrintProgramFertilizer:Ha') }}
                  </th>
                  <th class="tbl-cellpaycheck">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:4') }}
                  </th>
                  <th class="tbl-cellpaycheck">
                    {{ $t('UserPrintProgramFertilizer:ResultTable:Headers:5') }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="row in nutritionTotalProductList"
                  :key="row.productLink.id"
                  class="row"
                >
                  <td>{{ row.productLink.name }}</td>
                  <td>{{ row.productLink.picking }}</td>
                  <td>{{ formatNumber(row.totalPerHa) }}</td>
                  <td>{{ formatNumber(row.totalForArea) }}</td>
                  <td>
                    {{ formatNumber(row.packages) }}
                  </td>
                  <td>{{ row.packagesInt }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Печать - Итог цен и дат -->
          <section class="print-nobreak">
            <div class="cwrap print-nobreak margintop--standart">
              <div class="chead">
                <div class="chead__line-block">
                  <div
                    class="vsvg chead_icon"
                    v-svg="{ name: 'paycheck' }"
                  ></div>
                  <div class="print-h2">
                    {{ $t('UserPrintProgramFertilizer:ResultTitle') }}
                  </div>
                </div>
              </div>
              <div class="cblock flex-between margintop--mini print-text">
                <div class="printblock printblock--dates">
                  <div class="printblock_item">
                    <div class="printblock_item-key">
                      {{ $t('UserPrintProgramFertilizer:ResultDateCreate') }}
                    </div>
                    <div class="printblock_item-data">{{ createdAt }}</div>
                  </div>
                  <div class="printblock_item">
                    <div class="printblock_item-key">
                      {{ $t('UserPrintProgramFertilizer:ResultDateEdit') }}
                    </div>
                    <div class="printblock_item-data">{{ updatedAt }}</div>
                  </div>
                  <!-- <div class="printblock_item">
                    <div class="printblock_item-key">
                      Дата фиксирования цены:
                    </div>
                    <div class="printblock_item-data">13:45 22.04.2022</div>
                  </div> -->
                </div>
                <div class="printblock printblock--prices">
                  <div class="printblock_item">
                    <div class="printblock_item-key">
                      {{ $t('UserPrintProgramFertilizer:ResultSumDollar') }}
                    </div>
                    <div class="printblock_item-data">
                      {{
                        totalPriceUSD ? '$' + formatNumber(totalPriceUSD) : '-'
                      }}
                    </div>
                  </div>
                  <div class="printblock_item">
                    <div class="printblock_item-key">
                      {{ $t('UserPrintProgramFertilizer:ResultSumEuro') }}
                    </div>
                    <div class="printblock_item-data">
                      {{
                        totalPriceEU ? '€' + formatNumber(totalPriceEU) : '-'
                      }}
                    </div>
                  </div>
                  <div class="printblock_item">
                    <div class="printblock_item-key">
                      {{ $t('UserPrintProgramFertilizer:ResultSumGel') }}
                    </div>
                    <div class="printblock_item-data">
                      {{
                        totalPriceRounded ? '₾' + formatNumber(totalPrice) : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="cwrap print-nobreak margintop--mini cwrap--internalpadding el--borders-all el--borders-coloraccent"
            >
              <div class="print-total cblock flex-between">
                <div class="print-h1">
                  <span>
                    {{ $t('UserPrintProgramFertilizer:ResultDiscount') }} -
                  </span>
                  <span>
                    {{ program?.client_discount || 0 }}% (₾
                    {{
                      formatNumber(
                        (totalPriceRounded / 100) *
                          (program?.client_discount || 0)
                      )
                    }})
                  </span>
                </div>

                <div class="print-h1">
                  <span
                    >{{ $t('UserPrintProgramFertilizer:ResultSum') }} -
                  </span>
                  <span>
                    ₾
                    {{
                      formatNumber(
                        totalPriceRounded -
                          (totalPrice / 100) * (program?.client_discount || 0)
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!--Колонтитул - нижний-->
            <div id="footer" class="margintop--standart cwrap">
              <div class="cblock printtitle">
                <div class="printtitle_logo">
                  <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
                </div>
                <div class="printtitle_contact">
                  <div class="vsvg" v-svg="{ name: 'contactwebsite' }"></div>
                  <div>cartlis.ge</div>
                </div>
                <div class="printtitle_contact">
                  <div class="vsvg" v-svg="{ name: 'contactphone' }"></div>
                  <div>+995 32 244 55 42</div>
                </div>
                <div class="printtitle_contact">
                  <div class="vsvg" v-svg="{ name: 'contactmail' }"></div>
                  <div>info@cartlis.ge</div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { ka } from 'date-fns/locale';
import { mapState, mapGetters } from 'vuex';
import { calcDemandWithAnalysis } from '@/pages/programs/fertilizers/analysesData';
import { units } from '../elementMeasureUnits.js';
import { getOxydeName } from '@/pages/programs/fertilizers/analysesData.js';

import {
  actionTypes as nutritionActionTypes,
  mutationTypes as nutritionMutationTypes,
  staticData,
} from '@/store/modules/nutritionPrograms';

import LoaderBlock from '@/components/blocks/LoaderBlock';
import Button from '@/components/views/Button';

export default {
  name: 'PrintProgramFertilizer',
  components: { Button, LoaderBlock },
  mounted() {
    this.$store.commit(nutritionMutationTypes.resetState);
    this.$store.dispatch(
      nutritionActionTypes.fetchProgram,
      this.$route.params.id
    );
  },
  computed: {
    ...mapGetters([
      'geoPlainDataAsMap',
      'nutritionElementsValuesAsMap',
      'nutritionProductsByElement',
      'nutritionProductsEntriesAsMap',
      'nutritionPhasesProductsAsMap',
      'nutritionTotalProductList',
    ]),
    ...mapState({
      isLoadig: (state) =>
        state.nutrition.isLoading || state.nutrition.isCultureFetching,
      program: (state) => state.nutrition.program,
      culture(state) {
        return state.nutrition.cultures.find(
          ({ id }) => id === this.program.culture_id
        );
      },
      sorts(state) {
        return state.nutrition.programCulturesSorts
          .map(({ sort_id }) =>
            state.nutrition.culturesSorts.find(({ id }) => id === sort_id)
          )
          .map((sort) => sort.name)
          .join(', ');
      },
      elements: (state) => state.nutrition.elements,
      phases: (state) => state.nutrition.programPhases,
      manufacturers: (state) =>
        Object.fromEntries(
          state.nutrition.manufacturers.map((m) => [m.id, m.name])
        ),
      countries: (state) =>
        Object.fromEntries(
          state.nutrition.manufacturersCountries.map((m) => [m.id, m.name])
        ),
      createdAt: (state) =>
        state.nutrition.program.date_create
          ? format(
              new Date(state.nutrition.program.date_create),
              'dd.MM.yyyy HH:MM'
            )
          : '',
      updatedAt: (state) =>
        state.nutrition.program.date_update
          ? format(
              new Date(state.nutrition.program.date_update),
              'dd.MM.yyyy HH:MM'
            )
          : '',
      currentManager: (state) => state.nutrition.currentManager,
      currentManagerContacts: (state) => state.nutrition.currentManagerContacts,
    }),
    phasesShrinked() {
      const result = [];
      let i = 0;
      while (i < this.phases.length) {
        const slice = this.phases.slice(i, i + 4);
        const next4PhasesHasNoProducts =
          slice.length === 4 &&
          slice.every(
            (phase) =>
              phase && this.nutritionPhasesProductsAsMap[phase.id]?.length === 0
          );
        i += 1;
        if (next4PhasesHasNoProducts) {
          result.push({
            id: null,
            phase_start: this.phases[i - 1].phase_start,
            phase_end: this.phases[i + 2]?.phase_end,
            number: `${i}-${i + 3}`,
          });
          i += 3;
        } else {
          result.push({ ...this.phases[i - 1], number: i });
        }
      }
      return result;
    },
    methods() {
      return Object.fromEntries(staticData.methods.map((m) => [m.id, m.name]));
    },
    elementsData() {
      return this.elements.map((element) => {
        const ref = this.nutritionElementsValuesAsMap[element.id];
        return {
          ...element,
          ...ref,
          demandWithAnalysis: calcDemandWithAnalysis(
            element.id,
            ref.quantityMgKg,
            ref.demand
          ),
        };
      });
    },
    totalPrice() {
      return this.nutritionTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packages,
        0
      );
    },
    totalPriceRounded() {
      return this.nutritionTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packagesInt,
        0
      );
    },
    pricePerHaNew() {
      return this.totalPriceRounded / this.program.place_area;
    },
    pricePerHaDiscount() {
      return this.pricePerHaNew * (this.program.client_discount / 100);
    },
    pricePerHaDiscounted() {
      return this.pricePerHaNew - this.pricePerHaDiscount;
    },
    totalPriceDiscounted() {
      return (
        this.totalPriceRounded -
        (this.totalPriceRounded / 100) * (this.program.client_discount || 0)
      );
    },
    totalPriceUSD() {
      return this.program.rate_dollar
        ? this.totalPriceDiscounted / this.program.rate_dollar
        : null;
    },
    totalPriceEU() {
      return this.program.rate_euro
        ? this.totalPriceDiscounted / this.program.rate_euro
        : null;
    },
    horizontal() {
      return [
        this.$route.query.substance,
        this.$route.query.manufacturer,
        this.$route.query.country,
      ].some((e) => e === 'true');
    },
    colspan() {
      return (
        [
          this.$route.query.substance,
          this.$route.query.manufacturer,
          this.$route.query.country,
        ].filter((e) => e === 'true').length + 5
      );
    },
    showManufacturer() {
      return this.$route.query.manufacturer === 'true';
    },
    showSubstance() {
      return false;
    },
    showCountry() {
      return this.$route.query.country === 'true';
    },
  },
  methods: {
    getOxydeName,
    printWindow: function () {
      window.print();
    },
    closeTab() {
      window.close();
    },
    formatDate(ts) {
      return format(new Date(ts), 'd MMMM', { locale: ka });
    },
    formatPeriod(start, end) {
      return `${format(new Date(start), 'dd.MM.yyyy')} - ${format(
        new Date(end),
        'dd.MM.yyyy'
      )}`;
    },
    calcPrice(product) {
      if (!product.count_per_ha || !product.productRef.picking) return 0;
      return (
        product.count_per_ha *
        (product.productRef.price / product.productRef.picking)
      );
    },
    formatNumber(num) {
      return num.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    round(num) {
      return Math.round(num * 10_000) / 10_000;
    },
    getUnitsText(index, id) {
      // pH
      if (index === 13) return '';
      // EC
      if (index === 14) return id === 1 ? 'μS/cm' : 'ms/cm';
      return units.find((u) => u.id === id)?.text;
    },
  },
  watch: {
    culture() {
      if (this.culture) {
        this.$store.dispatch(
          nutritionActionTypes.fetchCultureData,
          this.culture.id
        );
      } else {
        this.$store.commit(nutritionMutationTypes.cultureDataFetchSuccess, []);
      }
    },
  },
  beforeMount() {
    const css = `@media print {
    @page {
    size: ${this.horizontal ? 'landscape' : 'portrait'};
  }
`;
    const style = document.createElement('style');
    style.textContent = css;
    style.type = 'text/css';
    document.head.appendChild(style);
  },
};
</script>
