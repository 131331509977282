<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
      <Breadcrumps :data="[{ title: 'Анализ почвы' }]" />
    </div>

    <!-- П.1 Анализ почвы-->
    <div
      class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
    >
      <div class="artclhead">
        <div class="artclhead-num marginright--micro">01.</div>
        <div class="artclhead-text">აიღეთ ნიმუში</div>
      </div>

      <div class="artclblock">
        <div class="artclblock-media">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/wHMIgZXDA1o"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <!--Блок Файл ПДФ-->

        <div class="margintop--standart">
          <ComponentFile
            text="Инструкции по отбору проб почвы"
            icon="filepdf"
            link="https://drive.google.com/file/d/142YtqdewDdly9FjVqS1zqcznphaaTtS_/view?usp=sharing"
          />
        </div>
      </div>
    </div>

    <!-- П.2 Маркировка почвы -->
    <div
      class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
    >
      <div class="artclhead">
        <div class="artclhead-num marginright--micro">02.</div>
        <div class="artclhead-text">ეტიკეტების ნიმუშები</div>
      </div>

      <div class="artclblock">
        <!--Блок Файл ПДФ-->

        <div class="margintop--standart">
          <ComponentFile
            text="Инструкции по маркировки проб почвы"
            icon="filepdf"
            link="https://drive.google.com/file/d/16wML5-8rhIzN89E5dWjv13qVlkTi06Kv/view?usp=sharing"
          />
        </div>
      </div>
    </div>

    <!--    &lt;!&ndash;п.1 забор анализов&ndash;&gt;-->
    <!--    <div class="cwrap articlewrap el&#45;&#45;borders-all el&#45;&#45;rounded-all">-->

    <!--      <div class="cblock guideblock">-->

    <!--        <div class="article-head">-->
    <!--          <span class="guideblock-headnum">-->
    <!--            01.-->
    <!--          </span>-->
    <!--          <span>-->
    <!--            აიღეთ ნიმუში-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div class="article-text margintop&#45;&#45;standart">-->

    <!--          <p class="bold">-->
    <!--            ფოთლის ნიმუშის აღების ოპტიმალური დრო-->
    <!--          </p>-->
    <!--          <p>-->
    <!--            ფოთლის აგროქიმიურ გამოკვლევაში დიდი მნიშვნელობა ენიჭება ფოთლის ნიმუშის აღების-->
    <!--            დროს, რადგან საკვები ელემენტების კონცენტრაცია ფოთლებში მუდმივად იცვლება. თუმცა,-->
    <!--            ფოთლის მინერალური შედგენილობა განსაკუთრებით სტაბილურია მისი აღმოცენებიდან-->
    <!--            მეოთხე და მეექვსე თვეს შორის-->
    <!--          </p>-->

    <!--          <p>-->
    <!--            ფოთლის ანალიზისთვის საუკეთესო დროა 4-6 თვის ასაკის საგაზაფხულო-->
    <!--            ფოთლის ანალიზი, ამ დროს ფოთოლს უკვე დასრულებული აქვს ზომაში ზრდა.-->
    <!--            საქართველოს კლიმატური პირობების გათვალისწინებით ფოთლის ეს ასაკი-->
    <!--            ემთხვევა ივლისი, აგვისტოს პერიოდს-->
    <!--          </p>-->

    <!--          <div class="marginbottom&#45;&#45;mini">-->
    <!--            <p class="bold">-->
    <!--              ფოთლის ნიმუშის აღების ინსტრუქცია:-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              1. ერთი ფოთლის ნიმუში უნდა წარმოადგენდეს არეალს, რომლის ფართობი-->
    <!--              არ უნდა აღემატებოდეს 20ჰა-ს. ნიმუშის ასაღებად უნდა შეირჩეს ხეები,-->
    <!--              რომლებიც იქნებიან დამახასიათებელი საკვლევი ფართობისთვის-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              2. ერთი ფოთლის ნიმუში უნდა წარმოადგენდეს არეალს, რომლის ფართობი-->
    <!--              არ უნდა აღემატებოდეს 20ჰა-ს. ნიმუშის ასაღებად უნდა შეირჩეს ხეები,-->
    <!--              რომლებიც იქნებიან დამახასიათებელი საკვლევი ფართობისთვის-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              3. თავი აარიდეთ ზრდა დაუსრულებელი ფოთლების აღებას, რადგან მათი-->
    <!--              ქიმიური შემადგენლობა მუდმივად იცვლება-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              4. ნიმუში არ აიღოთ დაზიანებული, დაავადებული ხეებიდან. აგრეთვე-->
    <!--              ისეთი ხეებიდან, რომლებიც მდებარეობს ნაკვეთის კიდეებში, ბოლო-->
    <!--              რიგებში, რადგან ისინი შეიძლება დაბინძურებულები იყვნენ მტვრისა და-->
    <!--              ნიადაგის ნაწილაკებისგან-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              5. საკვლევ ტერიტორიაზე შერჩეული ხიდან მაკრატლის საშუალებით-->
    <!--              მოჭერით ფოთოლი. ფოთოლი ვიზუალურად უნდა იყოს ჯანმრთელი,-->
    <!--              ზრდა დამთვრებული, მავნებლების დაზიანების გარეშე. ერთი ხიდან-->
    <!--              უნდა ავიღოთ დაახლოებით 4 ფოთოლი , რომლებიც უნდა-->
    <!--              მდებარეობდნენ ხის ჩრდილოეთ, სამხრეთ, აღმოსავლეთ და დასავლეთ-->
    <!--              მხარეს-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              6. მოჭრილი ფოთლები უნდა მოვათავსოთ ნიმუშის ასაღებ პაკეტში.-->
    <!--              სასურველია ნიმუშის ასაღები პაკეტი დამზადებული იყოს ქაღალდიდან.-->
    <!--              სხვა მასალის გამოყენების შემთხვევაში აუცილებელია ლაბორატორიაში-->
    <!--              მოწოდებამდე უზრუნველყოთ ფოთლის შერეული ნიმუშის აერაცია-->
    <!--            </p>-->

    <!--            <p>-->
    <!--              7. პაკეტს გავუკეთოთ ეტიკეტი, სადაც მითითებული იქნება ნიმუშის აღების თარიღი,-->
    <!--              ადგილი, კულტურა და დამკვეთის შესახებ პირადი ინფორმაცია-->
    <!--            </p>-->
    <!--          </div>-->

    <!--        </div>-->

    <!--      </div>-->

    <!--    </div>-->
    <!--    -->
    <!--    &lt;!&ndash;п.2 маркировка анализов&ndash;&gt;-->
    <!--    <div class="cwrap articlewrap el&#45;&#45;borders-all el&#45;&#45;rounded-all margintop&#45;&#45;standart">-->

    <!--      <div class="cblock guideblock">-->

    <!--        <div class="article-head">-->
    <!--          <span class="guideblock-headnum">-->
    <!--            02.-->
    <!--          </span>-->
    <!--          <span>-->
    <!--            ეტიკეტების ნიმუშები-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div class="article-text margintop&#45;&#45;standart">-->

    <!--          <p>-->

    <!--            ნიმუშების აღების მნიშვნელოვანი ეტაპია მისი სწორი ეტიკეტირება, რათა თავიდან ავიცილოთ ნიმუშების არევა.-->
    <!--            ნიმუშის აღების შემდგომ ქაღალდის შეფუთვაზე უნდა გაკეთდეს ეტიკეტი,-->
    <!--            რომელზეც აუცილებლად უნდა დავიტანოთ შემდეგი ინფორმაცია:-->

    <!--          </p>-->

    <!--          <ol class="">-->
    <!--            <li>-->
    <!--              სახელი, გვარი-->
    <!--            </li>-->

    <!--            <li>-->
    <!--              სახელი, გვარი-->
    <!--            </li>-->

    <!--            <li>-->
    <!--              ნიმუშის დასახელება (მნიშვნელოვანია იმ შემთხვევაში, თუ კლიენტს მოაქვს-->
    <!--              რამდენიმე ნიადაგის ნიმუში, კლიენტი ნიმუშს ანიჭებს დასახელებას რისი-->
    <!--              საშუალებითაც მოხდება ნიმუშების/ანალიზის შედეგების ერთმანეთისგან გარჩევა.-->
    <!--              იდენტიფიკაციისთვის შეიძლება მიეთითოს წყლის ნიმუშის წყარო)-->
    <!--            </li>-->
    <!--          </ol>-->

    <!--        </div>-->

    <!--      </div>-->

    <!--    </div>-->

    <!--Блок Гео-->
    <GuideAnalysisGeo />

    <!--Блок Отслеживайте-->
    <GuideAnalysisTrack />

    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from '@/components/views/LocationSelect';
import Button from '@/components/views/Button';
import GuideAnalysisGeo from '@/pages/userproject/userArticles/Components/GuideAnalysisGeo';
import GuideAnalysisTrack from '@/pages/userproject/userArticles/Components/GuideAnalysisTrack';
import ComponentFile from '@/pages/userproject/userArticles/Components/ComponentFile';
import routeNames from '@/router/routeNames';

export default {
  name: 'UserAnalysesInstruction2',
  components: {
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Все нализы', route: routeNames.userAnalyzes },
        { title: 'Инструкция - Анализ почвы' },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
