<template>
  <div class="sidemenu">
    <div class="sidemenu-wrap">
      <div class="sidemenu-logo">
        <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
      </div>

      <div
        class="sidemenu-item-wrap"
        v-for="entry in entries"
        :key="entry.name"
      >
        <template v-if="entry.routes?.length > 1">
          <div
            class="sidemenu-item"
            :class="{
              'sidemenu-item--active': openedEntry === entry.name,
            }"
            @click="
              openedEntry = openedEntry === entry.name ? null : entry.name
            "
          >
            <div class="sidemenu-item_icon" v-svg="{ name: entry.icon }" />
            <div class="sidemenu-item_name">{{ entry.name }}</div>
            <div class="sidemenu-item_btnshow">
              <div
                :key="openedEntry === entry.name"
                v-svg="{
                  name: openedEntry === entry.name ? 'arrowup' : 'arrowdown',
                }"
              />
            </div>
          </div>

          <div class="sidemenu-subwrap" v-show="openedEntry === entry.name">
            <router-link
              v-for="route in entry.routes"
              :key="route.route"
              :to="{ name: route.route, params: { fromMenu: true } }"
              class="sidemenu-subitem"
              active-class="sidemenu-subitem--active"
            >
              <div class="sidemenu-subitem_name">{{ route.name }}</div>
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="sidemenu-item-wrap" v-if="entry.event">
            <div class="sidemenu-item" @click="$emit(entry.event)">
              <div class="sidemenu-item_icon" v-svg="{ name: entry.icon }" />
              <div class="sidemenu-item_name">{{ entry.name }}</div>
            </div>
          </div>
          <router-link
            v-else
            :to="{ name: entry.routes[0].route, params: { fromMenu: true } }"
            class="sidemenu-item"
            active-class="sidemenu-item--active"
            @click.native="openedEntry = null"
          >
            <div class="sidemenu-item_icon" v-svg="{ name: entry.icon }"></div>
            <div class="sidemenu-item_name">{{ entry.routes[0].name }}</div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    entries: { type: Array, required: true },
  },
  data() {
    return {
      openedEntry: null,
    };
  },
  watch: {
    entries: {
      handler(entries) {
        const routes = this.$router.getRoutes();
        const [root] = this.$route.path.split('/').filter(Boolean);
        const parentRoute = routes.find(({ path }) => path === `/${root}`);
        const entry = entries.find(({ routes }) =>
          routes?.some(({ route }) => route === parentRoute.name)
        );
        if (entry?.routes.length > 1) {
          this.openedEntry = entry.name;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

a,
a > * {
  all: unset;
}

/* SCROLLBARS */
::-webkit-scrollbar {
  width: 10px;
  //margin-left: 10px;
}
::-webkit-scrollbar-track {
  background: $colorGrayLighter;
  border-radius: $borderRadiusStandart;
}
::-webkit-scrollbar-thumb {
  background: $colorAccentLight;
  border-radius: $borderRadiusStandart;
}
::-webkit-scrollbar-thumb:hover {
  background: $colorAccentLight;
}

.sidemenu {
  //margin-bottom: 20vh;
  user-select: none;
  //width: 292px;
  position: fixed;
  top: 0;
  height: 100vh;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;

  &:hover {
    overflow-y: visible;
  }
}

.sidemenu-wrap {
  //position: absolute;
  margin-bottom: $marginStandart;
  margin-right: 14px;
}

.sidemenu-logo {
  max-height: $heightStandart;
  margin-top: $marginStandart;

  & img {
    max-height: $heightStandart;
    transform: translateY(-$borderButton) translateX($borderButton * 2);
    //margin-bottom: 12px;
  }
}

.sidemenu-item {
  cursor: pointer;
  position: relative;
  z-index: 100;
  width: 292px; //calc(316px * 74 / 80);// оригинал 316px;
  height: $heightStandart;
  border: $borderButton solid $colorLinesAccentLight;
  box-sizing: border-box;
  border-radius: $borderRadiusStandart; // оригинал 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-top: $marginStandart; // оригинал 40px;
}
.sidemenu-item:hover {
  & .sidemenu-item_icon {
    background-color: $colorAccent;
    border: $borderButton solid $colorLinesAccent;
  }

  & .sidemenu_svgicon {
    fill: $colorTextWhite;
  }

  & .sidemenu-item_name {
    color: $colorTextAccent;
  }
}
.sidemenu-item--active {
  & .sidemenu_svgicon {
    fill: $colorTextWhite;
  }
  & .sidemenu-item_icon {
    background-color: $colorAccent;
    border: $borderButton solid $colorLinesAccent;
  }
  & .sidemenu-item_name {
    color: $colorTextAccent;
  }
  // & .sidemenu-item_btnshow {
  //   transform-origin: 50% 58%;
  //   transform: rotate(180deg);
  // }
}

.sidemenu-item_icon {
  position: absolute;
  left: -$borderButton;
  top: -$borderButton;
  width: $heightStandart;
  height: $heightStandart;
  background-color: transparent;
  box-sizing: border-box;
  border: $borderButton solid $colorLinesAccentLight;
  border-radius: $borderRadiusStandart; // оригинал 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.sidemenu_svgicon {
  fill: $colorTextAccent;
}
.sidemenu-item_name {
  color: $colorTextGray;
  font-size: $fontsizeStandart; // оригинал 16px;
  line-height: $lineFontsizeStandart;
  font-weight: $fontNormal;
  margin-left: $heightStandart + $marginMicro;
}
.sidemenu-item_btnshow {
  position: absolute;
  right: $marginMicro; // оригинал 20px;

  width: $iconSizeMicro; //не может основной класс перезаписать
  height: $iconSizeMicro; //не может основной класс перезаписать
  max-width: $iconSizeMicro; //т.к. не дает основной класс перезаписать
  max-height: $iconSizeMicro; //т.к. не дает основной класс перезаписать

  & > div {
    display: flex;
    align-items: center;
  }

  & svg {
    width: 100%;
    height: 100%;

    & .svgfill {
      fill: $colorAccent;
    }
  }
}

.sidemenu-subitem {
  cursor: pointer;
  z-index: 10;
  width: 219px; //calc((237px * 74  / 80));// оригинал 237px;
  height: $heightMedium; // оригинал 60px;
  display: flex;
  align-items: center;
  margin-top: -$borderButton;
  margin-left: -$borderButton;
  background-color: transparent;
  box-sizing: border-box;
  border: $borderButton solid $colorLinesAccentLight;
}

svg {
  width: 100%;
}

.sidemenu-subitem:hover {
  background-color: $colorAccent;

  & .sidemenu-subitem_name {
    color: $colorTextWhite;
  }
}
.sidemenu-subitem--active {
  background-color: $colorAccent;

  & .sidemenu-subitem_name {
    color: $colorTextWhite;
  }

  & .sidemenu-item {
    & .sidemenu_svgicon {
      fill: $colorTextWhite;
    }
    & .sidemenu-item_icon {
      background-color: $colorAccent;
    }
    & .sidemenu-item_name {
      color: $colorTextAccent;
    }
  }
}

.sidemenu-subitem:first-child {
  padding-top: 10px;
  margin-top: -9px;
  height: calc(#{$heightMedium} + 10px); // оригинал 70px;
  border-top: $borderButton solid transparent;
}
.sidemenu-subitem:last-child {
  border-bottom-left-radius: $borderRadiusStandart; // оригинал 10px;
  border-bottom-right-radius: $borderRadiusStandart; // оригинал 10px;
}

.sidemenu-subwrap {
  margin-left: $heightStandart; // оригинал 80px;
}
.sidemenu-subitem_name {
  margin-left: $marginMicro; // оригинал 20px;
  color: $colorTextAccent;
  font-size: $fontsizeStandart; // оригинал 16px;
  line-height: $lineFontsizeStandart;
}

@media screen and (max-width: 1800px) {
  .sidemenu-wrap {
    margin-right: 10px;
  }

  .sidemenu-logo {
    max-height: $heightStandart-k1600;
    margin-top: $marginStandart-k1600;

    & img {
      max-height: $heightStandart-k1600;
    }
  }

  .sidemenu-item {
    width: 260px; //292px * $k1600;
    height: $heightStandart-k1600;
    border-radius: $borderRadiusStandart-k1600; // оригинал 10px;
    margin-top: $marginStandart-k1600; // оригинал 40px;
  }

  .sidemenu-item_icon {
    left: -$borderButton;
    top: -$borderButton;
    width: $heightStandart-k1600;
    height: $heightStandart-k1600;
    border-radius: $borderRadiusStandart-k1600; // оригинал 10px;
  }

  .sidemenu-item_name {
    font-size: $fontsizeStandart-k1600; // оригинал 16px;
    line-height: $lineFontsizeStandart-k1600;
    margin-left: $heightStandart-k1600 + $marginMicro-k1600;
  }
  .sidemenu-item_btnshow {
    right: $marginMicro-k1600; // оригинал 20px;

    width: $iconSizeMicro-k1600; //не может основной класс перезаписать
    height: $iconSizeMicro-k1600; //не может основной класс перезаписать
    max-width: $iconSizeMicro-k1600; //т.к. не дает основной класс перезаписать
    max-height: $iconSizeMicro-k1600; //т.к. не дает основной класс перезаписать

    & > div {
      display: flex;
      align-items: center;
    }

    & svg {
      width: 100%;
      height: 100%;

      & .svgfill {
        fill: $colorAccent;
      }
    }
  }

  .sidemenu-subitem {
    width: 201px; //219px * $k1600;//calc((237px * 74  / 80));// оригинал 237px;
    height: $heightMedium-k1600; // оригинал 60px;
  }

  svg {
    width: 80%;
  }

  .sidemenu-subitem:first-child {
    padding-top: 9px;
    margin-top: -10px;
    height: calc(#{$heightMedium-k1600} + 9px); // оригинал 70px;
    border-top: $borderButton solid transparent;
  }
  .sidemenu-subitem:last-child {
    border-bottom-left-radius: $borderRadiusStandart-k1600; // оригинал 10px;
    border-bottom-right-radius: $borderRadiusStandart-k1600; // оригинал 10px;
  }

  .sidemenu-subwrap {
    margin-left: $heightStandart-k1600; // оригинал 80px;
  }
  .sidemenu-subitem_name {
    margin-left: $marginMicro-k1600; // оригинал 20px;
    font-size: $fontsizeStandart-k1600; // оригинал 16px;
    line-height: $lineFontsizeStandart-k1600;
  }
}

@media screen and (max-width: 1420px) {
  .sidemenu-wrap {
    margin-right: 8px;
  }
  .sidemenu-logo {
    max-height: $heightStandart-k1360;
    margin-top: $marginStandart-k1360;

    & img {
      max-height: $heightStandart-k1360;
    }
  }

  .sidemenu-item {
    width: 232px; //292px * $k1600;
    height: $heightStandart-k1360;
    border-radius: $borderRadiusStandart-k1360; // оригинал 10px;
    margin-top: $marginStandart-k1360; // оригинал 40px;
  }

  .sidemenu-item_icon {
    left: -$borderButton;
    top: -$borderButton;
    width: $heightStandart-k1360;
    height: $heightStandart-k1360;
    border-radius: $borderRadiusStandart-k1360; // оригинал 10px;
  }

  .sidemenu-item_name {
    font-size: $fontsizeStandart-k1360; // оригинал 16px;
    line-height: $lineFontsizeStandart-k1360;
    margin-left: $heightStandart-k1360 + $marginMicro-k1360;
  }
  .sidemenu-item_btnshow {
    right: $marginMicro-k1360; // оригинал 20px;

    width: $iconSizeMicro-k1360; //не может основной класс перезаписать
    height: $iconSizeMicro-k1360; //не может основной класс перезаписать
    max-width: $iconSizeMicro-k1360; //т.к. не дает основной класс перезаписать
    max-height: $iconSizeMicro-k1360; //т.к. не дает основной класс перезаписать

    & > div {
      display: flex;
      align-items: center;
    }

    & svg {
      width: 100%;
      height: 100%;

      & .svgfill {
        fill: $colorAccent;
      }
    }
  }

  .sidemenu-subitem {
    width: 181px; //219px * $k1600;//calc((237px * 74  / 80));// оригинал 237px;
    height: $heightMedium-k1360; // оригинал 60px;
  }

  svg {
    width: 70%;
  }

  .sidemenu-subitem:first-child {
    padding-top: 9px;
    margin-top: -10px;
    height: calc(#{$heightMedium-k1360} + 9px); // оригинал 70px;
    border-top: $borderButton solid transparent;
  }
  .sidemenu-subitem:last-child {
    border-bottom-left-radius: $borderRadiusStandart-k1360; // оригинал 10px;
    border-bottom-right-radius: $borderRadiusStandart-k1360; // оригинал 10px;
  }

  .sidemenu-subwrap {
    margin-left: $heightStandart-k1360; // оригинал 80px;
  }
  .sidemenu-subitem_name {
    margin-left: $marginMicro-k1360; // оригинал 20px;
    font-size: $fontsizeStandart-k1360; // оригинал 16px;
    line-height: $lineFontsizeStandart-k1360;
  }
}

@media screen and (max-width: 1219px) {
  .sidemenu {
    padding-left: 10px;
    z-index: 1990;
    background-color: $colorWhite;

    border-right: 1px solid $colorAccentLight;
    overflow: scroll;
    overflow-y: visible;
  }
}
</style>
