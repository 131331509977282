<template>
  <div class="warningblock" :class="{ 'warningblock--fatality': fatality }">
    <div class="rightzone_block warningblock-internal">
      <div class="warningblock-colorline"></div>
      <div class="warningblock-iconzone marginleft--standart marginright--mini">
        <div v-svg="{ name: 'warningtriangle' }"></div>
      </div>
      <div class="warningblock-text">
        <slot />
      </div>
    </div>
    <div v-if="showButton" class="warningblock-buttonzone marginleft--standart">
      <Button
        :text="$t('Warningblock:ButtonHide')"
        size="standart"
        color="graylines"
        icon="passwordhide"
        @click="$emit('dismiss')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
export default {
  name: 'WarningBlock',
  components: { Button },
  props: {
    fatality: { type: Boolean, default: false },
    showButton: { type: Boolean, default: true },
  },
};
</script>
