<template>
  <article class="main">
    <div v-if="isSubmitting" class="loader-container">
      <LoaderBlock />
    </div>
    <template v-else>
      <SideMenu :entries="entries" @logout="logout" @invoice="invoice" />
      <router-view :key="routerViewKey"></router-view>
    </template>
  </article>
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/auth';
import routeNames from '@/router/routeNames';

import SideMenu from '@/components/SideMenu';
//import LoaderBlock from '@/components/blocks/LoaderBlock';
import LoaderBlock from '@/components/system/PageLoader';

export default {
  name: 'AppMain',
  components: {
    SideMenu,
    LoaderBlock,
  },
  computed: {
    routerViewKey() {
      const { fullPath, query } = this.$route;
      // Создаем новый путь, исключая параметр 'page'
      const queryWithoutPage = { ...query };
      delete queryWithoutPage.page;

      // Собираем строку без параметра 'page'
      const url = this.$router.resolve({
        path: this.$route.path,
        query: queryWithoutPage,
      }).href;

      return url;
    },
    ...mapState({
      loading: (state) => state.auth.isSubmitting,
      userRole: (state) => state.auth.currentUserRole,
      isSubmitting(state) {
        return (
          state.auth.isSubmitting &&
          (this.$route.name !== routeNames.settingsPage ||
            !state.auth.currentUser)
        );
      },
    }),
    entries() {
      const userRole = this.userRole;

      const entries = [];

      if (userRole?.claim_users_see) {
        const entry = {
          name: this.$t('Main:MenuEntries:Administate'), //'Администрирование',
          icon: 'menu_administration',
          routes: [],
        };
        entry.routes.push({
          name: this.$t('Main:MenuEntries:Users'), //'Пользователи',
          route: 'Users',
        });
        entry.routes.push({
          name: this.$t('Main:MenuEntries:Roles'), //'Роли',
          route: 'Roles',
        });
        if (userRole?.claim_banners_edit)
          entry.routes.push({
            name: this.$t('Main:MenuEntries:Banners'),
            route: 'AdminAdwBanners',
          });
        if (userRole?.claim_articles_edit)
          entry.routes.push({
            name: this.$t('Main:MenuEntries:Articles'),
            route: 'Articles',
          });
        if (userRole?.claim_instructions_edit)
          entry.routes.push({
            name: this.$t('Main:MenuEntries:Instructions'),
            route: 'Instructions',
          });
        entries.push(entry);
      }

      if (userRole?.claim_glossary_see) {
        const entry = {
          name: this.$t('Main:MenuEntries:Dictionaries'), //'Справочник',
          icon: 'menu_glossary',
          routes: [
            {
              name: this.$t('Main:MenuEntries:Categories'), //'Категории культур',
              route: routeNames.categories,
            },
            {
              name: this.$t('Main:MenuEntries:Cultures'), //'Культуры',
              route: routeNames.cultures,
            },
            {
              name: this.$t('Main:MenuEntries:Pests'), //'Вредные обьекты',
              route: routeNames.badObjects,
            },
            {
              name: this.$t('Main:MenuEntries:StimulationTypes'), //'Типы стимуляции',
              route: routeNames.typesStimulation,
            },
            {
              name: this.$t('Main:MenuEntries:Phases'), //'Фазы',
              route: routeNames.phases,
            },
            {
              name: this.$t('Main:MenuEntries:Geobase'), //'Регионы',
              route: routeNames.regions,
            },
          ],
        };
        entries.push(entry);
      }

      if (userRole?.claim_products_see) {
        entries.push({
          icon: 'menu_products',
          routes: [
            {
              name: this.$t('Main:MenuEntries:Products'), //'Продукты',
              route: routeNames.products,
            },
          ],
        });
      }

      if (userRole?.claim_clients_see) {
        let entry = {
          icon: 'menu_clients',
          name: this.$t('Main:MenuEntries:Clients'), //'Клиенты',
          routes: [
            {
              name: this.$t('Main:MenuEntries:Clients'), //'Клиенты',
              route: routeNames.clients,
            },
          ],
        };

        if (userRole?.claim_clients_duplicates_edit) {
          entry.routes.push({
            name: this.$t('Main:MenuEntries:ClientsDuplicates'), //'Дубликаты Клиенты',
            route: routeNames.clientsDuplicates,
          });
        }
        entries.push(entry);
      }

      if (userRole?.claim_analyse_see) {
        entries.push({
          icon: 'menu_analyses',
          routes: [
            {
              name: this.$t('Main:MenuEntries:Analyzes'), //'Анализы',
              route: routeNames.analyzes,
            },
          ],
        });
      }

      if (userRole?.claim_plants_care_see) {
        const entry = {
          icon: 'menu_defense',
          name: this.$t('Main:MenuEntries:PlantsCare'), //'Защита растений',
          routes: [],
        };
        if (userRole?.claim_plants_care_edit)
          entry.routes.push({
            name: this.$t('Main:MenuEntries:PlantsCareTemplates'), //'Шаблоны',
            route: routeNames.defenseTemplates,
          });
        entry.routes.push({
          name: this.$t('Main:MenuEntries:PlantsCarePrograms'), //'Программа',
          route: routeNames.defensePrograms,
        });
        entries.push(entry);
      }

      if (userRole?.claim_plants_nutrition_see) {
        const entry = {
          icon: 'menu_fertilizers',
          name: this.$t('Main:MenuEntries:PlantsNutrition'), //'Удобрения',
          routes: [
            {
              name: this.$t('Main:MenuEntries:PlantsNutrition'), //'Удобрения',
              route: routeNames.fertilizerPrograms,
            },
          ],
        };

        if (userRole?.claim_plants_nutrition_edit)
          entry.routes.push({
            name: this.$t('Main:MenuEntries:PlantsNutritionDefaultProducts'),
            route: routeNames.fertilizerDefaultList,
          });

        entries.push(entry);
      }

      entries.push({
        icon: 'menu_invoice',
        name: this.$t('Main:MenuEntries:Invoice'), //'Инвойсирование',
        event: 'invoice',
      });

      entries.push({
        icon: 'menu_settings',
        routes: [
          {
            name: this.$t('Main:MenuEntries:Settings'), //'Настройки',
            route: routeNames.settingsPage,
          },
        ],
      });

      entries.push({
        icon: 'menu_exit',
        name: this.$t('Main:MenuEntries:Exit'), //'Выход',
        event: 'logout',
      });

      return entries;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(actionTypes.logout);
    },
    invoice() {
      window.open('http://212.72.133.26:2500/', '_blank').focus();
    },
  },
  created() {
    if (this.userRole) return;
    this.$store.dispatch(actionTypes.getCurrentUser);
  },
};
</script>

<style scoped>
.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
