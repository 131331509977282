<!--Translated-->
<template>
  <div
    class="cwrap cwrap--total marginbottom--standart"
    v-if="unTotalProductList.length"
  >
    <!--TODO: №2 Удобрения Юзера -->
    <!--TODO: Stas верстка - ГОТОВО 15.01.2025-->
    <!--TODO: Витя Логика цен для УДОББРЕНИЙ У ЮЗЕРА-->
    <!--TODO: Витя ЗАКОМЕНЧЕНЫ УСЛОВИЯ ИЗ ЗАЩИТЫ У МЕНЕДЖЕРА, нужно поменять на соответствующее условие тут-->
    <!--TODO: САША Перевод-->
    <div class="cblock cblock--totalprice2025 el--borders-all el--rounded-all">
      <!--Иконка с инфой, также инфа в блоке ниже по финальной цене-->
      <div
        class="infoicon"
        v-tooltip="{
          text: $t('ProgramTables:Tooltip.Text'),
          classList: [
            'tooltip',
            'tooltip--top',
            'tooltip--toright',
            'tooltip--oneline',
            'tooltip--anim1',
          ],
        }"
      >
        <div v-svg="{ name: 'info2025' }"></div>
      </div>

      <template v-if="pricePerHaDiscount > 0">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <span>{{ $t('ProgramTables:Sum') }}</span>
            <span>{{ $t('Vuex:plantsCare:CalculationMethodsShort:3') }}:</span>
            <span class="this-price">₾ {{ formatNumber(pricePerHaNew) }}</span>
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <span>{{ $t('ProgramTables:DiscountSum') }}</span>
            <span>{{ $t('Vuex:plantsCare:CalculationMethodsShort:3') }}:</span>
            <span class="this-price"
              >₾ {{ formatNumber(pricePerHaDiscount) }}</span
            >
          </div>
        </div>

        <div class="cblock__line">
          <div
            class="cblock__line-block uni_text-accentcolor uni_text-bold"
            v-tooltip="{
              text: $t('ProgramTables:Tooltip.Text'),
              classList: [
                'tooltip',
                'tooltip--top',
                'tooltip--toleft',
                'tooltip--oneline',
                'tooltip--anim1',
              ],
            }"
          >
            <span>{{ $t('ProgramTables:DiscountSumFinal') }}</span>
            <span>{{ $t('Vuex:plantsCare:CalculationMethodsShort:3') }}:</span>
            <span class="this-price"
              >₾ {{ formatNumber(pricePerHaDiscounted) }}</span
            >
          </div>
        </div>
      </template>
      <template v-else>
        <div class="cblock__line">
          <div
            class="cblock__line-block uni_text-accentcolor uni_text-bold"
            v-tooltip="{
              text: $t('ProgramTables:Tooltip.Text'),
              classList: [
                'tooltip',
                'tooltip--top',
                'tooltip--toleft',
                'tooltip--oneline',
                'tooltip--anim1',
              ],
            }"
          >
            <span>{{ $t('ProgramTables:Sum') }}</span>
            <span>{{ $t('Vuex:plantsCare:CalculationMethodsShort:3') }}:</span>
            <span class="this-price">₾ {{ formatNumber(pricePerHaNew) }}</span>
          </div>
        </div>
      </template>
    </div>
    <!--TODO: ДО СЮДА + удалить что ниже-->

    <div>
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'fertilizer' }"
          />
          <div class="chead_text">
            {{ $t('FertilizerProgramTables:Title2') }}
          </div>
        </div>
      </div>

      <div class="cblock el--rounded-bottom">
        <table class="table--type1 table--withhead table--paycheck">
          <thead>
            <tr>
              <th class="tbl-cellproduct">
                {{ $t('FertilizerProgramTables:Table:Headers:0') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:1') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:2.1') }}
                / 1
                {{ $t('FertilizerProgramTables:Table:Headers:2.2') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:2.1') }} /
                {{ area }} {{ $t('FertilizerProgramTables:Table:Headers:2.2') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:4') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:5') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="row in unTotalProductList"
              :key="row.productLink.id"
              class="row"
            >
              <td>{{ row.productLink.name }}</td>
              <td>{{ row.productLink.picking }}</td>
              <td>{{ formatNumber(row.totalPerHa) }}</td>
              <td>{{ formatNumber(row.totalForArea) }}</td>
              <td>
                {{ formatNumber(row.packages) }}
              </td>
              <td>{{ row.packagesInt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="cwrap">
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'paycheck' }"
          ></div>
          <div class="chead_text">
            {{ $t('FertilizerProgramTables:Result') }}
          </div>
        </div>
      </div>

      <div
        class="cblock cblock--analizetable el--rounded-bottom el--borders-sides el--borders-bottom"
      >
        <div class="cblock__internal">
          <div class="cblock__line paycheckfinal">
            <!--col1-->
            <div class="paycheckfinal_block paycheckfinal_block--dates">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DateCreate') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ createdAt }}</div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DateEdit') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ updatedAt }}</div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>

            <!--col2-->
            <div class="paycheckfinal_block">
              <!--Сумма в лари-->
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumGel') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceRounded)
                      ? '₾ ' + formatNumber(totalPriceRounded)
                      : '-'
                  }}
                </div>
              </div>

              <!--Скидка %-->
              <div
                class="paycheckfinal_block-line paycheckfinal_block--finalprice"
              >
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:Discount') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{ program.client_discount || 0 }}%
                </div>
              </div>

              <!--Сумма скидки в лари-->
              <div
                class="paycheckfinal_block-line paycheckfinal_block--finalprice"
              >
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DiscountSum') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{
                    formatNumber(
                      (totalPriceRounded / 100) * (program.client_discount || 0)
                    )
                  }}
                </div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>

            <!--col3-->
            <div class="paycheckfinal_block">
              <!--Итоговая сумма в лари-->
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:ResultSum') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{ formatNumber(totalPriceDiscounted) }}
                </div>
              </div>

              <!--TODO Исправить расчет именно для итоговой, сейчас тут общая (Итоговая сумма в долларах)-->
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumDollar') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceUSD)
                      ? '$ ' + formatNumber(totalPriceUSD)
                      : '-'
                  }}
                </div>
              </div>

              <!--TODO Исправить расчет именно для итоговой, сейчас тут общая  (Итоговая сумма в евро)-->
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumEuro') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceEU)
                      ? '€ ' + formatNumber(totalPriceEU)
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UserFertilizerProgramTables',
  computed: {
    ...mapGetters(['unTotalProductList']),
    ...mapState({
      isSaving: (state) => state.userNutrition.isSaving,
      program: (state) => state.userNutrition.program,
      area: (state) => state.userNutrition.program.place_area,
      createdAt: (state) =>
        state.userNutrition.program.date_create
          ? format(
              new Date(state.userNutrition.program.date_create),
              'dd.MM.yyyy HH:MM'
            )
          : 'არ არის შენახული',
      updatedAt: (state) =>
        state.userNutrition.program.date_update
          ? format(
              new Date(state.userNutrition.program.date_update),
              'dd.MM.yyyy HH:MM'
            )
          : '-',
    }),
    totalPrice() {
      return this.unTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packages,
        0
      );
    },
    totalPriceRounded() {
      return this.unTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packagesInt,
        0
      );
    },
    pricePerHaNew() {
      return this.totalPriceRounded / this.area;
    },
    pricePerHaDiscount() {
      return this.pricePerHaNew * (this.program.client_discount / 100);
    },
    pricePerHaDiscounted() {
      return this.pricePerHaNew - this.pricePerHaDiscount;
    },
    totalPriceDiscounted() {
      return (
        this.totalPriceRounded -
        (this.totalPriceRounded / 100) * (this.program.client_discount || 0)
      );
    },
    totalPriceUSD() {
      return this.program.rate_dollar
        ? this.totalPriceRounded / this.program.rate_dollar
        : null;
    },
    totalPriceEU() {
      return this.program.rate_euro
        ? this.totalPriceRounded / this.program.rate_euro
        : null;
    },
  },
  methods: {
    formatNumber(num) {
      return num.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
