<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('AdminAdwBannersEditor:Title:0'), route: routeBack },
          {
            title: isEditing
              ? $t('AdminAdwBannersEditor:Title:1')
              : $t('AdminAdwBannersEditor:Title:2'),
          },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AdminAdwBannersEditor:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :isLoading="false"
          :loadingLeft="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('AdminAdwBannersEditor:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :isLoading="isSaving"
          :loadingLeft="isSaving"
          :disabled="inputsDisabled || saveDisabled"
          @click="save"
        />
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <LocationSelect
              :geoEntries="geoMapped"
              v-model="banner.geo_id"
              :freeSelect="true"
              :type="1"
              :disabled="inputsDisabled"
              ref="location"
            />
          </div>
          <div class="cblock__line">
            <Input
              type="text"
              :placeholder="$t('AdminAdwBannersEditor:Name.Placeholder')"
              :label="$t('AdminAdwBannersEditor:Name.Label')"
              size="long3x"
              v-model="banner.name"
              :state="inputsDisabled ? 'disabled' : ''"
            />
          </div>
        </div>
      </div>

      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <!--Превью баннера-->
          <div class="cblock__line">
            <div
              class="cblock__line-block--vertical usermaintopcomposition-editorwrapper"
            >
              <label class="lable">
                {{ $t('AdminAdwBannersEditor:Preview:Header') }}
              </label>
              <div class="usermaintopcomposition">
                <div class="usermaintopcomposition_imageblock">
                  <div
                    class="usermaintopcomposition_content"
                    :class="{
                      'usermaintopcomposition_content--left': !banner.align,
                    }"
                  >
                    <div class="textline">
                      {{ banner.text }}
                    </div>
                    <Button
                      v-if="banner.button_text && banner.button_link"
                      :text="banner.button_text"
                      size="auto"
                      color="whitelines"
                      icon=""
                      @click="openLink"
                    />
                  </div>

                  <div class="usermaintopcomposition_imagemask"></div>

                  <img :src="imageUrl || defaultFile" />
                </div>
              </div>
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block flex-between">
              <RadioToggle
                type="disabled"
                :text1="$t('AdminAdwBannersEditor:Align:Left')"
                :text2="$t('AdminAdwBannersEditor:Align:Right')"
                :label="$t('AdminAdwBannersEditor:Align:Label')"
                name="align"
                v-model="banner.align"
                :disabled="inputsDisabled"
              />

              <div class="el--internal-nomargin flex-end">
                <Button
                  class=""
                  :text="$t('AdminAdwBannersEditor:ImageText')"
                  size="standart"
                  color="graylinesaccent"
                  icon="photo"
                  @click="$refs.fileInput.click()"
                  :disabled="inputsDisabled"
                />
                <input
                  ref="fileInput"
                  type="file"
                  accept="image/png, image/jpeg"
                  @change="fileSelected"
                  style="display: none"
                />
                <Button
                  text="Очистить"
                  size="s-standart"
                  color="graylinesalert"
                  icon="delete"
                  :disabled="imageUrl === null || inputsDisabled"
                  @click="
                    imageUrl = null;
                    file = null;
                  "
                />
              </div>
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="textarea"
                :placeholder="
                  $t('AdminAdwBannersEditor:BannerText.Placeholder')
                "
                :label="$t('AdminAdwBannersEditor:BannerText.Label')"
                size="long"
                v-model="banner.text"
                :state="inputsDisabled ? 'disabled' : ''"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="
                  $t('AdminAdwBannersEditor:ButtonText.Placeholder')
                "
                :label="$t('AdminAdwBannersEditor:ButtonText.Label')"
                size="standart"
                v-model="banner.button_text"
                :state="inputsDisabled ? 'disabled' : ''"
              />
              <Input
                type="text"
                :placeholder="$t('AdminAdwBannersEditor:Link.Placeholder')"
                :label="$t('AdminAdwBannersEditor:Link.Label')"
                size="max"
                icon="link"
                v-model="banner.button_link"
                :state="inputsDisabled ? 'disabled' : ''"
              />

              <Button
                text="Очистить"
                size="s-standart"
                color="graylinesalert"
                icon="backspace"
                @click="banner.button_link = ''"
                :state="inputsDisabled ? 'disabled' : ''"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line-block">
            <RadioToggle
              type="disabled"
              :text2="$t('AdminAdwBannersEditor:Active:1')"
              :text1="$t('AdminAdwBannersEditor:Active:0')"
              name="isBannerActive"
              :reverse="true"
              v-model="banner.is_active"
              :disabled="inputsDisabled"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isEditing"
      class="cblock el--borders-all el--rounded-bottom el--rounded-top marginbottom--standart"
    >
      <div class="cblock__infoblock">
        <p>
          {{ $t('AdminAdwBannersEditor:BannerDeleteText') }}
        </p>

        <Button
          :text="$t('AdminAdwBannersEditor:BannerDeleteButton')"
          size="standart"
          color="alertlines"
          icon="userdelete"
          @click="deleteBanner"
          :disabled="inputsDisabled"
        />
      </div>
    </div>

    <div class="cwrap">
      <div class="cblock">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('AdminAdwBannersEditor:ButtonSave')"
              size="standart"
              color="accent"
              icon="save"
              :isLoading="isSaving"
              :loadingLeft="isSaving"
              :disabled="inputsDisabled || saveDisabled"
              @click="save"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import isEqual from 'lodash.isequal';
import routeNames from '@/router/routeNames';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import { mapGetters } from 'vuex';
import { updateBanner, getBanners } from '@/api/admin';
import { serverUrl } from '@/api/axios.js';

import LocationSelect from '@/components/views/LocationSelect';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';

export const defaultBanner = {
  id: undefined,
  geo_id: null,
  name: '',
  align: 0,
  text: '',
  button_text: '',
  button_link: '',
  is_active: 1,
  image_link: null,
};

const defaultFile = require('@/assets/userpanel/img/usermain/topimg.jpg');

export default {
  name: 'AdminAdwBannersEditor',
  components: {
    RadioToggle,
    Input,
    Button,
    Breadcrumps,
    LocationSelect,
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      banner: { ...defaultBanner },
      file: null,
      imageUrl: null,
      defaultFile,
      serverData: { ...defaultBanner },
      routeBack: routeNames.adminAdwBanners,
      nextRouteConfirm: false,
    };
  },
  methods: {
    openLink() {
      window.open(this.banner.button_link, '_blank').focus();
    },
    fileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.imageUrl = window.URL.createObjectURL(file);
      }
      event.target.value = '';
    },
    async save() {
      this.isSaving = true;
      try {
        await updateBanner(this.banner, this.file);
      } finally {
        this.isSaving = false;
        this.file = null;
        if (!this.isEditing) {
          this.nextRouteConfirm = true;
          this.$router.push({ name: this.routeBack });
        } else {
          this.getData();
        }
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const { banners } = await getBanners();
        this.banner = banners.find(({ id }) => id == this.$route.params.id);
        this.serverData = { ...this.banner };
        this.imageUrl = serverUrl + this.banner.image_link;
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      this.banner = { ...defaultBanner };
      this.file = null;
      this.imageUrl = null;
      this.$refs.location.reset();
    },
    deleteBanner() {
      this.$confirm({
        message: this.$t('AdminAdwBannersEditor:ModalConfirmDelete.Text'),
        buttons: {
          left: {
            text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonDelete'),
            icon: 'delete',
          },
          right: {
            text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonUndo'),
            icon: 'cancel',
          },
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isSaving = true;
            try {
              await updateBanner({ ...this.banner, delete: true });
            } finally {
              this.isSaving = false;
            }
            this.nextRouteConfirm = true;
            this.$router.push({ name: this.routeBack });
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters(['geoMapped']),
    isEditing() {
      return this.$route.name === routeNames.editAdminAdwBanner;
    },
    inputsDisabled() {
      return this.isLoading || this.isSaving;
    },
    wasEdited() {
      return this.file !== null || !isEqual(this.banner, this.serverData);
    },
    isValid() {
      return (this.file || this.banner.image_link) && this.banner.name !== '';
    },
    saveDisabled() {
      return !this.isValid || !this.wasEdited;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to?.params?.skipModal || !this.wasEdited || this.nextRouteConfirm) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('DefenseProgramEdit:ModalRoute:Message1') +
          '<br />' +
          this.$t('DefenseProgramEdit:ModalRoute:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonOk'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonCancel'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
  created() {
    if (this.isEditing) this.getData();
  },
};
</script>

<style lang="scss" scoped>
.usermaintopcomposition-editorwrapper {
  width: 100%;
}
.usermaintopcomposition {
  & .usermaintopcomposition_imageblock {
    height: 412px !important;
  }
}

.mod-imageselect {
  ::v-deep .cblock.el--borders-all.el--rounded-bottom {
    border: none;

    & .addimagewrap.cblock__internal {
      padding: 0;
    }

    & .addimage-imagezone {
      margin: 0;
    }

    & .addimage-imagezone:first-child::after {
      display: none;
    }
  }
}

@media screen and (max-width: 1800px) {
  .usermaintopcomposition {
    & .usermaintopcomposition_imageblock {
      height: 328px !important;
    }
  }
}

@media screen and (max-width: 1420px) {
  .usermaintopcomposition {
    & .usermaintopcomposition_imageblock {
      height: 288px !important;
    }
  }
}
</style>
