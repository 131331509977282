<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('ProductsEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Products' })"
        />
        <Button
          :text="$t('ProductsEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="request.isUpdating"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <Warningblock
      v-if="showWarning"
      :show-button="showWarningBtn"
      :fatality="showWarningBtn"
      @dismiss="onHideWarning"
    >
      {{ warningText }}
    </Warningblock>

    <!--ОПИСАНИЕ ПРОДУКТА-->
    <div class="cwrap">
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('ProductsEdit:HeaderDescription') }}
          </div>
        </div>
      </div>

      <!--Данные продукта-->
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <!--ПОИСК ПРОДУКТА В БД БУХГАЛТЕРИИ-->
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('ProductsEdit:Id.Placeholder')"
                :label="$t('ProductsEdit:Id.Label')"
                icon=""
                size=""
                color=""
                :state="stateForInputsNotBlocked"
                v-model="newProduct.product.code"
              />

              <!--v-if ЕСЛИ НЕ НАЙДЕНА, УВЕДОМЛЕНИЕ-->
              <Button
                v-if="!isEditProductMode"
                :text="$t('ProductsEdit:ButtonFind')"
                size="wide"
                color="accent"
                icon="search"
                :disabled="!newProduct.product.code || request.isUpdating"
                @click="findButtonClick"
                :loading-left="true"
                :is-loading="this.request.isFinding"
              />

              <!--v-else ЕСЛИ НАЙДЕНА, ВМЕСТО кнопки "НАЙТИ" inp+buttpn-->
              <template v-else>
                <Input
                  :state="isDisabledUi ? 'disabled' : 'disabled2'"
                  type="text"
                  placeholder=""
                  :label="$t('ProductsEdit:ApexDate')"
                  :value="productUpdateDate"
                />
                <Button
                  :text="$t('ProductsEdit:ButtonUpdate')"
                  size="wide"
                  color="accentlines"
                  icon="retry"
                  :loading-left="true"
                  :disabled="isDisabledUi || !canEdit"
                  :is-loading="request.isFinding"
                  @click="onReloadProduct"
                />
              </template>
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Название-->
              <Input
                type="text"
                :placeholder="$t('ProductsEdit:ProductName.Placeholder')"
                :label="$t('ProductsEdit:ProductName.Label')"
                size="long"
                :state="stateForInputs"
                v-model="newProduct.product.name"
              />

              <!--Активное вещество-->
              <Input
                type="text"
                :placeholder="$t('ProductsEdit:Substance.Placeholder')"
                :label="$t('ProductsEdit:Substance.Label')"
                size="standard"
                :state="isDisabledUi ? 'disabled' : canEdit ? '' : 'disabled2'"
                v-model="newProduct.product.active_substance"
              />
            </div>
          </div>

          <!--ВЫБОР ТИПА ПРОДУКТА-->
          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Тип продукта-->
              <!--<Input
                type="dropdown"
                placeholder="Выберите..."
                label="Тип продукта"
                icon=""
                :state="isDisabledUi ? 'disabled' : ''"
                size="standard"
                color=""
                :dd-items="[
                  { text: 'Не выбрано', value: null },
                  { text: 'Пестицид', value: 0 },
                  { text: 'Стимулятор', value: 1 },
                  { text: 'Удобрение', value: 2 },
                ]"
              />-->
              <DropdownSelectValue
                :placeholder="$t('ProductsEdit:Type.Placeholder')"
                :label="$t('ProductsEdit:Type.Label')"
                :disabled="isDisabledUi"
                :disabled2="stateDisabled2ForDD || !canEdit"
                size="standard"
                v-model="newProduct.product.type_id"
                :options="ddItemsProductTypes"
                @selected="selectedType"
              />
              <!--Если Пестицид-->
              <DropdownSelectValue
                v-if="newProduct.product.type_id === 0"
                :placeholder="$t('ProductsEdit:Pest.Placeholder')"
                :label="$t('ProductsEdit:Pest.Label')"
                :disabled="isDisabledUi"
                :disabled2="stateDisabled2ForDD || !canEdit"
                size="standard"
                v-model="newProduct.product.sub_type_id"
                :options="ddItemsProductPests"
                dd-max-elements="7"
                :dd-use-default-icon="true"
                icon="default3"
              />
            </div>
          </div>

          <!--НУЖНО СКРЫТЬ ВСЮ ЛИНИЮ, ЕСЛИ ВЫШЕ ПЕСТИЦИД-->
          <div
            class="cblock__line"
            v-if="
              newProduct.product.type_id === 1 ||
              newProduct.product.type_id === 2
            "
          >
            <div class="cblock__line-block">
              <!--Если Удобрения, то оба-->
              <div v-if="newProduct.product.type_id === 2">
                <check-set
                  :key="1"
                  :title="$t('ProductsEdit:FertilizerType')"
                  :options="csFertilizerItems"
                  keyName="fertilizer_subcategory_id"
                  v-model="newProduct.productFertilizers"
                  :disabled="isDisabledUi"
                  :locked="!canEdit"
                />
              </div>

              <!--Если Стимулятор, только стимулятор-->
              <div v-if="newProduct.product.type_id === 1">
                <check-set
                  :key="2"
                  :title="$t('ProductsEdit:TypesStimulation')"
                  :options="data.typesStimulation"
                  keyName="type_stimulation_id"
                  v-model="newProduct.productTypesStimulation"
                  :disabled="isDisabledUi"
                  :locked="!canEdit"
                />
              </div>
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Производитель-->
              <DropdownPlusSelectValue
                :placeholder="$t('ProductsEdit:Vendor.Placeholder')"
                :label="$t('ProductsEdit:Vendor.Label')"
                :disabled="isDisabledUi"
                :disabled2="stateDisabled2ForDD || !canEdit"
                size="long"
                v-model="newProduct.product.manufacturer_id"
                :options="ddItemsProductManufacturers"
              />

              <!--Страна-Производитель-->

              <DropdownSelectValue
                :placeholder="$t('ProductsEdit:VendorCountry.Placeholder')"
                :label="$t('ProductsEdit:VendorCountry.Label')"
                :disabled="isDisabledUi"
                :disabled2="stateDisabled2ForDD || !canEdit"
                size="standard"
                v-model="newProduct.product.manufacturer_country_id"
                :options="ddItemsProductCountries"
                dd-max-elements="7"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Упаковка-->
              <Input
                type="number"
                :placeholder="$t('ProductsEdit:Pack.Placeholder')"
                :label="$t('ProductsEdit:Pack.Label')"
                :state="stateForInputs"
                size="standard"
                v-model="newProduct.product.picking"
              />

              <!--Упаковачная единица-->
              <DropdownSelectValue
                :placeholder="$t('ProductsEdit:Unit.Placeholder')"
                :label="$t('ProductsEdit:Unit.Label')"
                :disabled="isDisabledUi"
                :disabled2="stateDisabled2ForDD || !canEdit"
                size="standard"
                v-model="newProduct.product.picking_unit_id"
                :options="ddItemsProductPickingUnits"
              />

              <!--Цена-->
              <Input
                type="number"
                :placeholder="$t('ProductsEdit:Price.Placeholder')"
                :label="$t('ProductsEdit:Price.Label')"
                icon=""
                :state="stateForInputs"
                size="standard"
                v-model="newProduct.product.price"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Введите расход-->
              <Input
                type="number"
                :placeholder="$t('ProductsEdit:Quantity100.Placeholder')"
                :label="$t('ProductsEdit:Quantity100.Label')"
                :state="isDisabledUi ? 'disabled' : canEdit ? '' : 'disabled2'"
                v-model="newProduct.product.quantity_per_100_litre"
              />

              <!--Введите расход-->
              <Input
                type="number"
                :placeholder="$t('ProductsEdit:Quantity1.Placeholder')"
                :label="$t('ProductsEdit:Quantity1.Label')"
                :state="isDisabledUi ? 'disabled' : canEdit ? '' : 'disabled2'"
                size="standard"
                v-model="newProduct.product.quantity_per_ha"
              />

              <!--Стоимость на обьем-->
              <Input
                type="text"
                placeholder=""
                :label="$t('ProductsEdit:PricePerHa.Label')"
                :state="isDisabledUi ? 'disabled' : 'disabled2'"
                size="standard"
                :value="priceFor1Ha"
              />
            </div>
          </div>

          <!--ОТКЛЮЧЕНИЕ, НУЖНО СКРЫТЬ ВСЮ ЛИНИЮ, ЕСЛИ ЕГО НЕТ-->
          <div class="cblock__line">
            <div class="cblock__line-block">
              <RadioToggle
                type="disabled"
                :text1="$t('ProductsEdit:Active.Hide')"
                :text2="$t('ProductsEdit:Active.Active')"
                :label="$t('ProductsEdit:Active.Label')"
                :reverse="true"
                v-model="newProduct.product.is_active"
                :disabled="isDisabledUi"
                :locked="!canEdit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--v-if (Если продукт==Вредн обьект) -->
    <div
      class="cwrap cwrap--level1 marginbottom--standart"
      v-if="newProduct.product.type_id === 0"
    >
      <LinkedPests
        :isLoading="request.isLoading || request.isUpdating"
        :pests="data.pests"
        v-model="newProduct.productPests"
        :disabled="isDisabledUi"
        :multiMode="true"
        :readonly="!canEdit"
      >
        <template v-slot:description>
          <p v-if="!newProduct.productPests.length">
            {{ $t('ProductsEdit:NoPestsCulture') }}
          </p>
          <p v-else>{{ $t('ProductsEdit:NoPestsPhase') }}</p>
        </template>
      </LinkedPests>
    </div>

    <!--v-if (Если продукт==Удобрения) -->

    <TableElements
      v-if="newProduct.product.type_id === 2"
      :is-loading="false"
      :title="$t('ProductsEdit:TableElementsTitle')"
      :elements="this.data.elements"
      v-model="newProduct.productElements"
      :disabled="isDisabledUi"
      :locked="!canEdit"
      :elements-with-oxid="true"
    />
    <Button
      v-if="canEdit"
      :text="$t('ProductsEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="request.isUpdating"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
      class="marginbottom--standart"
    />
    <!--БЛОК УДАЛИТЬ / ИНФО-->
    <div class="cwrap marginbottom--standart" v-if="showDeleteBlock && canEdit">
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">{{ $t('ProductsEdit:DeleteHeader') }}</div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('ProductsEdit:DeleteText') }}
          </p>

          <Button
            :text="$t('ProductsEdit:ButtonDelete')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            :is-loading="request.isLoading"
            :loading-left="false"
            @click="onDeleteClick"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { createConfirmCallback } from '@/helpers/confirmModalHelper';
import { mapState } from 'vuex';
import { mutationTypes as productsMutationTypes } from '@/store/modules/products';
import routeNames from '@/router/routeNames';
import productsApi from '@/api/products';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';
import RadioToggle from '@/components/views/RadioToggle';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';
import CheckSet from '@/components/views/CheckSet';
import LinkedPests from '@/pages/glossary/GlossaryLinkedPests';
import TableElements from '@/components/tables/TableElements';
import Warningblock from '@/components/views/Warningblock';

export default {
  name: 'ProductsEdit',
  components: {
    Warningblock,
    TableElements,
    CheckSet,
    DropdownPlusSelectValue,
    DropdownSelectValue,
    RadioToggle,
    Breadcrumps,
    Input,
    Button,
    LinkedPests,
  },
  data() {
    return {
      request: {
        isLoading: false,
        isUpdating: false,
        isFinding: false,
        hasError: false,
      },
      data: {
        manufacturers: [],
        manufacturersCountries: [],
        elements: [],
        typesStimulation: [],
        pests: [],
      },
      metadata: {},
      state: {
        findOk: false,
        //isEditing: false,
        nextRouteConfirm: false,
      },
      tmp: {
        manufacturer: null,
        manufacturer2: [],
      },
      newProduct: {
        product: {
          id: null,
          code: '',
          name: '',
          type_id: null,
          sub_type_id: null,
          active_substance: '',
          picking: null,
          picking_unit_id: null,
          quantity_per_100_litre: null,
          quantity_per_ha: null,
          manufacturer_id: null,
          manufacturer_country_id: null,
          price: null,
          vat: null,
          is_active: 1,
          update_date: null,
          attention: null,
          user_ignore_attention: null,
          exist_apex: null,
        },
        productFertilizers: [],
        productTypesStimulation: [],
        productElements: [],
        productPests: [],
      },
      serverProduct: {},
    };
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_products_edit === 1,
    }),
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editProduct)
        return [
          { title: this.$t('ProductsEdit:Title1'), route: 'Products' },
          { title: this.$t('ProductsEdit:Title2') },
        ];
      else
        return [
          { title: this.$t('ProductsEdit:Title1'), route: 'Products' },
          { title: this.$t('ProductsEdit:Title3') },
        ];
    },
    isDisabledUiNotBlocked() {
      return this.request.isLoading;
    },
    isDisabledUi() {
      return (
        this.request.isLoading ||
        (!this.isEditProductMode && !this.state.findOk)
      );
    },
    ddItemsProductTypes() {
      return this.$store.state.products.productsCategories.map((value) => {
        return { value: value.id, text: value.name };
      });
    },
    ddItemsProductManufacturers() {
      return this.data.manufacturers.map((value) => {
        return { value: value.id, text: value.name };
      });
    },
    ddItemsProductCountries() {
      return this.data.manufacturersCountries.map((value) => {
        return { value: value.id, text: value.name };
      });
    },
    ddItemsProductPickingUnits() {
      return this.$store.state.products.productsPickingUnits.map((value) => {
        return { value: value.id, text: value.name };
      });
    },
    ddItemsProductPests() {
      return this.$store.state.products.productsPestsSubcategories.map(
        (val) => {
          return {
            text: val.name,
            value: val.id,
            icon: val.icon,
            class: val.class,
          };
        }
      );
    },
    csFertilizerItems() {
      return this.$store.state.products.productsFertilizersSubcategories;
    },
    isSaveButtonDisabled() {
      if (
        !this.newProduct.product.code ||
        !this.newProduct.product.name ||
        this.newProduct.product.type_id === null ||
        (this.newProduct.product.type_id === 0 &&
          this.newProduct.product.sub_type_id === null)
      ) {
        return true;
      }

      if (!this.isEditProductMode) {
        if (!this.state.findOk) {
          return true;
        }
      } else {
        return !this.hasChanges;
      }
      return !this.canEdit || false;
    },
    hasChanges() {
      for (const key of Object.keys(this.newProduct.product)) {
        if (this.newProduct.product[key] !== this.serverProduct?.product[key]) {
          //console.log(key);
          return true;
        }
      }
      if (
        this.newProduct.product.type_id === 2 &&
        this.newProduct.productElements
      ) {
        if (!this.serverProduct?.productElements) {
          console.log(1);
          return true;
        }
        if (
          this.newProduct.productElements.length !==
          this.serverProduct?.productElements.length
        ) {
          console.log(2);
          return true;
        }
        for (const [index] of this.newProduct.productElements.entries()) {
          if (
            this.newProduct.productElements[index].element_id !==
            this.serverProduct?.productElements[index].element_id
          ) {
            console.log(3);
            return true;
          }
          if (
            this.newProduct.productElements[index].quantity !==
            this.serverProduct?.productElements[index].quantity
          ) {
            console.log(4);
            return true;
          }
        }
      }
      if (
        this.newProduct.product.type_id === 2 &&
        this.newProduct.productFertilizers
      ) {
        if (!this.serverProduct?.productFertilizers) {
          console.log(5);
          return true;
        }
        if (
          this.newProduct.productFertilizers.length !==
          this.serverProduct?.productFertilizers.length
        ) {
          console.log(6);
          return true;
        }
        for (const [index] of this.newProduct.productFertilizers.entries()) {
          if (
            this.newProduct.productFertilizers[index]
              .fertilizer_subcategory_id !==
            this.serverProduct?.productFertilizers[index]
              .fertilizer_subcategory_id
          ) {
            console.log(7);
            return true;
          }
        }
      }
      if (
        this.newProduct.product.type_id === 0 &&
        this.newProduct.productPests
      ) {
        if (!this.serverProduct?.productPests) {
          console.log(8);
          return true;
        }
        if (
          this.newProduct.productPests.length !==
          this.serverProduct?.productPests.length
        ) {
          console.log(9);
          return true;
        }
        for (const [index] of this.newProduct.productPests.entries()) {
          if (
            this.newProduct.productPests[index].pest_id !==
            this.serverProduct?.productPests[index].pest_id
          ) {
            console.log(10);
            return true;
          }
        }
      }
      if (
        this.newProduct.product.type_id === 1 &&
        this.newProduct.productTypesStimulation
      ) {
        console.log(this.newProduct.productTypesStimulation.length);
        console.log(this.serverProduct.productTypesStimulation.length);
        if (!this.serverProduct?.productTypesStimulation) {
          console.log(11);
          return true;
        }
        if (
          this.newProduct.productTypesStimulation.length !==
          this.serverProduct.productTypesStimulation.length
        ) {
          console.log(12);
          return true;
        }
        for (const [
          index,
        ] of this.newProduct.productTypesStimulation.entries()) {
          if (
            this.newProduct.productTypesStimulation[index]
              .type_stimulation_id !==
            this.serverProduct?.productTypesStimulation[index]
              .type_stimulation_id
          ) {
            console.log(13);
            return true;
          }
        }
      }
      return false;
    },
    isEditProductMode() {
      return this.$route.name === routeNames.editProduct;
    },
    stateForInputsNotBlocked() {
      return this.isDisabledUiNotBlocked
        ? 'disabled'
        : !this.canEdit ||
          (this.isEditProductMode &&
            this.serverProduct?.product?.exist_apex !== 0)
        ? 'disabled2'
        : '';
    },
    stateForInputs() {
      return this.isDisabledUi
        ? 'disabled'
        : !this.canEdit ||
          (this.isEditProductMode &&
            this.serverProduct?.product?.exist_apex !== 0)
        ? 'disabled2'
        : '';
    },
    stateDisabled2ForDD() {
      return (
        this.isEditProductMode && this.serverProduct?.product?.exist_apex !== 0
      );
    },
    showWarning() {
      return this.newProduct?.product?.attention;
    },
    warningText() {
      return this.serverProduct?.product?.attention === 1
        ? this.$t('ProductsEdit:WarningText1') //'Необходимо заполнить данные'
        : this.serverProduct?.product?.attention === 2
        ? this.$t('ProductsEdit:WarningText2') //'Продукт отсутвует в Apex'
        : '';
    },
    showWarningBtn() {
      return this.serverProduct?.product?.attention === 2;
    },
    showDeleteBlock() {
      return this.serverProduct?.product?.attention === 2;
    },
    priceFor1Ha() {
      if (
        this.newProduct.product.price &&
        this.newProduct.product.picking &&
        this.newProduct.product.quantity_per_ha
      ) {
        if (
          !Number.isNaN(
            (this.newProduct.product.price / this.newProduct.product.picking) *
              this.newProduct.product.quantity_per_ha
          )
        ) {
          return (
            (this.newProduct.product.price / this.newProduct.product.picking) *
            this.newProduct.product.quantity_per_ha
          ).toFixed(2);
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    productUpdateDate() {
      if (this.newProduct.product.update_date) {
        return new Date(this.newProduct.product.update_date).toLocaleString(
          'ru-RU'
        );
      } else {
        return '';
      }
    },
  },
  methods: {
    async findButtonClick() {
      let res = await this.loadEditProductData(this.newProduct.product.code);
      if (this.newProduct.product?.id !== null) {
        //this.$store.state.products.editProductCache = this.serverProduct;
        this.$store.commit(
          productsMutationTypes.setEditProductCache,
          this.serverProduct
        );
        this.state.nextRouteConfirm = true;
        await this.$router.push({
          name: routeNames.editProduct,
          params: { id: this.newProduct.product.id },
        });
      } else {
        this.state.findOk = true;
        if (res && (res?.has1 || res?.has2)) {
          let text = '';
          if (res?.has1)
            text = `${this.$t('ProductsEdit:FindText1')} ${
              this.newProduct.product.code
            }-1`;
          if (res?.has2)
            text = `${this.$t('ProductsEdit:FindText1')} ${
              this.newProduct.product.code
            }-2`;
          if (res?.has1 && res?.has2)
            text = `${this.$t('ProductsEdit:FindText3')} ${
              this.newProduct.product.code
            }-1 и ${this.newProduct.product.code}-2`;
          text = text + '. ' + this.$t('ProductsEdit:FindText4');
          this.$awn.info(text);
        } else {
          this.$awn.success(
            this.$t('ProductsEdit:FindTextOk') //'Продукт с ID не найден, можно создать новый продукт'
          );
        }
      }
    },
    async onReloadProduct() {
      await this.loadEditProductData(this.newProduct.product.code, true);
      if (this.newProduct.product?.id !== null) {
        //this.$store.state.products.editProductCache = this.serverProduct;
        this.$store.commit(
          productsMutationTypes.setEditProductCache,
          this.serverProduct
        );
      }
    },
    onSave() {
      this.updateProduct();
    },
    async updateProduct() {
      try {
        this.request.isLoading = true;
        this.request.isUpdating = true;
        let response = await productsApi.updateProduct(this.newProduct);
        if (response.data.status === 'ok') {
          this.request.hasError = false;
          this.$confirm({
            message:
              this.$t('ProductsEdit:SaveOkText1') +
              '<br/> ' +
              this.$t('ProductsEdit:SaveOkText2'),
            buttons: {
              left: {
                text: this.$t('ProductsEdit:ButtonGo'), //'Перейти',
                icon: 'tick',
                color: 'accentlines',
              },
              right: {
                text: this.$t('ProductsEdit:ButtonNo'), //'Остаться',
                icon: 'cancel',
                color: 'graylines',
              },
            },
            callback: (confirm) => {
              this.state.nextRouteConfirm = true;
              if (confirm) {
                this.$router.push({ name: routeNames.products });
              } else {
                if (this.$route.name === routeNames.editProduct) {
                  this.$router.go();
                } else {
                  if (response.data?.id) {
                    this.$router.push({
                      name: routeNames.editProduct,
                      params: { id: response.data.id },
                    });
                  } else {
                    this.$router.go();
                  }
                }
              }
            },
          });
          //this.prepareMetaData();
        } else {
          this.request.hasError = true;
        }
        this.request.isLoading = false;
        this.request.isUpdating = false;
      } catch (e) {
        this.request.hasError = true;
        this.request.isLoading = false;
        this.request.isUpdating = false;
        console.log(e);
      }
    },
    async loadNewProductData() {
      try {
        //window.scrollTo(0, 0);
        this.request.isLoading = true;
        let response = await productsApi.getNewProductData({});
        if (response.data.status === 'ok') {
          this.request.hasError = false;
          Object.keys(response.data).forEach((key) => {
            if (
              Object.keys(this.data).includes(key) &&
              response.data[key] instanceof Array
            ) {
              this.data[key] = response.data[key];
            }
          });
          //this.prepareMetaData();
        } else {
          this.request.hasError = true;
        }
        this.request.isLoading = false;
      } catch (e) {
        this.request.hasError = true;
        this.request.isLoading = false;
        console.log(e);
      }
    },
    async loadEditProductData(code, forceUpdate) {
      let res = null;
      try {
        //window.scrollTo(0, 0);
        this.request.isLoading = true;
        if (code) {
          this.request.isFinding = true;
        }
        if (
          !forceUpdate &&
          this.$route.params.id &&
          this.$store.state.products.editProductCache &&
          this.$store.state.products.editProductCache?.product?.id ===
            this.$route.params.id
        ) {
          this.request.hasError = false;
          Object.keys(this.$store.state.products.editProductCache).forEach(
            (key) => {
              if (
                Object.keys(this.data).includes(key) &&
                this.$store.state.products.editProductCache[key] instanceof
                  Array
              ) {
                this.data[key] =
                  this.$store.state.products.editProductCache[key];
              }
            }
          );
          this.parseEditProductData(
            this.$store.state.products.editProductCache
          );
          this.serverProduct = this.$store.state.products.editProductCache;
          this.$store.commit(productsMutationTypes.resetEditProductCache);
          //this.$store.state.products.editProductCache = null;
        } else {
          let response = await productsApi.getEditProductData({
            id: this.$route.params?.id,
            code: code || undefined,
            forceUpdate: forceUpdate || undefined,
          });
          if (response.data.status === 'ok') {
            this.request.hasError = false;
            Object.keys(response.data).forEach((key) => {
              if (
                Object.keys(this.data).includes(key) &&
                response.data[key] instanceof Array
              ) {
                this.data[key] = response.data[key];
              }
            });
            this.serverProduct = response.data;
            this.serverProduct.productPests =
              this.serverProduct?.productPests || [];
            this.serverProduct.productTypesStimulation =
              this.serverProduct?.productTypesStimulation || [];
            this.serverProduct.productFertilizers =
              this.serverProduct?.productFertilizers || [];
            this.serverProduct.productElements =
              this.serverProduct?.productElements || [];
            this.serverProduct.productElements.forEach((val) => {
              if (val?.quantity === 0) {
                val.quantity = null;
              }
            });
            this.parseEditProductData(response.data);
            //this.prepareMetaData();
          } else {
            this.request.hasError = true;
          }
          res = response.data;
        }
        this.request.isLoading = false;
        if (code) {
          this.request.isFinding = false;
        }
      } catch (e) {
        this.request.hasError = true;
        this.request.isLoading = false;
        if (code) {
          this.request.isFinding = false;
        }
        console.log(e);
      }
      return res;
    },
    parseEditProductData(data) {
      try {
        Object.keys(data).forEach((key) => {
          if (Object.keys(this.newProduct).includes(key)) {
            if (data[key] instanceof Array) {
              {
                this.newProduct[key] = data[key];
              }
            } else if (data[key] instanceof Object) {
              {
                this.newProduct[key] = {
                  ...this.newProduct[key],
                  ...data[key],
                };
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    selectedType() {
      this.newProduct.product.sub_type_id = null;
    },
    onHideWarning() {
      this.newProduct.product.attention = 0;
      this.newProduct.product.user_ignore_attention = 1;
    },
    onDeleteClick() {
      this.$confirm({
        message:
          this.$t('ProductsEdit:ModalDeleteText1') +
          '<br>' +
          this.$t('ProductsEdit:ModalDeleteText2'),
        buttons: {
          left: {
            text: this.$t('ProductsEdit:ModalDeleteButtonLeft'), //'Удалить',
          },
          right: {
            text: this.$t('ProductsEdit:ModalDeleteButtonRight'), //'Отмена',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteProduct();
          }
        },
      });
    },
    async deleteProduct() {
      try {
        this.request.isLoading = true;
        this.request.isUpdating = true;
        let response = await productsApi.deleteProduct(this.newProduct);
        if (response.data.status === 'ok') {
          this.request.hasError = false;
          this.state.nextRouteConfirm = true;
          await this.$router.push({ name: routeNames.products });
          //this.prepareMetaData();
        } else {
          this.request.hasError = true;
          this.$awn.alert(this.$t('ProductsEdit:DeleteError2'));
        }
        this.request.isLoading = false;
        this.request.isUpdating = false;
      } catch (e) {
        this.request.hasError = true;
        this.request.isLoading = false;
        this.request.isUpdating = false;
        this.$awn.alert(this.$t('ProductsEdit:DeleteError'));
        console.log(e);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const showConfirm = () =>
      this.$confirm({
        message:
          this.$t('ProductsEdit:NotSavedText1') +
          '<br>' +
          this.$t('ProductsEdit:NotSavedText2'),
        callback: createConfirmCallback(next),
      });

    if (!this.canEdit) {
      next();
    } else if (this.state.nextRouteConfirm) {
      if (to.name !== routeNames.editProduct) {
        this.$store.commit(productsMutationTypes.resetEditProductCache);
      }
      next();
    } else if (this.$route.name === routeNames.editProduct) {
      if (!this.isSaveButtonDisabled) {
        if (to?.params?.skipModal) {
          next();
          return;
        }
        showConfirm();
      } else {
        next();
      }
    } else {
      if (this.state.findOk) {
        //this.nextRoute = to;
        if (to?.params?.skipModal) {
          next();
          return;
        }
        showConfirm();
      } else {
        next();
      }
    }
  },
  async created() {
    window.scrollTo(0, 0);
    if (this.isEditProductMode) {
      await this.loadEditProductData();
    } else {
      await this.loadNewProductData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';
</style>
